import { useCallback, useMemo } from "react";
import { useAuth } from "./AuthProvider";
import { TypeNotification, useNotification } from "./NotificationProvider";
import { strings } from "../localization/en";
import { useLocation, useNavigate } from "react-router";
import sessionService from "../services/session.service";

type Props = {
  children: JSX.Element;
};
type CustomConfig = RequestInit & {
  retry: boolean;
  headers: HeadersInit & {
    Authorization: string;
  };
};
type QueueMember = {
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
};

let isRefreshing = false;
let failedQueue: QueueMember[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom: QueueMember) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const { fetch: originalFetch } = window;

function WithInterceptor({ children }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { showInfo } = useNotification();
  const { logOut, user } = useAuth();

  useMemo(() => {
    window.fetch = async (...args) => {
      let [resource, config] = args;

      let response = await originalFetch(resource, config);

      if (response.status === 401) {
        if (response.url.includes("auth/refreshToken")) {
          isRefreshing = false;
          processQueue(response, null);
          logOut().then((_) => {
            showInfo(
              strings.session_timeout_error,
              TypeNotification.warning,
              10000
            );
            navigate("/login", { state: { from: location } });
          });
          return response;
        }
        if (user?.refreshToken && !(config as CustomConfig).retry) {
          if (isRefreshing) {
            return new Promise(function (resolve, reject) {
              failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                (config as CustomConfig).headers.Authorization =
                  "Bearer " + token;
                return originalFetch(resource, config);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }
          (config as CustomConfig).retry = true;
          isRefreshing = true;
          return new Promise(function (resolve) {
            sessionService
              .refreshToken()
              .then((user) => {
                (config as CustomConfig).headers.Authorization =
                  "Bearer " + user?.token;
                processQueue(null, user?.token);

                resolve(originalFetch(resource, config));
              })
              .finally(() => {
                isRefreshing = false;
              });
          });
        } else {
          isRefreshing = false;
          logOut().then((_) => {
            showInfo(
              strings.session_timeout_error,
              TypeNotification.warning,
              10000
            );
            navigate("/login", { state: { from: location } });
          });
          return response;
        }
      }
      return response;
    };
  }, [user]);
  return children;
}

export default WithInterceptor;
