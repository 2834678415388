import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { StatisticData, SurveyState } from "../../models";
import Icon from "@mdi/react";
import { mdiAccountGroup, mdiSquareEditOutline, mdiCheckCircle } from "@mdi/js";
import { strings } from "../../../../localization/en";

type AppProps = {
  statistics: StatisticData[];
};
function Status({ statistics }: AppProps) {
  return (
    <Row className="m-0 h-100 w-100">
      <Col
        xs={6}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <Icon path={mdiAccountGroup} size={1} className="mb-1 text-primary" />
        <p className="mb-0 status-text text-muted">
          {strings.dashboard_insight_label_total}
        </p>
        <h2 data-testid="total">
          {statistics.reduce((acc, cur) => acc + cur.count, 0)}
        </h2>
      </Col>
      <Col
        xs={6}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <i
          className="mdi mdi-file-alert-outline text-primary"
          style={{ fontSize: 24 }}
        ></i>
        <p className="mb-0 status-text text-muted">
          {strings.dashboard_not_started_label}
        </p>
        <h2 data-testid="not_started">
          {statistics.find((x) => x.state === SurveyState.NotStarted)?.count ||
            0}
        </h2>
      </Col>
      <Col
        xs={6}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <Icon
          path={mdiSquareEditOutline}
          size={1}
          className="mb-1 text-primary"
        />
        <p className="mb-0 status-text text-muted">
          {strings.dashboard_in_progress_label}
        </p>
        <h2 data-testid="in_progress">
          {statistics.find((x) => x.state === SurveyState.InProgress)?.count ||
            0}
        </h2>
      </Col>
      <Col
        xs={6}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <Icon path={mdiCheckCircle} size={1} className="mb-1 text-primary" />
        <p className="mb-0 status-text text-muted">
          {strings.dashboard_completed_label}
        </p>
        <h2 data-testid="completed">
          {statistics.find((x) => x.state === SurveyState.Submitted)?.count ||
            0}
        </h2>
      </Col>
    </Row>
  );
}

export default Status;
