import { useCallback, useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { SurveyScore } from "../../models/survey-score";
import { surveyService } from "../../services/survey.service";
import { useAuth } from "../../../components/AuthProvider";
import { useNotification } from "../../../components/NotificationProvider";
import SubSectionBreakdown from "../../client/components/survey-score/SubSectionBreakdown";
import "./ViewSurveyScorePage.css";
import { strings } from "../../../localization/en";
import SurveyScoreHeader from "../../client/components/survey-score/SurveyScoreHeader";
import SurveyScoreChart from "../../client/components/survey-score/SurveyScoreChart";
import ChartData from "../../models/survey-score/chart-data";
import SurveyScoreButtonGroup from "../../client/components/survey-score/SurveyScoreButtonGroup";
import ClientHeader from "../../client/components/ClientHeader";

export default function ViewSurveyScorePage() {
  const { getMyInfo, logOut } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();

  const [surveyScore, setSurveyScore] = useState<SurveyScore | undefined>(
    undefined
  );
  const [data, setData] = useState<ChartData | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const { showError } = useNotification();

  const fetchSurveyResultCallback = useCallback(() => {
    id &&
      surveyService
        .getSurveyResult(id)
        .then((_surveyScore: SurveyScore | undefined) => {
          if (!_surveyScore) {
            throw new Error("Survey score not found");
          }
          setSurveyScore(_surveyScore);
          setData({
            labels: _surveyScore.subSections.map((x) => x.subSection),
            datasets: [
              {
                label: "Sub-section %",
                data: _surveyScore.subSections.map((x) => x.percentage),
                backgroundColor: "rgba(255,255,255,0.4)",
                borderColor: "#FFFFFF",
                borderWidth: 2,
                pointBackgroundColor: "#FFFFFF",
              },
            ],
          });
        })
        .catch((error) => {
          showError(error.status, strings.server_error);
        })
        .finally(() => setLoading(false));
  }, [id, showError]);

  useEffect(() => {
    getMyInfo()
      .then()
      .catch(() => {
        logOut().then((_) => navigate("/login"));
      });
  });

  useEffect(() => {
    fetchSurveyResultCallback();
  }, [fetchSurveyResultCallback]);

  if (loading) {
    return (
      <div className="survey-score">
        <Spinner animation="border" className="loading" />
      </div>
    );
  }

  return (
    <div className="survey-score">
      <ClientHeader isAdmin />
      <SurveyScoreButtonGroup surveyId={id} isAdmin />

      <Container className="mt-3">
        <SurveyScoreHeader
          company={surveyScore?.contact.company}
          category={surveyScore?.category}
        />

        <SurveyScoreChart chartData={data} surveyScore={surveyScore} />

        <SubSectionBreakdown surveyScore={surveyScore} />
      </Container>
    </div>
  );
}
