import { Form } from "react-bootstrap";

interface NAOptionProps {
  onChange: (evt: any) => void;
  name: string;
  checked?: boolean;
}
function NAOption(props: NAOptionProps) {
  const { name, onChange, checked } = props;
  return (
    <Form.Check
      type="radio"
      aria-label="radio_na"
      name={name}
      onChange={onChange}
      checked={checked}
    />
  );
}

export default NAOption;
