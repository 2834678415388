import { useState } from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useAuth } from "../../../../components/AuthProvider";
import {
  TypeNotification,
  useNotification,
} from "../../../../components/NotificationProvider";
import { strings } from "../../../../localization/en";
import { surveyService } from "../../../services/survey.service";
import InformationTooltip from "../InformationTooltip";

type props = {
  surveyId: string | undefined;
  isAdmin?: boolean;
};

export default function SurveyScoreButtonGroup({
  surveyId,
  isAdmin = false,
}: props) {
  const { clientVerified } = useAuth();
  const { showError } = useNotification();
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = () => {
    setIsExporting(true);
    if (isAdmin) {
      surveyService
        .getAdminSurveyReportPdf(surveyId!)
        .catch(() => {
          showError(undefined, strings.server_error);
        })
        .finally(() => setIsExporting(false));
    } else {
      surveyService
        .getSurveyReportPdf(surveyId!)
        .catch(() => {
          if (clientVerified) showError(undefined, strings.server_error);
        })
        .finally(() => setIsExporting(false));
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="d-flex mx-5">
      <div className="d-flex m-5">
        <div className="position-relative">
          <Button
            onClick={() =>
              openInNewTab(
                "/guides/B4SI Social Impact Compass Guidance Manual.pdf"
              )
            }
          >
            Guidance
          </Button>
          <InformationTooltip
            information={strings.result_guidance_tooltip}
            className="button-tooltip-wrapper"
            fill="#3988ff"
          />
        </div>
      </div>
      <div className="d-flex ms-auto m-5">
        <div className="position-relative mx-3">
          <Button
            data-testid="pdf-button"
            onClick={handleExport}
            disabled={isExporting}
          >
            <Spinner
              className="me-1"
              animation="grow"
              size="sm"
              hidden={!isExporting}
            />
            {strings.export_to_pdf_button_uppercase}
          </Button>

          <InformationTooltip
            information={strings.export_to_pdf_tooltip}
            className="button-tooltip-wrapper"
            fill="#3988ff"
          />
        </div>
      </div>
    </div>
  );
}
