import "./OverallResultLogo.css";
type Props = {
  category: string | undefined;
};
function OverallResultLogo({ category }: Props) {
  return (
    <div className="my-5">
      <div className="overall-result d-flex align-items-center justify-content-center">
        {["Thoughtful", "Purposeful", "Strategic", "Transformational"].map(
          (cat) => (
            <img
              data-testid={`img-${cat.toLowerCase()}`}
              src={`/images/${cat}.png`}
              alt="overall-results"
              key={cat}
              className={
                category === cat
                  ? "overall-result-logo highlighted-result"
                  : "overall-result-logo overlay-tint"
              }
            />
          )
        )}
      </div>
    </div>
  );
}

export default OverallResultLogo;
