import { useState } from "react";
import { Card, Container, Form, Row, Alert } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { isAdmin } from "../../models";
import { useAuth } from "../../../components/AuthProvider";
import NavBar from "../../client/components/NavBar";
import { strings } from "../../../localization/en";
import { APIError } from "../../models/error/api-error";
interface LocationState {
  from: {
    pathname: string;
  };
}

function LogIn() {
  const navigate = useNavigate();
  const { compassLogIn, user } = useAuth();
  const location = useLocation();
  const [loggingIn, setLoggingIn] = useState(false);
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const { email, password, rememberMe } = inputs;
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  function handleChange(e: any) {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  }

  function handleChangeCheckbox(e: any) {
    const { name, checked } = e.target;
    setInputs({ ...inputs, [name]: checked });
  }

  function handleSubmit(e: any) {
    e.preventDefault();

    setSubmitted(true);
    if (email && password) {
      const from = (location.state as LocationState)?.from?.pathname || "/";
      setLoggingIn(true);

      compassLogIn(email, password, rememberMe)
        .then((_) => {
          setLoggingIn(false);

          navigate(from, { replace: true });
        })
        .catch((error: APIError) => {
          setLoggingIn(false);
          if (error.status === 401) setErrorMessage(strings.login_incorrect);
          else if (error.status === 403)
            setErrorMessage(strings.forbidden_error);
          else {
            setErrorMessage(strings.server_error);
          }
        });
    }
  }
  if (user && isAdmin(user)) {
    return <Navigate to="/admin" replace />;
  }
  return (
    <div className="login-page">
      <NavBar />
      <Container className="mt-5 d-flex justify-content-center">
        <Card className="w-50">
          <Card.Header as="h1" className="p-3">
            {strings.login}
          </Card.Header>
          {!!errorMessage && (
            <Alert variant="danger text-center px-0 mx-0">{errorMessage}</Alert>
          )}
          <Card.Body>
            <Form
              onSubmit={handleSubmit}
              onChange={() => {
                if (!!errorMessage) setErrorMessage("");
              }}
            >
              <Form.Group as={Row} className="align-items-center mb-3">
                <label className="col-3 text-right">
                  {strings.email_address}
                </label>
                <div className="col-9">
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    className={
                      "form-control" +
                      ((submitted && !email) || !!errorMessage
                        ? " is-invalid"
                        : "")
                    }
                  />
                  {submitted && !email && (
                    <div className="invalid-feedback">
                      {strings.username_required}
                    </div>
                  )}
                </div>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center mb-3">
                <label className="col-3 text-right">{strings.password}</label>
                <div className="col-9">
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={handleChange}
                    className={
                      "form-control" +
                      ((submitted && !password) || !!errorMessage
                        ? " is-invalid"
                        : "")
                    }
                  />
                  {submitted && !password && (
                    <div className="invalid-feedback">
                      {strings.password_required}
                    </div>
                  )}
                </div>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center mb-3">
                <div className="col-3"></div>
                <div className="col-9">
                  <Form.Check
                    name="rememberMe"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={handleChangeCheckbox}
                    label={strings.remember}
                  />
                </div>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center mb-3">
                <div className="col-3"></div>
                <div className="col-9">
                  <button type="submit" className="btn btn-primary">
                    {loggingIn && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    {strings.login}
                  </button>
                </div>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default LogIn;
