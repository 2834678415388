import React from "react";
type AppProps = {
  content: string;
};
function Title({ content }: AppProps) {
  return (
    <h3 className="bg-title text-light text-center mb-0 p-3">{content}</h3>
  );
}

export default Title;
