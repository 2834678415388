export function spiderGraphBackgroundGenerator(string: string) {
  switch (string) {
    case "PLAN":
      return "rgba(65, 192, 240, 0.4)";
    case "IMPLEMENT":
      return "rgba(27, 175, 162, 0.4)";
    case "MEASURE":
      return "rgba(0, 112, 189, 0.4)";
    case "REPORT & ENGAGE":
      return "rgba(130, 46, 142, 0.4)";
    default:
      return "";
  }
}
