import {
  handleResponse,
  handleBlobResponse,
} from "../../helpers/handle-response";
import {
  PagedItems,
  QuestionCreateModel,
  SectionCreateModel,
  SectionViewModel,
  SubsectionCreateModel,
  SubsectionViewModel,
  SurveyFilter,
  SurveyViewModel,
} from "../models";
import sessionService from "../../services/session.service";
import { addDays, toYYYYMMDD } from "../../helpers/function-extensions";
import { QuestionViewModel } from "../models/question-view";
import SurveyInsightFilter from "../models/survey-insight-filter";
import { SurveyResendModel } from "../models/survey-resend";

class AdminService {
  deleteSubsection(id: number) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/subsections/${id}`,
      requestOptions
    ).then(handleResponse);
  }

  getSubsection(id: number): Promise<SubsectionViewModel> {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/subsections/${id}`,
      requestOptions
    ).then(handleResponse);
  }

  updateSubSection(
    id: number,
    subsection: SubsectionCreateModel
  ): Promise<any> {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
      body: JSON.stringify(subsection),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/subsections/${id}`,
      requestOptions
    ).then(handleResponse);
  }

  createSubSection(subsection: SubsectionCreateModel) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
      body: JSON.stringify(subsection),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/subsections`,
      requestOptions
    ).then(handleResponse);
  }

  getAllSubSections(): Promise<SubsectionViewModel[]> {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/subSections`,
      requestOptions
    ).then(handleResponse);
  }

  deleteSection(id: number) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/sections/${id}`,
      requestOptions
    ).then(handleResponse);
  }

  getSection(id: string) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/sections/${id}`,
      requestOptions
    ).then(handleResponse);
  }

  updateSection(id: string, section: SectionCreateModel) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
      body: JSON.stringify(section),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/sections/${id}`,
      requestOptions
    ).then(handleResponse);
  }

  createSection(section: SectionCreateModel) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
      body: JSON.stringify(section),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/sections`,
      requestOptions
    ).then(handleResponse);
  }

  getAllSections(): Promise<SectionViewModel[]> {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/sections`,
      requestOptions
    ).then(handleResponse);
  }

  getAllQuestions(): Promise<QuestionViewModel[]> {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/questions`,
      requestOptions
    ).then(handleResponse);
  }

  getQuestion(id: number): Promise<QuestionViewModel> {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/questions/${id}`,
      requestOptions
    ).then(handleResponse);
  }

  createQuestion(payload: QuestionCreateModel): Promise<void> {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
      body: JSON.stringify(payload),
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/questions`,
      requestOptions
    ).then(handleResponse);
  }

  updateQuestion(questionId: number, payload: QuestionCreateModel) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
      body: JSON.stringify(payload),
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/questions/${questionId}`,
      requestOptions
    ).then(handleResponse);
  }

  deleteQuestion(questionId: number): Promise<void> {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/questions/${questionId}`,
      requestOptions
    ).then(handleResponse);
  }

  generateSurvey(email: string) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
      body: JSON.stringify({ email: email, dueDate: addDays(15) }),
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/surveys`,
      requestOptions
    ).then(handleResponse);
  }

  getStatisticsData({
    typeSortingDate,
    fromDate,
    toDate,
  }: SurveyInsightFilter) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };

    return fetch(
      `${
        process.env.REACT_APP_API_END_POINT
      }/statistics?typeSortingDate=${typeSortingDate}&from=${toYYYYMMDD(
        fromDate
      )}&to=${toYYYYMMDD(toDate)}`,
      requestOptions
    ).then(handleResponse);
  }

  searchSurveys(
    pagination: SurveyFilter
  ): Promise<PagedItems<SurveyViewModel>> {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/statistics/search?status=${pagination.status}&search=${pagination.search}&category=${pagination.category}&itemsPerPage=${pagination.itemsPerPage}&currentPage=${pagination.currentPage}&orderDirection=${pagination.orderDirection}&orderBy=${pagination.orderBy}&typeSortingDate=${pagination.typeSortingDate}&dateFrom=${pagination.dateFrom}&dateTo=${pagination.dateTo}`,
      requestOptions
    )
      .then(handleResponse)
      .then((pagedResult: PagedItems<any>) => ({
        ...pagedResult,
        items: pagedResult.items.map((s) => ({
          ...s,
          dateSent: new Date(s.dateSent),
        })),
      }));
  }

  exportSurveys(filter: SurveyFilter) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/statistics/export?status=${filter.status}&search=${filter.search}&category=${filter.category}&orderDirection=${filter.orderDirection}&orderBy=${filter.orderBy}&typeSortingDate=${filter.typeSortingDate}&dateFrom=${filter.dateFrom}&dateTo=${filter.dateTo}`,
      requestOptions
    ).then(handleBlobResponse);
  }

  resendSurveys(payload: SurveyResendModel[]) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
      body: JSON.stringify(payload),
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/surveys/resendSurvey`,
      requestOptions
    ).then(handleResponse);
  }

  getResendSurveys(filter: SurveyFilter) {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/statistics/resend?status=${filter.status}&search=${filter.search}&category=${filter.category}&orderDirection=${filter.orderDirection}&orderBy=${filter.orderBy}&typeSortingDate=${filter.typeSortingDate}&dateFrom=${filter.dateFrom}&dateTo=${filter.dateTo}`,
      requestOptions
    ).then(handleResponse);
  }
}

export default new AdminService();
