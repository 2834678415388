import { Dropdown } from "react-bootstrap";
import { Section } from "../../models/survey";
import { SubsectionViewModel } from "../models";
import "./HeaderFilter.css";
type Props = {
  defaultValue: string;
  selectedValue: string;
  setSelectedValue: (selectedValue: string) => void;
  handleDefaultItemClick?: (e: React.MouseEvent<HTMLElement>) => void;
  handleItemClick?: (itemValue: string) => void;
  options: Section[] | SubsectionViewModel[];
};
function HeaderFilter({
  defaultValue,
  selectedValue,
  setSelectedValue,
  handleDefaultItemClick = (_) => {
    setSelectedValue(defaultValue);
  },
  handleItemClick = (itemValue) => {
    setSelectedValue(itemValue);
  },
  options,
}: Props) {
  return (
    <Dropdown className="h-100 w-100 position-static">
      <Dropdown.Toggle
        className="h-100 w-100 rounded-0 table-header-filter"
        id="dropdown-filter"
      >
        {selectedValue}
      </Dropdown.Toggle>

      <Dropdown.Menu className="rounded-0">
        <Dropdown.Item onClick={handleDefaultItemClick}>
          {defaultValue}
        </Dropdown.Item>
        {options.map((o) => (
          <Dropdown.Item
            key={o.id}
            onClick={(_) => {
              handleItemClick(o.name);
            }}
          >
            {o.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default HeaderFilter;
