import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../../../components/AuthProvider";

function LogOut() {
  const { logOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    logOut().then((_) => navigate("/login"));
  }, [logOut, navigate]);

  return <></>;
}

export default LogOut;
