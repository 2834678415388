import { Container, Table } from "react-bootstrap";
import { BiEditAlt } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router";
import Title from "../components/common/Title";
import React, { useEffect, useState } from "react";
import { QuestionViewModel } from "../models/question-view";
import adminService from "../services/admin.service";
import ConfirmDeleteModal from "../components/common/ConfirmDeleteModal";
import { SubsectionViewModel } from "../models";
import { Section } from "../../models/survey";
import HeaderFilter from "../components/HeaderFilter";
import "./ManageQuestions.css";
import { strings } from "../../../localization/en";
import {
  TypeNotification,
  useNotification,
} from "../../../components/NotificationProvider";

type StoredFilter = {
  selectedSection: string;
  selectedSubSection: string;
};

let deletingId: number;

function ManageQuestions({ viewOnly }: { viewOnly: boolean }) {
  const location = useLocation();
  const [questions, setQuestions] = useState<QuestionViewModel[]>([]);
  const [subSections, setSubSections] = useState<SubsectionViewModel[]>([]);
  const [selectedSection, setSelectedSection] = useState<string>("Section");
  const [selectedSubSection, setSelectedSubSection] =
    useState<string>("Sub-Section");
  const [sections, setSections] = useState<Section[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [hasHorizontalScrollbar, setHasHorizontalScrollbar] = useState(false);
  const navigate = useNavigate();
  const { showError, showInfo } = useNotification();

  const handleTableWidthChange = () => {
    let div = document.getElementById("middle");
    if (div?.scrollWidth && div?.clientWidth) {
      setHasHorizontalScrollbar(div?.scrollWidth > div?.clientWidth);
    }
  };
  useEffect(() => {
    adminService.getAllQuestions().then((response: QuestionViewModel[]) => {
      setQuestions(response);
    });
    adminService.getAllSubSections().then((respondedSubSections) => {
      setSubSections(respondedSubSections);
    });
    adminService.getAllSections().then((respondedSections) => {
      setSections(respondedSections);
    });
    handleTableWidthChange();
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((_) => handleTableWidthChange());
    resizeObserver.observe(document.getElementById("middle")!);
    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (location.state) {
      setSelectedSection((location.state as StoredFilter).selectedSection);
      setSelectedSubSection(
        (location.state as StoredFilter).selectedSubSection
      );
    }
  }, [location]);

  const handleShowModal = (id: number) => {
    deletingId = id;
    setShowModal(true);
  };

  const deleteQuestion = () => {
    adminService
      .deleteQuestion(deletingId)
      .then((_) => {
        setShowModal(false);
        showInfo(
          strings.question_deleted_message,
          TypeNotification.success,
          3000
        );
        adminService.getAllQuestions().then((response) => {
          setQuestions(response);
        });
      })
      .catch((error) => {
        showError(error.status, strings.server_error);
      })
      .finally(() => setShowModal(false));
  };

  const handleTopScroll = (e: any) => {
    const bottom = document.getElementById("bottom");
    const middle = document.getElementById("middle");
    const top = e.target;

    bottom!.scroll({
      left: top.scrollLeft,
    });
    middle!.scroll({
      left: top.scrollLeft,
    });
  };
  const filteredSubSections =
    selectedSection === "Section"
      ? subSections
      : subSections.filter((s) => s.section === selectedSection);

  const getFilteredQuestions = () => {
    if (selectedSubSection === "Sub-Section") {
      if (selectedSection === "Section") return questions;
      else return questions.filter((q) => q.section === selectedSection);
    }
    return questions.filter((q) => q.subSection === selectedSubSection);
  };
  const filteredQuestions = getFilteredQuestions();
  const questionColWidth = viewOnly ? "25%" : "20%";
  const questionAttrColWidth = viewOnly ? "5%" : "6%";
  return (
    <Container fluid>
      <Title content={strings.question_manage_title} />
      <Container fluid className="bg-white p-4">
        <div className="sticky-table-header">
          <div
            style={{
              overflowX: "scroll",
              borderBottom: "10px solid white",
            }}
            className="bg-white"
            id="top"
            onScroll={(e) => {
              handleTopScroll(e);
            }}
            hidden={!hasHorizontalScrollbar}
          >
            <div
              style={{
                height: "1px",
                width: "100%",
                minWidth: "1900px",
              }}
            ></div>
          </div>

          <Table className="custom-table horizontal-scroll-table" id="middle">
            <thead className=" table-gray text-center horizontal-scroll-table-content">
              <tr className="">
                <th
                  className="p-0 h-100 border-start-0"
                  style={{ width: "12%" }}
                >
                  <HeaderFilter
                    defaultValue="Section"
                    selectedValue={selectedSection}
                    setSelectedValue={setSelectedSection}
                    handleDefaultItemClick={(_) => {
                      setSelectedSection((prev) => {
                        if (prev !== "Section")
                          setSelectedSubSection("Sub-Section");
                        return "Section";
                      });
                    }}
                    handleItemClick={(itemValue) => {
                      setSelectedSection((prev) => {
                        if (prev !== itemValue)
                          setSelectedSubSection("Sub-Section");
                        return itemValue;
                      });
                    }}
                    options={sections}
                  />
                </th>
                <th className="p-0 h-100" style={{ width: "17%" }}>
                  <HeaderFilter
                    defaultValue="Sub-Section"
                    selectedValue={selectedSubSection}
                    setSelectedValue={setSelectedSubSection}
                    options={filteredSubSections}
                  />
                </th>
                <th style={{ width: questionColWidth }}>
                  {strings.question_table_header_question}
                </th>
                <th style={{ width: questionColWidth }}>
                  {strings.question_table_header_guidance}
                </th>
                <th className="value-column" style={{ width: "6%" }}>
                  {strings.question_table_header_order}
                </th>
                <th
                  className="text-center value-column"
                  style={{ width: "6%" }}
                >
                  {strings.question_table_header_weighting}
                </th>
                <th className="value-column" style={{ width: "4%" }}>
                  {strings.question_table_header_nAOptions}
                </th>
                <th
                  className="value-column"
                  style={{ width: questionAttrColWidth }}
                >
                  {strings.question_table_header_option1}
                </th>
                <th
                  className="value-column"
                  style={{ width: questionAttrColWidth }}
                >
                  {strings.question_table_header_option2}
                </th>
                <th
                  className="value-column"
                  style={{ width: questionAttrColWidth }}
                >
                  {strings.question_table_header_option3}
                </th>
                <th
                  className="value-column"
                  style={{ width: questionAttrColWidth }}
                >
                  {strings.question_table_header_option4}
                </th>
                <th
                  className="value-column"
                  style={{ width: questionAttrColWidth }}
                >
                  {strings.question_table_header_max_score}
                </th>
                {!viewOnly && (
                  <React.Fragment>
                    <th
                      className="text-center value-column"
                      style={{ width: "5%" }}
                    >
                      {strings.table_header_edit}
                    </th>
                    <th
                      className="text-center value-column border-end-0"
                      style={{ width: "5%" }}
                    >
                      {strings.table_header_delete}
                    </th>
                  </React.Fragment>
                )}
              </tr>
            </thead>
          </Table>
        </div>

        <Table
          className="custom-table h-100 horizontal-scroll-table"
          id="bottom"
        >
          <tbody className="horizontal-scroll-table-content">
            {filteredQuestions.map((q, i) => (
              <tr key={i}>
                <td style={{ width: "12%" }}> {q.section}</td>
                <td style={{ width: "17%" }}>{q.subSection}</td>
                <td style={{ width: questionColWidth }}>{q.question}</td>
                <td
                  className="guidance-column"
                  style={{ width: questionColWidth }}
                >
                  {q.guidance}
                </td>
                <td className="text-center" style={{ width: "6%" }}>
                  {q.order}
                </td>
                <td className="text-center" style={{ width: "6%" }}>
                  {q.weighting}
                </td>
                <td className="text-center" style={{ width: "4%" }}>
                  {q.naOption ? "Y" : "N"}
                </td>
                <td
                  className="text-center"
                  style={{ width: questionAttrColWidth }}
                >
                  {q.rawScore1}
                </td>
                <td
                  className="text-center"
                  style={{ width: questionAttrColWidth }}
                >
                  {q.rawScore2}
                </td>
                <td
                  className="text-center"
                  style={{ width: questionAttrColWidth }}
                >
                  {q.rawScore3}
                </td>
                <td
                  className="text-center"
                  style={{ width: questionAttrColWidth }}
                >
                  {q.rawScore4}
                </td>
                <td
                  className="text-center"
                  style={{ width: questionAttrColWidth }}
                >
                  {q.maxScore}
                </td>
                {!viewOnly && (
                  <React.Fragment>
                    <td className="text-center" style={{ width: "5%" }}>
                      <BiEditAlt
                        fontSize={25}
                        className="icon-button"
                        onClick={() => {
                          navigate(`/admin/questions/edit/${q.id}`, {
                            state: { selectedSection, selectedSubSection },
                          });
                        }}
                      />
                    </td>
                    <td className="text-center" style={{ width: "5%" }}>
                      <IoTrashOutline
                        fontSize={25}
                        className="icon-button"
                        onClick={() =>
                          (function (id) {
                            handleShowModal(id);
                          })(q.id)
                        }
                      />
                    </td>
                  </React.Fragment>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <ConfirmDeleteModal
        show={showModal}
        setShow={setShowModal}
        handleConfirm={deleteQuestion}
      />
    </Container>
  );
}

export default ManageQuestions;
