import { Col, ProgressBar } from "react-bootstrap";
import "./Progress.css";
import styles from "./Progress.module.scss";
export interface ProgressProps {
  label: string;
  value: number;
  name?: string;
  index?: number;
  onClick?: (sectionName: string) => void;
}

function Progress(props: ProgressProps) {
  const { label, value, name, index, onClick } = props;
  const clickable = onClick && name;
  const className = clickable ? "survey-progress" : "";
  const handleClick = () => {
    if (clickable) {
      onClick(name);
    }
  };

  return (
    <div
      className={className}
      onClick={handleClick}
      style={{
        flex: "1 1 0px",
        paddingLeft: "5px",
        paddingRight: "5px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        alignItems: "stretch",
      }}
      key={index}
    >
      <div className="progress-title">{label}</div>
      <ProgressBar
        variant="default"
        now={value}
        label={`${value}%`}
        className={styles["progress-" + label.split(" ")[0].toLowerCase()]}
      />
    </div>
  );
}

export default Progress;
