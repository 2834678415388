import { Button, Col, Form, Card, Row, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { SurveyState } from "../../models/survey";
import { surveyService } from "../../services/survey.service";
import { useAuth } from "../../../components/AuthProvider";
import VerificationInput from "../components/VerificationInput";
import { strings } from "../../../localization/en";
import { emailRegex } from "../../helpers/regex-pattern";
interface IdProps {
  id: string;
  status: SurveyState;
  onSuceess: (status: SurveyState) => void;
  verified: boolean;
  setIsVerifying: (verified: boolean) => void;
}

function SurveyContactVerification(props: IdProps) {
  const { id, status, onSuceess, verified, setIsVerifying } = props;
  const { verify } = useAuth();

  const {
    control,
    getValues,
    formState: { errors, isDirty },
    setError,
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "all",
  });

  const { control: control2, handleSubmit: handleSubmit2 } = useForm({
    defaultValues: {
      firstName: "",
      surname: "",
      company: "",
    },
  });

  const verifyContactInfo = handleSubmit2((data) => {
    const email = getValues("email");
    if (!email) return;
    surveyService
      .verifyContactInfo(id, { email, ...data })
      .then((_) => {
        setIsVerifying(false);
        verify(email);
        onSuceess(status);
        document.title = "B4SI | Assessment";
      })
      .catch((_error) => {
        setError("email", {
          type: "custom",
          message: strings.email_required_message,
        });
      });
  });

  return (
    <Container className="mt-5">
      <Card className="border-0 rounded shadow p-4">
        <Card.Title as={Col}>
          <h4 className="my-0 text-center">{strings.verification_steps}</h4>
        </Card.Title>

        <Card.Body>
          <div className="text-danger mb-3">
            <i>{strings.all_mandatory}</i>
          </div>
          <Form id="verificationForm" onSubmit={verifyContactInfo}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="2">
                {strings.email_address}
              </Form.Label>
              <Col sm="10">
                <VerificationInput
                  name="email"
                  placeholder="Please enter your address yourname@example.com"
                  control={control}
                  type="email"
                  rules={{
                    pattern: emailRegex,
                  }}
                />
              </Col>
            </Form.Group>

            {!verified && (
              <>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    {strings.firstname}
                  </Form.Label>
                  <Col sm="4">
                    <VerificationInput
                      name="firstName"
                      placeholder="Please enter your First Name"
                      control={control2}
                      rules={{
                        required: "First Name cannot be empty.",
                      }}
                    />
                  </Col>
                  <Form.Label column sm="2" className="text-md-end">
                    {strings.surname}
                  </Form.Label>
                  <Col sm="4">
                    <VerificationInput
                      name="surname"
                      placeholder="Please enter your Surname"
                      control={control2}
                      rules={{
                        required: "Surname cannot be empty.",
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">
                    {strings.company}
                  </Form.Label>
                  <Col sm="10">
                    <VerificationInput
                      name="company"
                      placeholder="Please enter your Company Name"
                      control={control2}
                      rules={{ required: "Company Name cannot be empty." }}
                    />
                  </Col>
                </Form.Group>
              </>
            )}
          </Form>
        </Card.Body>
        <div className="text-center">
          <Button
            type="submit"
            variant="primary"
            form="verificationForm"
            disabled={errors.email || !isDirty ? true : false}
          >
            {strings.go_to_assessment}
          </Button>
        </div>
      </Card>
    </Container>
  );
}

export default SurveyContactVerification;
