import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { AnsweredQuestion, AnswerOption } from "../../models/survey";
import ActionPriority from "./ActionPriority";
import NAOption from "./NAOption";
import { useSurveyActions } from "./SurveyActionProvider";
import "./QuestionRow.css";
import QuestionGuidanceTooltip from "../../admin/components/QuestionGuidanceTooltip";
interface QuestionProps {
  index: number;
  question: AnsweredQuestion;
  options: AnswerOption[];
  sectionName: string | undefined;
}

function QuestionRow(props: QuestionProps) {
  const { index, question, options, sectionName } = props;
  const { changeAnswer, changeActionPriority, changeComment, isCheckComplete } =
    useSurveyActions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const answerChanged = (answerId?: number) => {
    changeAnswer(question.questionId, answerId);
  };

  const priorityChanged = (evt: any) => {
    changeActionPriority(question.questionId, evt.target.value);
  };

  const commentChanged = (evt: any) => {
    changeComment(question.questionId, evt.target.value);
  };

  const isAnswered = question.naSelected || !!question.answerId;

  const answerOptionCssClass = (i: number) => {
    if (isAnswered || !isCheckComplete) {
      return "text-center";
    }

    if (i === 0 && !question.allowNA) {
      return "text-center answer-error answer-error-left";
    }

    if (i === options.length - 1) {
      return "text-center answer-error answer-error-right";
    }

    return "text-center answer-error";
  };

  return (
    <tr>
      <td
        style={{
          padding: 0,
        }}
      >
        <QuestionGuidanceTooltip
          guidance={question.guidance}
          sectionName={sectionName}
        />
      </td>
      <td>
        {<b>{String.fromCharCode(97 + index) + ". "}</b>}
        {question.question}
      </td>

      <td className="text-center p-0">
        {question.allowNA ? (
          <div
            className={
              question.allowNA && !isAnswered && isCheckComplete
                ? "answer-error-left answer-error"
                : ""
            }
          >
            <NAOption
              name={question.questionId.toString()}
              checked={question.naSelected && question.allowNA}
              onChange={function () {
                return answerChanged();
              }}
            />
          </div>
        ) : (
          <span>-</span>
        )}
      </td>

      {options.map((o, i) => (
        <td className="p-0" key={o.id}>
          <div className={answerOptionCssClass(i)}>
            <Form.Check
              type="radio"
              aria-label={`radio_${i + 1}`}
              name={question.questionId.toString()}
              checked={question.answerId === o.id}
              onChange={(function (answerId) {
                return function () {
                  return answerChanged(answerId);
                };
              })(o.id)}
            />
          </div>
        </td>
      ))}

      <td>
        <ActionPriority
          chooseNA={question.naSelected}
          value={question.naSelected ? "" : question.actionPriority}
          onChange={priorityChanged}
          className={
            !question.naSelected && !question.actionPriority && isCheckComplete
              ? "priority-error"
              : ""
          }
        />
      </td>

      <td className="text-center">
        <Form.Control
          size="sm"
          type="text"
          onChange={commentChanged}
          value={question.comment ?? ""}
        />
      </td>
    </tr>
  );
}

export default QuestionRow;
