import { handleResponse, handleBlobResponse } from "../helpers/handle-response";
import {
  Survey,
  SurveyInfo,
  SurveyVerificationData,
  SurveyVerificationStatus,
} from "../models/survey";
import { SurveyScore } from "../models/survey-score";
import sessionService from "./session.service";

class SurveyService {
  verifyContactInfo(
    surveyId: string,
    payload: SurveyVerificationData
  ): Promise<any> {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...payload, surveyId }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/surveys/verification`,
      requestOptions
    ).then(handleResponse);
  }

  getMineSurveys() {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/surveys`,
      requestOptions
    )
      .then(handleResponse)
      .then((surveyInfos) =>
        surveyInfos.map((s: SurveyInfo) => ({
          ...s,
          dueDate: new Date(s.dueDate),
        }))
      );
  }

  getSurveyResult(id: string): Promise<SurveyScore | undefined> {
    const currentUser = sessionService.getCurrentUser();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/surveys/${id}/scores`,
      requestOptions
    ).then(handleResponse);
  }

  getById(id: string): Promise<Survey | undefined> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/surveys/${id}`,
      requestOptions
    ).then(handleResponse);
  }

  getSurveyVerificationStatus(id: string): Promise<SurveyVerificationStatus> {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/surveys/${id}/status`,
      requestOptions
    ).then(handleResponse);
  }

  updateSurvey(id: string, survey: Survey): Promise<SurveyInfo> {
    const currentUser = sessionService.getCurrentUser();
    const payload = {
      email: survey.email,
      slug: survey.id,
      questions: survey.questions.map((q) => ({
        actionPriority: q.actionPriority,
        allowNA: q.allowNA,
        answerId: q.answerId,
        comment: q.comment,
        naSelected: q.naSelected,
        questionId: q.questionId,
      })),
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
      body: JSON.stringify(payload),
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/surveys/${id}`,
      requestOptions
    ).then(handleResponse);
  }

  submitSurvey(id: string, survey?: Survey): Promise<void> {
    const currentUser = sessionService.getCurrentUser();

    let payload = {};
    if (survey !== undefined) {
      payload = {
        email: survey.email,
        slug: survey.id,
        questions: survey.questions.map((qs) => ({
          actionPriority: qs.actionPriority,
          allowNA: qs.allowNA,
          answerId: qs.answerId,
          comment: qs.comment,
          naSelected: qs.naSelected,
          questionId: qs.questionId,
        })),
      };
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + currentUser?.token,
      },
      body: JSON.stringify(payload),
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/surveys/${id}/submit`,
      requestOptions
    )
      .then(handleResponse)
      .then(() => {
        console.log("Save data successfully");
      });
  }

  getSurveyReportPdf(id: string) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        surveyId: id,
        email: sessionService.getClientEmail(),
        reportType: "SurveyReport",
      }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/Reports`,
      requestOptions
    ).then(handleBlobResponse);
  }

  getAdminSurveyReportPdf(id: string) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionService.getCurrentUser()?.token,
      },
      body: JSON.stringify({
        surveyId: id,
        reportType: "SurveyReport",
      }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/Reports/admin`,
      requestOptions
    ).then(handleBlobResponse);
  }

  getSurveyDraftVersionPdf(id: string) {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        surveyId: id,
        email: sessionService.getClientEmail(),
        reportType: "SurveyDraftVersion",
      }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/Reports`,
      requestOptions
    ).then(handleBlobResponse);
  }
}

export const surveyService = new SurveyService();
