import { Alert, Button, Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import InputWithLabel from "../components/common/InputWithLabel";
import Title from "../components/common/Title";
import adminService from "../services/admin.service";
import { strings } from "../../../localization/en";
import { sectionRegex } from "../../helpers/regex-pattern";
import {
  TypeNotification,
  useNotification,
} from "../../../components/NotificationProvider";
import { useNavigate } from "react-router";

function AddNewSection() {
  const navigate = useNavigate();
  const { showInfo } = useNotification();
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const handleAddNewSection = handleSubmit(async (data) => {
    adminService.createSection(data).then((_) => {
      showInfo(strings.section_created_message, TypeNotification.success, 3000);
      navigate("/admin/sections");
    });
  });

  return (
    <Container fluid>
      <Title content={strings.section_add_title} />
      <Container fluid className="bg-white p-4">
        {Object.keys(errors).length !== 0 ? (
          <Alert variant="danger">
            <ul className="m-0">
              {Object.keys(errors).map((e) => (
                <li key={e}>{(errors as any)[e].message}</li>
              ))}
            </ul>
          </Alert>
        ) : (
          <></>
        )}

        <Form>
          <InputWithLabel
            label={strings.section_label_section}
            name="name"
            control={control}
            placeholder={strings.section_placeholder_section}
            rules={{
              required: strings.section_error_section_empty,
              pattern: {
                value: sectionRegex.name,
                message: strings.section_error_section_format,
              },
            }}
          />
          <InputWithLabel
            label={strings.section_label_description}
            name="description"
            control={control}
            placeholder={strings.section_placeholder_description}
            rules={{
              required: strings.section_error_description_empty,
            }}
          />
          <div className="text-center mt-5">
            <Button data-testid="save-btn" onClick={handleAddNewSection}>
              {strings.save_changes_button}
            </Button>
          </div>
        </Form>
      </Container>
    </Container>
  );
}

export default AddNewSection;
