import Icon from "@mdi/react";
import { mdiAlertOutline } from "@mdi/js";
import { strings } from "../../../localization/en";

function SurveyNotFound() {
  return (
    <div className="alert alert-danger d-flex px-0 py-4" role="alert">
      <Icon path={mdiAlertOutline} size={3} className="mx-5" />
      <div className="d-flex flex-column text-dark">
        <h2>{strings.assessmentlink_notfound}</h2>
        <p className="m-0 mt-auto">{strings.pls_contact_manager}</p>
      </div>
    </div>
  );
}

export default SurveyNotFound;
