type LogoWrapperProps = {
  className?: string;
  children?: React.ReactNode;
  link: string;
  target?: React.HTMLAttributeAnchorTarget;
  rel?: string;
};

function LogoWrapper ({
  className,
  children,
  link,
  target = "_blank",
  rel = "noopener noreferrer"
}: LogoWrapperProps) {
  return (
    <a className={className} href={link} target={target} rel={rel}>
      {children}
    </a>
  );
}
export default LogoWrapper;
