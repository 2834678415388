import { useEffect, useState } from "react";
import { surveyService } from "../../../services/survey.service";
import { Card, Form, Table } from "react-bootstrap";
import { AnswerOption, Survey, SurveyState } from "../../../models/survey";
import SurveyNotFound from "../SurveyNotFound";
import "./SurveyDetail.css";
import { useParams } from "react-router";
import "./CaseStudy.css";
import Header from "./Header";
import { strings } from "../../../../localization/en";
import styles from "./SurveyDetail.module.scss";
function SurveyDetail() {
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [survey, setSurvey] = useState<Survey | undefined>(undefined);

  useEffect(() => {
    surveyService
      .getById(id!)
      .then((_survey: Survey | undefined) => {
        if (_survey && _survey.status === SurveyState.Submitted) {
          setSurvey(_survey);
        } else {
          setError(true);
        }
      })
      .catch((_err) => {
        setError(true);
      });
  }, [id, setError]);

  function getSectionName(name: string, description: string) {
    return name.split(" ")[1].toUpperCase() + ": " + description;
  }

  function getCharacterQuestion(idxQuestion: number) {
    return String.fromCharCode(97 + idxQuestion) + ". ";
  }

  function getAnswered(
    subSectionOptions: AnswerOption[],
    answeredId: number | undefined,
    naSelected: boolean
  ) {
    let value = naSelected ? "N/A" : "-";
    if (!!answeredId) {
      subSectionOptions.some((option: AnswerOption) => {
        const flag = option.id === answeredId;
        if (flag) value = option.answer;
        return flag;
      });
    }
    return value;
  }

  return (
    <div className="survey-page-report">
      {error && <SurveyNotFound />}
      {!survey ? null : (
        <>
          <Header />
          <p className="normal page-header">
            <b>
              <span>Your Completed Assessment</span>
            </b>
          </p>
          {survey.sections.map((section) => (
            <Card key={section.id} className="border-none">
              <Card.Body className="py-1 px-0">
                {survey.subSections
                  .filter((ss) => ss.sectionId === section.id)
                  .map((subSection, i) => (
                    <div key={i} className="wrapper">
                      <div className="page-break-after-auto"></div>
                      {i === 0 ? (
                        <div
                          className={`${
                            styles[
                              "section-header-" +
                                section.name.split(" ")[1].toLowerCase()
                            ]
                          } section-header p-3 wrapper-section`}
                          style={{ pageBreakInside: "avoid" }}
                        >
                          <span className="py-0 my-0">
                            {getSectionName(section.name, section.description)}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                      <Table borderless className="mx-0">
                        <thead>
                          <tr>
                            <th colSpan={7} className="p-0">
                              <div
                                className={`${
                                  styles[
                                    "sub-section-header-" +
                                      section.name.split(" ")[1].toLowerCase()
                                  ]
                                } py-2 px-2`}
                              >
                                <span className="my-0 fs-1rem">
                                  {subSection.name}
                                </span>
                              </div>
                            </th>
                          </tr>

                          <tr className={`${styles["questions"]} mx-0`}>
                            <th
                              style={{ width: "40%" }}
                              className="text-center bg-white mt-5"
                            ></th>
                            <th
                              style={{
                                width: "5%",
                                borderRadius: "7px",
                              }}
                              className="text-center border-left-right"
                            >
                              N/A
                            </th>
                            {subSection.options.map((opt) => (
                              <th
                                key={opt.id}
                                style={{ width: "12%", borderRadius: "7px" }}
                                className="text-center border-left-right"
                              >
                                {opt.answer}
                              </th>
                            ))}
                            <th
                              style={{ width: "7%", borderRadius: "7px" }}
                              className="text-center"
                            >
                              {strings.action_priority}
                            </th>
                          </tr>
                        </thead>

                        <tbody className="border-top-none">
                          {survey.questions
                            .filter((q) => q.subSectionId === subSection.id)
                            .map((question, idxQuestion) => (
                              <tr key={idxQuestion} className="py-3">
                                <td>
                                  <b>{getCharacterQuestion(idxQuestion)}</b>
                                  {question.question}
                                  <span className="d-block comment">
                                    {!!question.comment ? (
                                      <span>
                                        (<i>Comment: </i>{" "}
                                        {`${question.comment})`}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </td>
                                <td className="text-center ">
                                  {!question.allowNA ? (
                                    "-"
                                  ) : (
                                    <Form.Check
                                      type="radio"
                                      checked={question.naSelected}
                                      readOnly
                                    />
                                  )}
                                </td>
                                {subSection.options.map((opt) => (
                                  <td key={opt.id} className="text-center">
                                    <Form.Check
                                      type="radio"
                                      checked={question.answerId === opt.id}
                                      readOnly
                                    />
                                  </td>
                                ))}

                                <td className="text-center ">
                                  {question.naSelected
                                    ? "-"
                                    : question.actionPriority}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  ))}
              </Card.Body>
            </Card>
          ))}
        </>
      )}
    </div>
  );
}

export default SurveyDetail;
