import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
import { IconContext } from "react-icons";
import { HiExclamationCircle } from "react-icons/hi";
type Props = {
  information: string;
  className?: string;
  fill?: string;
};
function InformationTooltip({
  information,
  className,
  fill = "#0d6efd",
}: Props) {
  const renderTooltip = (props: any) => (
    <BootstrapTooltip {...props}>{information}</BootstrapTooltip>
  );
  return (
    <OverlayTrigger
      placement="top"
      overlay={renderTooltip}
      trigger={["hover", "focus"]}
    >
      {({ ref, ...triggerHandler }) => (
        <div
          ref={ref}
          {...triggerHandler}
          className={className}
          style={{
            background: "white",
            borderRadius: "50%",
          }}
        >
          <HiExclamationCircle
            fill={fill}
            size={28}
            stroke="white"
            strokeWidth={1}
          />
        </div>
      )}
    </OverlayTrigger>
  );
}

export default InformationTooltip;
