import { Dispatch, SetStateAction } from "react";
import { Button, Modal } from "react-bootstrap";
import { strings } from "../../../../localization/en";

type ModalProps = {
  show?: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  handleShow?: Function;
  handleClose?: Function;
  handleConfirm?: Function;
};

function ConfirmDeleteModal({
  show = false,
  setShow,
  handleShow = () => setShow(true),
  handleClose = () => setShow(false),
  handleConfirm = () => setShow(false),
}: ModalProps) {
  return (
    <Modal
      show={show}
      onHide={() => handleClose()}
      onShow={() => handleShow()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{strings.delete_confirm_title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{strings.delete_confirm_message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleClose()}>
          {strings.delete_confirm_modal_cancel}
        </Button>
        <Button variant="danger" onClick={() => handleConfirm()}>
          {strings.delete_confirm_modal_OK}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ConfirmDeleteModal;
