import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { maturityLevelTexts } from "../../../admin/constants/maturity-level-texts";
import { strings } from "../../../../localization/en";
import { SurveyScore } from "../../../models/survey-score";
import { surveyService } from "../../../services/survey.service";
import Header from "./Header";
import ReportOverallResultLogo from "./ReportOverallResultLogo";
import ReportSubSectionBreakdown from "./ReportSubSectionBreakdown";
import "./SurveySummaryReport.css";
import { Radar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { spiderGraphBackgroundGenerator } from "../../../helpers/spider-graph-background-generator";
import { fillSpiderGraphBackground } from "../../../helpers/fill-spidergraph-background";
import { extractName } from "../../../helpers/extract-name";
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: any[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    pointBackgroundColor: string;
    pointRadius: number;
  }[];
}

function SurveySummaryReport() {
  const [data, setData] = useState<ChartData | undefined>(undefined);
  const [base64Image, setBase64Image] = useState("");
  const { id } = useParams();
  const [surveyScore, setSurveyScore] = useState<SurveyScore | undefined>(
    undefined
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    id &&
      surveyService
        .getSurveyResult(id)
        .then((score: SurveyScore | undefined) => {
          if (!score) {
            throw new Error("Survey score not found");
          }
          setSurveyScore(score);
          setData({
            labels: score.subSections.map((x) => x.subSection),
            datasets: [
              {
                label: "Sub-section %",
                data: score.subSections.map((x) => x.percentage),
                backgroundColor: "rgba(255,255,255,0.4)",
                borderColor: "#FFFFFF",
                borderWidth: 10,
                pointBackgroundColor: "#FFFFFF",
                pointRadius: 12,
              },
            ],
          });
          setLoading(false);
        });
  }, [id]);
  if (loading) {
    return (
      <div className="survey-score">
        <Spinner animation="border" className="loading" />
      </div>
    );
  }

  return (
    <>
      <div>
        <Header />
        <div className="mt-3">
          <p data-testid="survey-header" className="normal page-header">
            <b>
              {strings.assessment_result_header}
              {surveyScore?.contact.company}
            </b>
          </p>
          <ReportOverallResultLogo category={surveyScore?.category} />
          <p className="text-start normal fw-bold fst-italic">
            {strings.assessment_result_intro}
          </p>
          <p className="text-start normal mb-4">
            {strings.assessment_result_content}
          </p>
          <div className="overall-maturity-border p-4">
            <p className="mb-3 fw-bold fst-italic normal mt-0">
              {strings.overall_maturity_label} {surveyScore?.category}
            </p>
            <p className="m-0 normal">
              {surveyScore?.category !== undefined
                ? maturityLevelTexts[
                    surveyScore?.category as string as keyof typeof maturityLevelTexts
                  ]
                : ""}
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <div className="text-center spider-graph-summary-wrapper">
              <img src={base64Image} alt="Image" height="100%" width="100%" />
              <div className="custom-legend-summary">
                {surveyScore?.sections.map((section) => (
                  <div
                    key={section.section}
                    style={{
                      backgroundColor: spiderGraphBackgroundGenerator(
                        extractName(section.section)
                      ),
                    }}
                  >
                    {extractName(section.section)}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "0",
              height: "0",
              overflow: "hidden",
              position: "absolute",
            }}
          >
            <div style={{ width: "3000px", opacity: 0 }}>
              <Radar
                data={data!}
                plugins={[
                  {
                    afterDraw: (chart) => {
                      setBase64Image(chart.toBase64Image());
                    },
                    id: "afterDraw",
                  },
                  {
                    beforeDraw: (chart) => {
                      const { ctx, scales } = chart;
                      const {
                        xCenter,
                        yCenter,
                        drawingArea: radius,
                      } = scales.r as any;
                      fillSpiderGraphBackground(xCenter, yCenter, radius, ctx);
                    },
                    id: "beforeDatasetDraw",
                  },
                  ChartDataLabels,
                ]}
                options={{
                  responsive: true,
                  animation: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    datalabels: {
                      display: false,
                    },
                  },
                  scales: {
                    r: {
                      pointLabels: {
                        font: {
                          size: 50,
                        },
                        padding: 60,
                      } as any,
                      ticks: {
                        display: false,
                        stepSize: 20,
                      },
                      grid: {
                        color: "#FFFFFF",
                        lineWidth: 7,
                      },
                      angleLines: {
                        display: false,
                      },

                      min: 0,
                      max: 100,
                    },
                  },
                  elements: {
                    point: {
                      borderWidth: 10,
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ pageBreakAfter: "always" }}></div>
      <div>
        <Header />
        <ReportSubSectionBreakdown surveyScore={surveyScore} />
      </div>
    </>
  );
}

export default SurveySummaryReport;
