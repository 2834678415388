import b4siConstants from "./branding/b4siConstants";

export const b4si = {
  colors: {
    firstText: b4siConstants.gray1,
    secondText: b4siConstants.darkBlue,
    thirdText: b4siConstants.white,
    fourthText: b4siConstants.blue,
    fifthText: b4siConstants.purple,
    sixthText: b4siConstants.teal,
    seventhText: b4siConstants.orange,
    eighthText: b4siConstants.green,
    nineText: b4siConstants.black,

    firstBackground: b4siConstants.white,
    secondBackground: b4siConstants.blue,
    thirdBackground: b4siConstants.gray2,
    fourthBackground: b4siConstants.darkBlue,
    fifthBackground: b4siConstants.yellow,
    sixthBackground: b4siConstants.orange,
    seventhBackground: b4siConstants.gray0,

    firstBorder: b4siConstants.gray2,
    secondBorder: b4siConstants.white,
    thirdBorder: b4siConstants.darkBlue,
    fourthBorder: b4siConstants.yellow,
    fifthBorder: b4siConstants.orange,
    sixthBorder: b4siConstants.green,
    seventhBorder: b4siConstants.gray0,

    firstAccordion: b4siConstants.navy,
    secondAccordion: b4siConstants.yellow,
    thirdAccordion: b4siConstants.purple,
    fourthAccordion: b4siConstants.orange,
    fifthAccordion: b4siConstants.teal,

    firstCard: b4siConstants.purple,
    secondCard: b4siConstants.orange,
    thirdCard: b4siConstants.teal,
  },
  logos: {
    allWhite: b4siConstants.logoAllWhite,
    allWhiteCrop: b4siConstants.logoAllWhiteCrop,
  },
  fontFamily: {
    headlines: b4siConstants.fontFamilyHeadlines,
    body: b4siConstants.fontFamilyBody,
  },
};
