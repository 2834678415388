import { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";

type AppProps = {
  name: string;
  value?: any;
  control: any;
  rules?: any;
  label: string;
  placeholder: string;
};

function TextareaWithLabel({
  name,
  value,
  control,
  rules,
  label,
  placeholder,
}: AppProps) {
  useEffect(() => {
    const el = document.getElementById(name);

    el!.style.height = el!.scrollHeight + "px";
  }, [value]);
  return (
    <Form.Group as={Row} className="mb-3 mx-0">
      <Form.Label
        column
        className="bg-label py-3 fw-bold d-flex align-items-center"
        sm="2"
      >
        {label}
      </Form.Label>
      <Col sm="10" className="position-relative px-0">
        <Controller
          name={name}
          defaultValue={value}
          control={control}
          render={({ field, formState }) => {
            const { errors } = formState;
            const { onChange, value, ...rest } = field;
            return (
              <Form.Control
                value={value}
                className="rounded-0"
                style={{
                  overflow: "hidden",
                  resize: "none",
                  height: "56px",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
                id={name}
                placeholder={placeholder}
                isInvalid={!!errors[name]}
                as="textarea"
                rows={1}
                onChange={(e) => {
                  onChange(e);

                  const el = document.getElementById(name);

                  el!.style.height = "0px";
                  const scrollHeight = el!.scrollHeight + "px";
                  el!.style.height = scrollHeight;
                }}
                {...rest}
              />
            );
          }}
          rules={rules}
        />
      </Col>
    </Form.Group>
  );
}

export default TextareaWithLabel;
