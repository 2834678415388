import { Table } from "react-bootstrap";
import { extractName } from "../../../helpers/extract-name";
import { extractNumber } from "../../../helpers/extract-number";
import { strings } from "../../../../localization/en";
import { SurveyScore } from "../../../models/survey-score";
import styles from "./SubSectionBreakdown.module.scss";
type Props = {
  surveyScore: SurveyScore | undefined;
};
function SubSectionBreakdown({ surveyScore }: Props) {
  return (
    <>
      <p className="text-center fs-5 fw-bold" style={{ marginTop: "70px" }}>
        {strings.sub_section_breakdown_header}
      </p>
      <div className={styles.wrapper}>
        <div className={styles.breakdown}>
          {surveyScore?.sections.map((section, i) => {
            const filteredSubsections = surveyScore?.subSections.filter(
              (subsection) => subsection.section === section.section
            );
            return (
              <div key={i}>
                <Table
                  className={`text-center fixed-table custom-table-2 ${styles["table-wrapper"]}`}
                  size="sm"
                  bordered
                >
                  <colgroup>
                    <col width="10%" />
                    <col width="60%" />
                    <col width="30%" />
                  </colgroup>

                  <tbody>
                    <tr className="fw-bold ">
                      <td
                        colSpan={2}
                        className={`py-3 fs-6 text-uppercase ${
                          styles[`left-table-header-${i}`]
                        }`}
                      >
                        {extractName(section.section)}
                      </td>
                      <td
                        data-testid={`section-${extractNumber(
                          section.section
                        )}-category`}
                        className={`py-3 fs-6 ${styles["right-table-header"]} ${
                          styles[
                            `section-result-cell-${section.category.toLowerCase()}`
                          ]
                        }`}
                      >
                        {section.category}
                      </td>
                    </tr>
                    {filteredSubsections.map((subSection) => (
                      <tr key={subSection.subSection}>
                        <td className={styles[`table-body-${i}`]}>
                          {extractNumber(subSection.subSection)}
                        </td>
                        <td
                          className={`text-start ${styles[`table-body-${i}`]}`}
                        >
                          {extractName(subSection.subSection)}
                        </td>
                        <td
                          className={
                            styles[
                              `sub-section-result-cell-${subSection.category.toLowerCase()}`
                            ]
                          }
                          data-testid={`subsection-${extractNumber(
                            subSection.subSection
                          )}-category`}
                          // style={{
                          //   backgroundColor: backgroundSecondaryColorGenerator(
                          //     subSection.category
                          //   ),
                          //   color: "#343a40",
                          // }}
                        >
                          {subSection.category}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SubSectionBreakdown;
