import InfoTooltip from "./InfoTooltip/InfoTooltip";
import "./QuestionGuidanceTooltip.css";
type AppProps = {
  guidance: string;
  sectionName: string | undefined;
};
function QuestionGuidanceTooltip({ guidance, sectionName }: AppProps) {
  return (
    <div className="wrapper-info-tooltip">
      {!!guidance ? (
        <InfoTooltip content={guidance} sectionName={sectionName} />
      ) : (
        <div className="spacing"></div>
      )}
    </div>
  );
}

export default QuestionGuidanceTooltip;
