import { ErrorMessage } from "@hookform/error-message";
import { Alert, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";

type Props = {
  name: string;
  control: any;
  placeholder: string;
  type?: string;
  rules: any;
};
function VerificationInput ({
  name,
  control,
  placeholder,
  type = "text",
  rules
}: Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        const { errors } = formState;
        return (
          <>
            <Form.Control type={type} {...field} placeholder={placeholder} />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) =>
                message !== undefined
                  ? (
                  <Alert variant="danger" className="p-2 mt-2">
                    {message}
                  </Alert>
                    )
                  : (
                  <></>
                    )
              }
            />
          </>
        );
      }}
      rules={rules}
    />
  );
}

export default VerificationInput;
