import { Table } from "react-bootstrap";
import { AnsweredQuestion, SubSection } from "../../models/survey";
import QuestionHeaderRow from "./QuestionHeaderRow";
import QuestionRow from "./QuestionRow";
import styles from "./SubSectionPage.module.scss";

interface SubSectionProps {
  sectionName: string | undefined;
  subSection: SubSection;
  questions: AnsweredQuestion[];
}
function SubSectionPage(props: SubSectionProps) {
  const { subSection, questions, sectionName } = props;

  return (
    <div className="subsection">
      <div
        className={`${styles["sub-section-header-" + sectionName]} py-2 px-2`}
      >
        <h6 className="my-0">{subSection.name}</h6>
      </div>
      <Table
        className={`${styles["sub-section-table"]}`}
        responsive="sm my-1"
        borderless
      >
        <thead className="sub-section-header">
          <QuestionHeaderRow options={subSection.options} />
        </thead>
        <tbody style={{ borderTop: "none" }}>
          {questions
            .filter((q) => q.subSectionId === subSection.id)
            .map((q, i) => (
              <QuestionRow
                key={q.questionId}
                question={q}
                options={subSection.options}
                sectionName={sectionName}
                index={i}
              />
            ))}
        </tbody>
      </Table>
    </div>
  );
}

export default SubSectionPage;
