import { strings } from "../../../localization/en";
import InfoTooltip from "../../admin/components/InfoTooltip/InfoTooltip";
import { AnswerOption } from "../../models/survey";

interface HeaderProps {
  options: AnswerOption[];
}
function QuestionHeaderRow(props: HeaderProps) {
  const { options } = props;
  return (
    <tr>
      <th
        style={{
          padding: 0,
          width: "0.25%",
          backgroundColor: "#fff",
        }}
      ></th>
      <th
        className="text-center "
        style={{ width: "35%", backgroundColor: "#fff" }}
      ></th>
      <th
        className="text-center border-left-right"
        style={{ width: "5%", borderRadius: "10px" }}
      >
        {strings.na_question}
      </th>

      {options.map((o, i) => (
        <th
          className="text-center border-left-right"
          style={{ width: "5%", borderRadius: "10px" }}
          key={i}
        >
          {o.answer}
        </th>
      ))}

      <th
        className="text-center border-left-right"
        style={{ width: "10%", borderRadius: "10px", position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            top: "4px",
            right: "4px",
          }}
        >
          <InfoTooltip
            content={strings.action_priority_description}
            position="top"
          />
        </div>
        {strings.action_priority}
      </th>
      <th
        className="text-center"
        style={{ width: "15%", borderRadius: "10px" }}
      >
        {strings.comment} <br /> {strings.optional}
      </th>
    </tr>
  );
}

export default QuestionHeaderRow;
