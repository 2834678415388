import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { capitalizeFirstLetter } from "../../../helpers/capitalize-first-letter";
import "./AnswerSetInput.css";
type AppProps = {
  name: string;
  value?: string;
  control: any;
  rules: any;
};
function AnswerSetInput({ name, value, control, rules }: AppProps) {
  useEffect(() => {
    const el = document.getElementById(name);
    el!.style.height = el!.scrollHeight + "px";
  }, [value]);
  return (
    <Controller
      name={name}
      defaultValue={value}
      control={control}
      render={({ field, formState }) => {
        const { errors } = formState;
        const { onChange, value, ...rest } = field;
        return (
          <div className="position-relative">
            <Form.Control
              as={"textarea"}
              rows={1}
              id={name}
              value={value}
              className="rounded-0 text-center answer-set-input"
              placeholder="Free text field"
              isInvalid={!!errors[name]}
              onChange={(e) => {
                e.target.value = capitalizeFirstLetter(e.target.value);
                onChange(e);

                const el = document.getElementById(name);

                el!.style.height = "0px";
                const scrollHeight = el!.scrollHeight + "px";
                el!.style.height = scrollHeight;
              }}
              {...rest}
            />
          </div>
        );
      }}
      rules={rules}
    />
  );
}

export default AnswerSetInput;
