import React, { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import { BiEditAlt } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router";
import {
  TypeNotification,
  useNotification,
} from "../../../components/NotificationProvider";
import { strings } from "../../../localization/en";
import { Section } from "../../models/survey";
import ConfirmDeleteModal from "../components/common/ConfirmDeleteModal";
import Title from "../components/common/Title";
import HeaderFilter from "../components/HeaderFilter";
import { SubsectionViewModel } from "../models";
import adminService from "../services/admin.service";

type StoredFilter = {
  selectedSection: string;
};

let deletingId: number;

function ManageSubSections({ viewOnly }: { viewOnly: boolean }) {
  const location = useLocation();
  const [subSections, setSubSections] = useState<SubsectionViewModel[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  const [selectedSection, setSelectedSection] = useState<string>("Section");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { showError, showInfo } = useNotification();

  useEffect(() => {
    adminService.getAllSubSections().then((respondedSubSections) => {
      setSubSections(respondedSubSections);
    });
    adminService.getAllSections().then((respondedSections) => {
      setSections(respondedSections);
    });
  }, []);

  useEffect(() => {
    if (location.state)
      setSelectedSection((location.state as StoredFilter).selectedSection);
  }, [location]);

  const handleShowModal = (id: number) => {
    deletingId = id;
    setShowModal(true);
  };

  const deleteSubsection = () => {
    adminService
      .deleteSubsection(deletingId)
      .then((_) => {
        showInfo(
          strings.subsection_deleted_message,
          TypeNotification.success,
          3000
        );
        adminService.getAllSubSections().then((response) => {
          setSubSections(response);
        });
      })
      .catch((error) => {
        showError(error.status, strings.server_error);
      })
      .finally(() => setShowModal(false));
  };

  const filteredSubSections =
    selectedSection === "Section"
      ? subSections
      : subSections.filter((s) => s.section === selectedSection);

  const subSectionDescriptionColWidth = viewOnly ? "24%" : "20%";
  const subSectionAttrColWidth = viewOnly ? "12%" : "13%";
  return (
    <Container fluid>
      <Title content="Manage Sub-Sections" />
      <Container fluid className="p-4 bg-white">
        <Table className="custom-table">
          <colgroup>
            <col span={1} style={{ width: "24%" }} />
            <col span={1} style={{ width: subSectionDescriptionColWidth }} />
            <col span={1} style={{ width: subSectionAttrColWidth }} />
            <col span={1} style={{ width: subSectionAttrColWidth }} />
            <col span={1} style={{ width: subSectionAttrColWidth }} />
            <col span={1} style={{ width: subSectionAttrColWidth }} />
            {!viewOnly && (
              <React.Fragment>
                <col span={1} style={{ width: "4%" }} />
                <col span={1} style={{ width: "4%" }} />
              </React.Fragment>
            )}
          </colgroup>
          <thead className="sticky-top table-gray text-center">
            <tr>
              <th className="p-0 h-100">
                <HeaderFilter
                  defaultValue="Section"
                  selectedValue={selectedSection}
                  setSelectedValue={setSelectedSection}
                  options={sections}
                />
              </th>
              <th>{strings.subsection_table_header_subsection}</th>
              <th>{strings.label_option_1}</th>
              <th>{strings.label_option_2}</th>
              <th>{strings.label_option_3}</th>
              <th>{strings.label_option_4}</th>
              {!viewOnly && (
                <React.Fragment>
                  <th>{strings.table_header_edit}</th>
                  <th>{strings.table_header_delete}</th>
                </React.Fragment>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredSubSections.map((subSection, i) => {
              return (
                <tr key={i}>
                  <td>{subSection.section}</td>
                  <td>{subSection.name}</td>
                  <td className="text-center">{subSection.option1}</td>
                  <td className="text-center">{subSection.option2}</td>
                  <td className="text-center">{subSection.option3}</td>
                  <td className="text-center">{subSection.option4}</td>
                  {!viewOnly && (
                    <React.Fragment>
                      <td className="text-center">
                        <BiEditAlt
                          fontSize={25}
                          onClick={() => {
                            navigate(
                              `/admin/sub-sections/edit/${subSection.id}`,
                              {
                                state: { selectedSection },
                              }
                            );
                          }}
                          className="icon-button"
                        />
                      </td>
                      <td className="text-center">
                        <IoTrashOutline
                          className="icon-button"
                          fontSize={25}
                          onClick={() =>
                            (function (id) {
                              handleShowModal(id);
                            })(subSection.id)
                          }
                        />
                      </td>
                    </React.Fragment>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Container>
      <ConfirmDeleteModal
        show={showModal}
        setShow={setShowModal}
        handleConfirm={deleteSubsection}
      />
    </Container>
  );
}

export default ManageSubSections;
