import { handleResponse } from "../helpers/handle-response";
import { User } from "../models/user";

class SessionService {
  private _sessionKey = "_user";

  private setCurrentUser(user: User | any): User | any {
    if (!user) {
      localStorage.removeItem(this._sessionKey);
    } else {
      localStorage.setItem(this._sessionKey, JSON.stringify(user));
    }
    return user;
  }

  getCurrentUser(): User | any {
    const user = localStorage.getItem(this._sessionKey);
    if (user) {
      return JSON.parse(user);
    }

    return undefined;
  }

  getClientEmail() {
    const currentUser = this.getCurrentUser();
    return currentUser?.client.email;
  }

  register(
    name: string,
    email: string,
    password: string
  ): Promise<User | undefined> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: name, email, password }),
    };

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/register`,
      requestOptions
    ).then(handleResponse);
  }

  refreshToken(): Promise<User | undefined> {
    const { token, refreshToken } = this.getCurrentUser()!;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token, refreshToken }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/refreshToken`,
      requestOptions
    )
      .then(handleResponse)
      .then((user) => {
        return this.setCurrentUser(user);
      });
  }

  getMyInfo(): Promise<any> {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getCurrentUser()?.token,
      },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/me`,
      requestOptions
    ).then(handleResponse);
  }

  logClientIn(email: string) {
    const currentUser = this.getCurrentUser();
    this.setCurrentUser({ ...currentUser, client: { email } });
  }

  logOut(): Promise<void> {
    const currentUser = this.getCurrentUser();
    if (!currentUser) {
      return new Promise<void>((resolve) => {
        resolve();
      });
    }

    const { token, refreshToken } = currentUser;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token, refreshToken }),
    };

    this.setCurrentUser(undefined);

    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/logout`,
      requestOptions
    ).then(handleResponse);
  }

  existUser(email: string): Promise<any> {
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/exist/${email}`
    ).then(handleResponse);
  }

  portalLogin(email: string, password: string): Promise<User | undefined> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/pages/login`,
      requestOptions
    )
      .then(handleResponse)
      .then((user) => {
        return this.setCurrentUser(user);
      });
  }

  getAccessFailedTimes(email: string): Promise<number> {
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/pages/access-failed/${email}`
    ).then(handleResponse);
  }

  sendResetPassword(email: string): Promise<any> {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/pages/resetpassword/${email}`,
      requestOptions
    ).then(handleResponse);
  }

  resetPassword(email: string, password: string, token: string): Promise<any> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password, token }),
    };
    return fetch(
      `${process.env.REACT_APP_API_END_POINT}/auth/pages/resetpassword`,
      requestOptions
    ).then(handleResponse);
  }
}
const sessionService = new SessionService();

export default sessionService;
