import React from "react";
import { User } from "../models/user";
import sessionService from "../services/session.service";

interface AuthContextType {
  user: User | undefined;
  role: string | undefined;
  logIn: (username: string, password: string) => Promise<any>;
  compassLogIn: (
    username: string,
    password: string,
    rememberMe: boolean
  ) => Promise<any>;
  logOut: () => Promise<void>;
  verify: (email: string) => void;
  getMyInfo: () => Promise<any>;
  clientVerified: boolean;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<User | undefined>(
    sessionService.getCurrentUser()
  );
  const [role, setRole] = React.useState<string | undefined>(
    sessionService.getCurrentUser()?.role
  );
  const [clientVerified, setClientVerified] = React.useState<boolean>(false);

  const logIn = (email: string, password: string) => {
    return sessionService
      .portalLogin(email, password)
      .then((u: User | undefined) => setUser(u));
  };

  const compassLogIn = (
    email: string,
    password: string,
    rememberMe: boolean
  ) => {
    return sessionService
      .compassLogIn(email, password, rememberMe)
      .then((u: User | undefined) => {
        setUser(u);
        setRole(u?.role);
      });
  };

  const logOut = () => {
    return sessionService.logOut().then(() => {
      setUser(undefined);
      setRole(undefined);
    });
  };

  const verify = (email: string) => {
    sessionService.logClientIn(email);
    setClientVerified(true);
  };

  const getMyInfo = () => sessionService.getMyInfo();

  let value = {
    user,
    role,
    clientVerified,
    logIn,
    compassLogIn,
    logOut,
    verify,
    getMyInfo,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
