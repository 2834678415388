import { Fragment, useState } from "react";
import {
  Button,
  ButtonToolbar,
  Card,
  Container,
  Modal,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { useNotification } from "../../../components/NotificationProvider";
import { strings } from "../../../localization/en";
import { surveyService } from "../../services/survey.service";
import { useSection } from "./SectionProvider";
import SubSectionPage from "./SubSectionPage";
import { useSurveyActions } from "./SurveyActionProvider";
import { AiOutlineExclamation } from "react-icons/ai";
import styles from "./SectionPage.module.scss";
import RoundedModal, { ModalType } from "./RoundedModal";

function SectionPage() {
  const { id } = useParams();
  const navigator = useNavigate();
  const {
    hasNextSection,
    nextSection,
    hasPreviousSection,
    previousSection,
    saveSurvey,
    isSurveyFulfilled,
    setIsShowModalConfirm,
    setIsCheckComplete,
  } = useSurveyActions();

  const { section, subSections, questions } = useSection();
  const [isSubmittedSurvey, setIsSubmittedSurvey] = useState<boolean>(false);
  const [showIncompleteWarning, setShowIncompleteWarning] =
    useState<boolean>(false);
  const { showError } = useNotification();

  function statusSurveyHandler(callBack: any) {
    surveyService
      .getSurveyVerificationStatus(id ?? "")
      .then((response) => {
        if (
          response.status === "NotStarted" ||
          response.status === "InProgress"
        ) {
          callBack();
        } else if (response.status === "Submitted") {
          setIsSubmittedSurvey(true);
        } else {
          showError(undefined, strings.server_error);
        }
      })
      .catch((error) => {
        showError(undefined, strings.server_error);
      });
  }

  function saveDraftHandler() {
    statusSurveyHandler(() => saveSurvey());
  }

  function onSubmitBtnHandler() {
    if (isSurveyFulfilled) {
      statusSurveyHandler(() => setIsShowModalConfirm(true));
    } else {
      statusSurveyHandler(() => {
        setIsCheckComplete(true);
        setShowIncompleteWarning(true);
      });
    }
  }

  const extractedSectionName = section?.name.split(" ")[1].toLowerCase();

  const buttonVerticalRule = (
    <div
      className="vr mx-2 h-100 position-absolute"
      style={{ width: "2px", left: "calc(50% - 0.5rem)" }}
    ></div>
  );

  return !section ? (
    <Spinner animation="border" />
  ) : (
    <>
      <Card className="border-0">
        <Card.Header
          className={`${
            styles["section-header-" + extractedSectionName]
          } py-3 section-header mx-1`}
        >
          <h5 className="py-0 my-0">
            {section.name.split(" ")[1].toUpperCase() +
              ": " +
              section.description}
          </h5>
        </Card.Header>
        <Card.Body className="py-1 px-1">
          {subSections.map((ss, i) => (
            <div key={i}>
              <SubSectionPage
                key={i}
                sectionName={extractedSectionName}
                subSection={ss}
                questions={questions}
              />
            </div>
          ))}
        </Card.Body>
        <Card.Footer>
          <ButtonToolbar className="mt-3 mb-5">
            <Container
              fluid
              className="mb-4 survey-pagination text-center position-relative"
            >
              <Button
                data-testid="previous-button"
                className="btn-pagination me-3"
                onClick={previousSection}
                hidden={!hasPreviousSection}
              >
                {strings.button_previous_content}
              </Button>
              {!(!hasPreviousSection || !hasNextSection) && buttonVerticalRule}
              <Button
                data-testid="next-button"
                className="btn-pagination ms-3"
                onClick={nextSection}
                hidden={!hasNextSection}
              >
                {strings.button_next_content}
              </Button>
            </Container>

            <Container
              fluid
              className="d-flex justify-content-center position-relative"
            >
              <div className="w-25 text-end me-3">
                <Button
                  data-testid="save-button"
                  style={{
                    minWidth: "8rem",
                    height: "3rem",
                  }}
                  variant="primary fw-bold"
                  onClick={saveDraftHandler}
                >
                  {strings.button_save_content}
                </Button>
              </div>
              {buttonVerticalRule}
              <div className="w-25 ms-3">
                <Button
                  data-testid="submit-button"
                  style={{
                    minWidth: "8rem",
                    height: "3rem",
                  }}
                  className="fw-bold"
                  onClick={onSubmitBtnHandler}
                >
                  {strings.assessment_btn_submit}
                </Button>
              </div>
            </Container>
          </ButtonToolbar>
        </Card.Footer>
      </Card>
      <Modal
        show={isSubmittedSurvey}
        centered
        size="lg"
        onHide={() => setIsSubmittedSurvey(false)}
      >
        <Modal.Body>
          <Container className="my-3 d-flex flex-column justify-content-center align-items-center">
            <div
              style={{
                border: "0.4rem solid #fbc880",
                width: "8rem",
                height: "8rem",
                display: "flex",
                borderRadius: "50%",
              }}
            >
              <AiOutlineExclamation
                className="text-center"
                style={{ color: "#f89406", fontSize: " 5rem", margin: "auto" }}
              />
            </div>
            <p className="py-2 my-3 text-muted fs-6 text-center">
              <span
                dangerouslySetInnerHTML={{
                  __html: strings.assessment_msg_view_result,
                }}
              />
            </p>
          </Container>
        </Modal.Body>
      </Modal>
      <RoundedModal
        modalType={ModalType.Error}
        showModal={showIncompleteWarning}
        title={strings.assessment_incomplete}
        content={
          <Fragment>
            {strings.assessment_incomplete_explain}
            <br />
            {strings.assessment_incomplete_cta}
          </Fragment>
        }
        action={strings.assessment_incomplete_action}
        hideAction={() => setShowIncompleteWarning(false)}
        handleAction={() => setShowIncompleteWarning(false)}
      />
    </>
  );
}

export default SectionPage;
