import { SurveyState } from "../models/survey";

export function surveyToString(state: any) {
  switch (state) {
    case SurveyState.NotStarted:
      return "Not Started";
    case SurveyState.InProgress:
      return "In Progress";
    case SurveyState.Submitted:
      return "Submitted";

    default:
      return "";
  }
}
