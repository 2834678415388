export const maturityLevelTexts = {
  Thoughtful:
    "As its name suggest, the thoughtful stage is characterised by an overall abundance of good ideas and intentions, but few systems in place to transform these into actionable strategies. Programmes might be planned and implemented through a philanthropic perspective and charitable giving would have a larger share of the contributions.",
  Purposeful:
    "The second maturity level in the B4SI Social Impact Compass is the Purposeful Stage. Companies at this stage will start to look very different to each other, as they evolve in their strategies and define specific plans and targets. Compared to the Thoughtful stage, Purposeful companies may have made an effort to identify key stakeholders, define KPIs and outline some key themes and programmes. However, social impact is aligned to community investment activities, with a lack of programme involvement and connection to other business units.",
  Strategic:
    "The third maturity level in the B4SI Social Impact Compass is the Strategic stage. Companies at this stage have made greater efforts to align their social impact strategy to the business, and leverage company resources to deliver the most effective programmes. At this stage, examples of social procurement or business innovation projects may be emerging and/or thorough impact measurement applied across activities.",
  Transformational:
    "The fourth and final maturity level in the B4SI Social Impact Compass is the Transformational stage. A Transformational company will very likely deliver its social impact strategy as an aligned element of its overall business strategy, playing on its strengths and leveraging relationships with suppliers, employees, clients, or customers. Projects would cover B4SI’s 3 routes to impact (Community Investment, Business Innovation and Social Procurement) and there would be thorough impact measurement systems in place. Finally, a company at this stage may have a shared value approach to social impact ensuring full engagement with stakeholders when planning and promoting wider change.",
};
