import "./CaseStudy.css";
import styles from "./CaseStudy.module.scss";
function Transformational() {
  return (
    <div>
      <p className="normal page-header">
        <b>
          <span>Your Social Impact Maturity Level - Case Studies</span>
        </b>
      </p>
      <h4 className="normal text-center">
        <span data-testid="case-header" className="page-title">
          Transformational
        </span>
      </h4>
      <p className="normal page-description">
        The fourth and final maturity level in the B4SI Social Impact Compass is{" "}
        <strong>Transformational</strong>. A Transformational company will very
        likely deliver its social impact strategy as an aligned element of its
        overall business strategy, playing on its strengths and leveraging
        relationships with suppliers, employees, clients, or customers. Projects
        would cover B4SI's 3 routes to impact (Community Investment, Business
        Innovation and Social Procurement) and there would be thorough impact
        measurement systems in place. Finally, a company at this level may have
        a shared value approach to social impact ensuring full engagement with
        stakeholders when planning and promoting wider change.
      </p>
      <p className="normal page-description">
        A company in this level may have some similarities with our Illustrative
        examples below.
      </p>
      <h6 className={`normal company-header ${styles.transformational}`}>
        <strong>Illustrative example: Healthy Life ltd</strong>
      </h6>
      <p className="normal">
        <strong>Sector:</strong> Pharmaceutical
      </p>
      <p className="normal">
        <strong>Overview:</strong> A pharmaceutical company based in Singapore,
        with operations in over 50 countries around the world and over 95,000
        employees.
      </p>
      <p className="normal">
        <strong>Plan:</strong> The business' updated sustainability strategy was
        designed following a recent materiality assessment that mapped potential
        risks and benefits of its operations for the business and the
        communities in which it operates. This social impact strategy is aligned
        across the full suite of Environmental Social & Governance activities,
        and includes activities related to diversity and inclusion, health and
        safety while also partnering with aspects of Environmental & Governance
        initiatives. The global social impact strategy has a clearly identified
        vision which is “to help people have healthier, happier lives” and 3
        themes, with defined strategic objectives.
      </p>
      <p className="normal">
        <strong>Implement:</strong> Local country specific operations determine
        operative objectives which align to these strategic aims and that feed
        into them through their KPIs. Through this process, the organisation
        makes sure every social impact initiative is aligned to global business
        sustainability.
      </p>
      <p className="normal">
        On the project level, each country office engages with leading local
        charities under each of their strategic aims to run consultation sprints
        and to engage people in the design of targets and interventions. These
        partnerships consider both the business resources and partners own
        objectives to define the most impactful projects. Partners receive
        training; accordingly, training covers gathering data, measuring impact,
        and achieving intended outcomes.
      </p>
      <p className="normal">
        The social impact strategy implementation involves community investment,
        social procurement, and business innovation inputs. In the community
        investment space, Healthy Life ltd has a flagship health and communities
        project which targets specific communities and engages with relevant
        actors on all levels, using resources from various company departments.
        Social enterprises and diverse suppliers are engaged through the social
        procurement programme which invests in these businesses to provide
        supplies such as PPE to health centres or wellbeing training for
        employees. Under business innovation, Healthy Life ltd has invested
        significantly in the past years to develop self-administered health kits
        that can be quickly deployed in emergency situations and can provide
        people with affordable access to healthcare.
      </p>
      <p className="normal">
        Employees have widespread opportunities to engage across initiatives
        regardless of their role within the organisation. The company's
        expertise in project management and knowledge of the healthcare industry
        is leveraged for its leading pro bono volunteering programme with local
        charities. The company has a thorough understanding of the impacts of
        employees volunteering and individual performance, therefore it includes
        this dimension in performance reviews and career development schemes.
      </p>
      <p className="normal">
        <strong>Measure:</strong> The progress against targets for the global
        social impact strategy is overseen by a steering group that convenes
        quarterly to assess progress and give recommendations. This group is
        formed by representatives from various business divisions that act as
        “Social Impact Champions” within their role. The Social Impact Champions
        are responsible for setting up relevant policies and training and they
        lead an annual, the group leads on a performance review. Its results and
        recommendations guide local adjustments to the social impact strategy.
      </p>
      <p className="normal">
        Data is collected across all projects, determined by relevance and
        accessibility, prioritising partner resources and providing the
        necessary tools and training.
      </p>
      <p className="normal">
        <strong>Report and Engage:</strong> Social impact reporting is assured
        annually by B4SI, covering inputs, outputs, and impacts. This data
        constitutes the core of social impact reporting which is integrated on
        the company's annual report and leveraged to engage with customers,
        talent attraction and partners through social media engagement.
      </p>
      <p className="normal" style={{ marginBottom: "0.14in" }}>
        <strong>Next steps:</strong>
      </p>
      <ul className="dashed">
        <li className="dashed">
          To leverage on its strong social impact strategy, Healthy Life might
          consider reviewing its social impact storytelling strategy to boost
          organisational reputation and inspire others to enhance their impact
          and conducting a{" "}
          <strong className="cs-text-blue">landscape analysis</strong> to
          identify multi-stakeholder initiatives, forums, and coalitions for the
          company to participate in.
        </li>
        <li className="dashed">
          Conduct a <strong>strategic</strong>{" "}
          <strong className="cs-text-blue">review of key initiatives</strong> to
          identify results to date, best practices for replication, lessons
          learned, and new areas of opportunity.
        </li>
        <li className="dashed">
          A <strong className="cs-text-blue">Bespoke Training Session</strong>{" "}
          can support to improve impact measurement capabilities across
          different teams in the business.
        </li>
        <li className="dashed">
          <strong>Assess existing </strong>{" "}
          <strong className="cs-text-blue">employee engagement</strong>{" "}
          opportunities or develop an employee engagement plan linked to social
          impact priorities. This should focus on:
          <ul>
            <li className="second-indent">
              identifying how social impact priorities are communicated to
              employees,
            </li>
            <li className="second-indent">
              the formal processes to support employee involvement,
            </li>
            <li className="second-indent">
              and the incentive structures for employees to engage in the
              achievement of social impact priorities.
            </li>
          </ul>
        </li>
        <li className="dashed">
          <strong>Review the company's key</strong>{" "}
          <strong className="cs-text-blue">social impact initiatives</strong> to
          date associated with priority issues to assess objectives and expected
          benefits to identify gaps and areas of misalignment; This information
          should feed into a{" "}
          <strong className="cs-text-blue">social impact strategy</strong>{" "}
          <strong>update/refresh.</strong>
        </li>
      </ul>
      <h6 className={`normal company-header ${styles.transformational} mt-3`}>
        <strong>Illustrative example: Threads and Things Pty Ltd</strong>
      </h6>
      <p className="normal">
        <strong>Sector:</strong> Retail
      </p>
      <p className="normal">
        <strong>Overview:</strong> A large Japanese department store
        specialising in clothing and homewares, headquartered in Tokyo.
      </p>
      <p className="normal">
        <strong>Plan:</strong> The company has a clear social purpose to make
        sustainable clothing and homewares accessible to all and it takes a
        holistic approach to its social impact programming. Social impact is
        achieved through community investment, social procurement, and business
        innovation. The company has changed one of its fundamental manufacturing
        processes for social good, creating scalable employment for regional
        communities in Japan. An established Social Impact working group plays a
        leading role in the organisation's two-yearly materiality assessment
        process, which it has committed to, to ensure they are in a position to
        lead and collaborate with all stakeholders to address the issues
        identified.
      </p>
      <p className="normal">
        <strong>Implement:</strong> Members from across the organisation are
        represented on a social impact working group including the CEO, a social
        procurement team member and the executive. Each working group member has
        responsibility for one of the company's social impact focus areas. They
        take responsibility for conducting regular external analyses relevant to
        their focus area. Key Performance Indicators are set at the beginning of
        each new financial year and progress against these are assessed on a
        quarterly basis with each member of the working group providing a
        progress update against KPIs, to continuously improve on the strategy
        and instigate pivoting of resources, where necessary.
      </p>
      <p className="normal">
        The core community engagement team are accountable for the
        implementation of strategy's action plan. The working group includes a
        representative from the leadership team and from the social procurement
        team. The B4SI framework and methodology are rigorously applied to the
        organisation's community investment, social procurement, and innovative
        endeavours. A workshop is held with community partners yearly to
        strengthen relationships, co-create initiatives and ensure strategic
        alignment.
      </p>
      <p className="normal">
        <strong>Measure:</strong> The community engagement team apply the B4SI
        guidance to all activities and the social procurement team assess the
        organisation's supplier panel in line with the B4SI guidance as part of
        their regular risk assessment and planning processes. The organisation
        is committed to the ethical sourcing of textiles and materials for their
        products. The company is committed to continuous improvement program of
        work to challenge internal assumptions and identify gaps in the
        strategic approach.
      </p>
      <p className="normal">
        An internal social impact dashboard tool was created following the first
        full year of B4SI data reporting. This dashboard enables companywide
        visibility on progress against targets across the three routes to
        impact. The dashboard also highlights areas of risk or opportunity
        across the programs within the strategy, allowing teams to take
        proactive steps towards pivoting resources and ultimately improving
        societal impacts resulting from their programs and activities.
      </p>
      <p className="normal">
        <strong>Report and Engage:</strong> A social impact paper is issued to
        the Board of Directors on a quarterly basis, depicting the corresponding
        UN Sustainable Development Goals and containing a snapshot of progress
        against strategy, in addition to highlighting areas for improvement. The
        first report of each new calendar year contains key insights from the
        B4SI Benchmark and B4SI Insight Reports, providing robust data and
        insights from peer retail organisations around the globe. An all-staff
        briefing takes place every six months, creating space for feedback on
        ongoing engagement internally around the social impact program.
      </p>
      <p className="normal">
        Community Investment Data is assured externally and is reported both in
        the sustainability report and the company's annual report.
      </p>
      <p className="normal">
        <strong>Next steps:</strong> Being strong in community Investment and
        social procurement, the Threads and Things team is now reviewing the
        possibility to develop innovative products and services that deliver a
        social impact by using the{" "}
        <strong className="cs-text-blue">
          B4SI Business Innovation for Social Impact guidance.
        </strong>
      </p>
    </div>
  );
}
export default Transformational;
