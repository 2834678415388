import * as React from "react";
import {
  Container,
  Form,
  Button,
  Modal,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { showDateValue } from "../../../../helpers/function-extensions";
import debounce from "lodash/debounce";
import {
  Ordering,
  PaginationModel,
  SurveyFilter,
  SurveyOrderByColumn,
  SurveyState,
  SurveyViewModel,
} from "../../../models";
import adminService from "../../../services/admin.service";
import TablePagination from "../TablePagination";
import { surveyToString } from "../../../../helpers/business-helper";
import { useNotification } from "../../../../../components/NotificationProvider";
import { APIError } from "../../../../models/error/api-error";
import { strings } from "../../../../../localization/en";
import { MdOutlineCheck } from "react-icons/md";
import { TiArrowUnsorted } from "react-icons/ti";
import {
  SurveyTableHeader,
  OrderingIconWrapper,
  SurveyTable as CustomTable,
  ResendSurveyButton,
  ShowEntriesWrapper,
} from "./styled";

import { SurveyCategory } from "../../../models/survey-category";
import { TypeSortingDate } from "../../../models/survey-insight-filter";
import { useAuth } from "../../../../../components/AuthProvider";
import { SurveyResendModel } from "../../../models/survey-resend";

type AppProps = {
  fromDate: string;
  toDate: string;
  typeSortingDate: TypeSortingDate;
  isCreatedSurvey: boolean;
};

const surveyTableHeader = [
  {
    id: 0,
    display: strings.dashboard_assessment_table_header_date_sent,
    orderingName: SurveyOrderByColumn.DateSent,
  },
  {
    id: 1,
    display: strings.dashboard_assessment_table_header_first_name,
    orderingName: SurveyOrderByColumn.FirstName,
  },
  {
    id: 2,
    display: strings.dashboard_assessment_table_header_surname,
    orderingName: SurveyOrderByColumn.Surname,
  },
  {
    id: 3,
    display: strings.dashboard_assessment_table_header_company,
    orderingName: SurveyOrderByColumn.Company,
  },
  {
    id: 4,
    display: strings.dashboard_assessment_table_header_email,
    orderingName: SurveyOrderByColumn.Email,
  },
  {
    id: 5,
    display: strings.dashboard_assessment_table_header_status,
    orderingName: SurveyOrderByColumn.Status,
  },
  {
    id: 6,
    display: strings.dashboard_assessment_table_header_date_submitted,
    orderingName: SurveyOrderByColumn.DateSubmitted,
  },
  {
    id: 7,
    display: strings.dashboard_assessment_table_header_maturity_attained,
    orderingName: SurveyOrderByColumn.Maturity,
  },
  {
    id: 8,
    display: strings.dashboard_assessment_table_header_link,
    orderingName: "",
  },
  {
    id: 9,
    display: strings.dashboard_assessment_table_header_result_link,
    orderingName: "",
  },
];

function SurveyTable({
  fromDate,
  toDate,
  typeSortingDate,
  isCreatedSurvey,
}: AppProps) {
  const { showError } = useNotification();
  const pages = [10, 25, 50, 100];

  const [filter, setFilter] = React.useState<SurveyFilter>({
    currentPage: 1,
    orderDirection: Ordering.Desc,
    orderBy: SurveyOrderByColumn.DateSent,
    itemsPerPage: pages[0],
    search: "",
    status: SurveyState.All,
    dateFrom: fromDate,
    dateTo: toDate,
    category: SurveyCategory.All,
    typeSortingDate: typeSortingDate,
  });

  const [isShowLoadingModal, setIsShowLoadingModal] = React.useState(false);
  const [isExportFinish, setIsExportFinish] = React.useState(false);
  const [isResending, setIsResending] = React.useState<boolean>(false);
  const [isGettingResendingItems, setIsGettingResendingItems] =
    React.useState<boolean>(false);
  const [isShowSuccessModal, setIsShowSuccessModal] =
    React.useState<boolean>(false);

  const previousFilter = JSON.stringify(filter);

  const [pagination, setPagination] = React.useState<
    PaginationModel | undefined
  >(undefined);
  const [surveys, setSurveys] = React.useState<SurveyViewModel[]>([]);
  const [resendSurveys, setResendSurveys] = React.useState<SurveyResendModel[]>(
    []
  );

  const [isSelectAllSurveys, setIsSelectAllSurveys] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (!!fromDate || !!toDate || !!typeSortingDate) {
      setFilter((prevState) => {
        return {
          ...prevState,
          dateFrom: fromDate,
          dateTo: toDate,
          typeSortingDate: typeSortingDate,
        };
      });
    }
  }, [fromDate, toDate, typeSortingDate]);

  React.useEffect(() => {
    const _filter = JSON.parse(previousFilter);
    adminService
      .searchSurveys(_filter)
      .then((result) => {
        setSurveys(result.items);
        setPagination({
          currentPage: result.pageIndex,
          totalItems: result.totalItems,
          pageSize: result.pageSize,
        });
      })
      .then(() => {
        setResendSurveys([]);
        setIsSelectAllSurveys(false);
      })
      .catch((err: APIError) => {
        showError(err.status, strings.unknown_error);
      });
  }, [previousFilter, isCreatedSurvey, showError]);

  const pageChanged = (page: number) =>
    setFilter((prevState) => {
      return { ...prevState, currentPage: page };
    });

  function handleChange(event: any) {
    debounceDropDown(event.target.value);
  }

  const debounceDropDown = React.useCallback(
    debounce((nextValue: string) => fetchDropdownOptions(nextValue), 500),
    [filter]
  );

  const fetchDropdownOptions = (val: string) => {
    setFilter((prevState) => {
      return { ...prevState, search: val, currentPage: 1 };
    });
  };

  const getEmptyTableMessage = () => {
    if (filter.search.length !== 0)
      return strings.dashboard_assessment_table_not_found_email;
    return strings.dashboard_assessment_table_empty;
  };

  const exportCSV = () => {
    setIsExportFinish(false);
    setIsShowLoadingModal(true);
    adminService.exportSurveys(filter).then((_) => setIsExportFinish(true));
  };

  function getOrderDirection(
    newOrderingName: SurveyOrderByColumn,
    currentOrderingName: SurveyOrderByColumn,
    prevOrderDirection: Ordering
  ) {
    if (newOrderingName === currentOrderingName) {
      return prevOrderDirection === Ordering.Desc
        ? Ordering.Asc
        : Ordering.Desc;
    }

    return newOrderingName === SurveyOrderByColumn.DateSent ||
      newOrderingName === SurveyOrderByColumn.DateSubmitted
      ? Ordering.Desc
      : Ordering.Asc;
  }

  function onClickIconHeader(columnOrdering: SurveyOrderByColumn) {
    setFilter((prevState) => {
      return {
        ...prevState,
        orderBy: columnOrdering,
        orderDirection: getOrderDirection(
          columnOrdering,
          prevState.orderBy,
          prevState.orderDirection
        ),
      };
    });
  }

  function isCheckedResend(slug: string): boolean {
    return resendSurveys.some((resendSurvey) => resendSurvey.slug === slug);
  }

  function resendSurveyOnChange(slug: string, email: string) {
    let newResendSurveys = [...resendSurveys];
    if (!isCheckedResend(slug)) {
      newResendSurveys.push({
        slug: slug,
        email: email,
      } as SurveyResendModel);
    } else {
      newResendSurveys = newResendSurveys.filter(
        (resend) => resend.slug !== slug
      );
    }
    setResendSurveys(newResendSurveys);
  }

  function resendSurveysOnClick() {
    setIsResending(true);
    adminService
      .resendSurveys(resendSurveys)
      .then((_) => {
        setResendSurveys([]);
        setIsShowSuccessModal(true);
      })
      .catch((err) => {
        showError(undefined, err.error);
        setIsShowSuccessModal(false);
      })
      .finally(() => {
        setIsResending(false);
        setIsSelectAllSurveys(false);
      });
  }

  function selectAllResendOnClick() {
    if (isSelectAllSurveys) {
      setResendSurveys([]);
    } else {
      setIsGettingResendingItems(true);
      adminService
        .getResendSurveys(filter)
        .then((data) => {
          setResendSurveys(data);
        })
        .catch((err) => {
          showError(err.status, err.error);
        })
        .finally(() => setIsGettingResendingItems(false));
    }
    setIsSelectAllSurveys(!isSelectAllSurveys);
  }

  return (
    <React.Fragment>
      <Container fluid className="mt-5 px-0 admin-dashboard">
        <div className="d-flex mb-4 table-actions align-items-center ">
          <Form.Group className="d-flex align-items-center me-1">
            <Form.Label className="m-0 me-2">
              {strings.dashboard_assessment_table_search_label}
            </Form.Label>
            <Form.Control
              style={{ minWidth: "160px" }}
              className="placeholder-italic text-muted small-input"
              placeholder={
                strings.dashboard_assessment_table_search_placeholder
              }
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="px-2 me-1 mx-4">
            <OverlayTrigger
              placement="bottom"
              overlay={(props: any) => (
                <Tooltip id="button-tooltip" {...props}>
                  {strings.export_to_csv_tooltip}
                </Tooltip>
              )}
              trigger={["click", "hover"]}
            >
              <Button onClick={exportCSV}>
                {strings.export_to_csv_button}
              </Button>
            </OverlayTrigger>
          </Form.Group>

          <Form.Group className="mx-4">
            <ResendSurveyButton
              disabled={isResending || resendSurveys.length === 0}
              onClick={resendSurveysOnClick}
            >
              <Spinner animation="grow" size="sm" hidden={!isResending} />
              <span>{strings.dashboard_assessment_resend_label_button}</span>
            </ResendSurveyButton>
          </Form.Group>

          <Form.Group className="d-flex align-items-center mx-4">
            <Form.Label className="m-0 me-2 ">
              {strings.dashboard_assessment_table_status_label}
            </Form.Label>
            <Form.Select
              style={{ width: "125px" }}
              className="text-muted small-input text-center"
              value={filter.status}
              onChange={(e: any) => {
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    status: e.target.value as SurveyState,
                    currentPage: 1,
                  };
                });
              }}
            >
              <option value={SurveyState.All}>
                {strings.dashboard_show_all_label}
              </option>
              <option value={SurveyState.NotStarted}>
                {strings.dashboard_not_started_label}
              </option>
              <option value={SurveyState.InProgress}>
                {strings.dashboard_in_progress_label}
              </option>
              <option value={SurveyState.Submitted}>
                {strings.dashboard_completed_label}
              </option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="d-flex align-items-center ms-3">
            <Form.Label className="m-0 me-2 ">
              {strings.dashboard_assessment_table_maturity_label}
            </Form.Label>
            <Form.Select
              style={{ width: "160px" }}
              className="text-muted small-input text-center"
              value={filter.category}
              onChange={(e: any) => {
                setFilter((prevState) => {
                  return {
                    ...prevState,
                    category: e.target.value as SurveyCategory,
                    currentPage: 1,
                  };
                });
              }}
            >
              <option value={SurveyCategory.All}>
                {strings.dashboard_show_all_label}
              </option>
              <option value={SurveyCategory.Thoughtful}>
                {strings.dashboard_maturity_thoughtful_label}
              </option>
              <option value={SurveyCategory.Purposeful}>
                {strings.dashboard_maturity_purposeful_label}
              </option>
              <option value={SurveyCategory.Strategic}>
                {strings.dashboard_maturity_strategic_label}
              </option>
              <option value={SurveyCategory.Transformational}>
                {strings.dashboard_maturity_transformational_label}
              </option>
            </Form.Select>
          </Form.Group>
        </div>
        <CustomTable>
          <thead className="sticky-top">
            <tr>
              {surveyTableHeader.map((header, index) => {
                return (
                  <SurveyTableHeader key={index}>
                    <span>{header.display}</span>

                    {index < 8 && (
                      <OrderingIconWrapper
                        active={
                          header.orderingName === filter.orderBy.toString()
                        }
                        onClick={() =>
                          onClickIconHeader(
                            header.orderingName as SurveyOrderByColumn
                          )
                        }
                      >
                        <TiArrowUnsorted />
                      </OrderingIconWrapper>
                    )}
                  </SurveyTableHeader>
                );
              })}

              <th>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    disabled={surveys.length === 0 || isGettingResendingItems}
                    variant="outline-primary"
                    onClick={() => selectAllResendOnClick()}
                  >
                    <Spinner
                      animation="grow"
                      size="sm"
                      hidden={!isGettingResendingItems}
                    />

                    {isSelectAllSurveys
                      ? strings.dashboard_assessment_unselect_all
                      : strings.dashboard_assessment_select_all}
                  </Button>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {surveys.length > 0 && (
              <React.Fragment>
                {surveys.map((s, i) => (
                  <tr key={i}>
                    <td>{showDateValue(s.dateSent)}</td>
                    <td>{s.firstName}</td>
                    <td>{s.surname}</td>
                    <td>{s.company}</td>
                    <td>{s.email}</td>
                    <td>{surveyToString(s.status)}</td>
                    <td>
                      {!!s.dateSubmitted &&
                        showDateValue(new Date(s.dateSubmitted))}
                    </td>
                    <td>{s.category}</td>
                    <td>
                      <a href={s.surveyLink} target="_blank" rel="noreferrer">
                        {strings.dashboard_assessment_table_link_content}
                      </a>
                    </td>
                    <td>
                      {!!s.surveyResultLink && (
                        <a
                          href={s.surveyResultLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {
                            strings.dashboard_assessment_table_link_result_content
                          }
                        </a>
                      )}
                    </td>

                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={isCheckedResend(s.id)}
                        onChange={(e) => resendSurveyOnChange(s.id, s.email)}
                      />
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            )}
            {surveys.length === 0 && (
              <tr>
                <td colSpan={11} className="text-center py-3">
                  {getEmptyTableMessage()}
                </td>
              </tr>
            )}
          </tbody>
        </CustomTable>

        {pagination && (
          <TablePagination
            totalItems={pagination.totalItems}
            currentPage={pagination.currentPage}
            pageSize={pagination.pageSize}
            pageChanged={pageChanged}
          />
        )}

        {pagination && pagination.totalItems > 0 && (
          <Container
            fluid
            className="d-flex align-items-center justify-items-center"
          >
            <ShowEntriesWrapper>
              <Form.Label className="m-0">
                {strings.dashboard_assessment_table_show}
              </Form.Label>
              <Form.Select
                className="mx-2 none-boreder-radius text-muted small-input"
                value={filter.itemsPerPage}
                onChange={(e: any) => {
                  setFilter((prevState) => {
                    return {
                      ...prevState,
                      itemsPerPage: +e.target.value,
                      currentPage: 1,
                    };
                  });
                }}
              >
                {pages.map((p) => (
                  <option value={p} key={p}>
                    {p}
                  </option>
                ))}
              </Form.Select>
              <Form.Label className="m-0 ">
                {strings.dashboard_assessment_table_entries}
              </Form.Label>
            </ShowEntriesWrapper>

            <div className="ms-auto">
              <span>
                {`Showing ${
                  (pagination.currentPage - 1) * pagination.pageSize + 1
                } to ${Math.min(
                  pagination.currentPage * pagination.pageSize,
                  pagination.totalItems
                )} of ${pagination.totalItems} entries`}
              </span>
            </div>
          </Container>
        )}
      </Container>
      <Modal
        onHide={() => setIsShowLoadingModal(false)}
        show={isShowLoadingModal}
        centered
      >
        <Modal.Header closeButton className="modal-none-border"></Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center pt-0 pb-4">
          {isExportFinish ? (
            <MdOutlineCheck
              fontSize={100}
              className="icon-success icon-export-success my-2"
            />
          ) : (
            <Spinner
              animation="border"
              className="icon-export icon-export-loading my-2"
            />
          )}

          <strong>
            {isExportFinish
              ? strings.export_to_csv_completed
              : strings.export_to_csv_loading}
          </strong>
        </Modal.Body>
      </Modal>

      <Modal
        onHide={() => setIsShowSuccessModal(false)}
        show={isShowSuccessModal}
        centered
        className="create-survey-link-modal"
      >
        <Modal.Header closeButton className="modal-none-border"></Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center py-0">
          <MdOutlineCheck fontSize={100} className="icon-success mb-4" />
          <Modal.Title className="fs-2 font-weight-bold mb-2 text-muted">
            <strong>{strings.dashboard_resend_modal_title}</strong>
          </Modal.Title>
        </Modal.Body>
        <Modal.Footer className="modal-none-border d-flex justify-content-center align-items-center">
          <Button
            className="btn-color-purple px-3"
            onClick={() => setIsShowSuccessModal(false)}
          >
            <strong>{strings.dashboard_created_modal_OK}</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default SurveyTable;
