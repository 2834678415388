type Props = {
  category: string | undefined;
};
function ReportOverallResultLogo({ category }: Props) {
  return (
    <div className="my-4">
      <div className="overall-result-report  d-flex align-items-center justify-content-center">
        {["Thoughtful", "Purposeful", "Strategic", "Transformational"].map(
          (cat) => (
            <img
              data-testid={`img-${cat.toLowerCase()}`}
              src={`/images/${cat}.png`}
              alt="overall-results-report "
              key={cat}
              className={
                category === cat
                  ? "overall-result-logo-report  highlighted-result-report"
                  : "overall-result-logo-report  overlay-tint-report "
              }
            />
          )
        )}
      </div>
    </div>
  );
}

export default ReportOverallResultLogo;
