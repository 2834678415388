import React from "react";
import { strings } from "../../../../localization/en";
import { maturityLevelTexts } from "../../../admin/constants/maturity-level-texts";
import { ScoreCategory } from "../../../models/survey-score";
import OverallResultLogo from "./OverallResultLogo";

type props = {
  company: string | undefined;
  category: ScoreCategory | undefined;
};

export default function SurveyScoreHeader({ company, category }: props) {
  return (
    <React.Fragment>
      <h2 data-testid="survey-header" className="m-0 text-center text-blue">
        {strings.assessment_result_header}
        {company}
      </h2>
      <OverallResultLogo category={category} />
      <p className="text-start fw-bold fst-italic">
        {strings.assessment_result_intro}
      </p>
      <p className="text-start mb-4">{strings.assessment_result_content}</p>
      <div className="overall-maturity-border p-4">
        <p className="mb-3 fw-bold fst-italic ">
          {strings.overall_maturity_label} {category}
        </p>
        <p className="m-0">
          {category !== undefined
            ? maturityLevelTexts[
                category as string as keyof typeof maturityLevelTexts
              ]
            : ""}
        </p>
      </div>
    </React.Fragment>
  );
}
