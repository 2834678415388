import "./Header.css";
import Logo1 from "../../../cc_logo.png";
import Logo2 from "../../../Logo2.png";
function Header() {
  return (
    <nav className="header">
      <a href="https://corporate-citizenship.com">
        <img className="left-logo" src={Logo1} />
      </a>
      <a href="https://b4si.net">
        <img className="right-logo" src={Logo2} />
      </a>
    </nav>
  );
}

export default Header;
