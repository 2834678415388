import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { SurveyState, SurveyVerificationStatus } from "../../models/survey";
import { surveyService } from "../../services/survey.service";
import ClientHeader from "../components/ClientHeader";
import SurveyContactVerification from "./SurveyContactVerification";
import SurveyNotFound from "../components/SurveyNotFound";
import { strings } from "../../../localization/en";
import { Alert, Container, ListGroup } from "react-bootstrap";
import styles from "./SurveyGeneralInfo.module.css";
export interface GeneralInfoProps {
  action: () => void;
  surveyId: string;
}

function SurveyGeneralInfo(props: GeneralInfoProps) {
  const { action, surveyId } = props;
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState<SurveyState>(
    SurveyState.Unknown
  );
  const [isVerifying, setIsVerifying] = useState(true);
  const verifySuccess = (status: SurveyState) => {
    setVerified(true);
    setSurveyStatus(status);
  };

  const actionTitle =
    surveyStatus === SurveyState.NotStarted
      ? strings.start_assessment
      : surveyStatus === SurveyState.InProgress
      ? strings.continue_assessment
      : "";

  useEffect(() => {
    surveyService
      .getSurveyVerificationStatus(surveyId)
      .then((survey: SurveyVerificationStatus) => {
        setVerified(survey.verified);
        setSurveyStatus(survey.status);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  return (
    <div className="survey-page">
      <ClientHeader />
      {isVerifying && !error ? (
        <SurveyContactVerification
          id={surveyId}
          status={surveyStatus}
          onSuceess={verifySuccess}
          verified={verified}
          setIsVerifying={setIsVerifying}
        />
      ) : (
        <Container
          className="mt-5 "
          style={{
            maxWidth: "900px",
          }}
        >
          <ListGroup className="rounded-0 ">
            {error && <SurveyNotFound />}
            <ListGroup.Item className="text-center py-3 border-dark">
              <h5 className={styles.title}>
                {strings.site_intro_header_line1}
                <br />
                {strings.site_intro_header_line2}
              </h5>
            </ListGroup.Item>
            <ListGroup.Item className={styles.note + " border-dark py-3"}>
              {strings.assessment_intro_note}
            </ListGroup.Item>
            <ListGroup.Item className="border-dark py-3">
              <p className="mb-3 lh-sm">
                Designed to support the assessment, development, and management
                of a company's social impact strategy,{" "}
                <strong>The Social Impact Compass</strong> is a tool based
                across four pillars:{" "}
                <strong>Plan, Implement, Measure, Report &amp; Engage</strong>.
              </p>
              <p className="mb-3">The tool is arranged in three parts:</p>

              <ol className="ms-4">
                <li className="ps-2 mb-2 fs-6 lh-sm">
                  <strong>Assessment:</strong> This self-assessment element
                  contains a set of questions within the four pillars, organised
                  in 14 sections. Delivered online, it allows practitioners to
                  complete the questions, shows completion progress and if
                  needed can be shared with internal stakeholders for input.
                </li>
                <li className="ps-2 mb-2 fs-6 lh-sm">
                  <strong>Results:</strong> The results page shows a summary of
                  the scores obtained in each section and the overall maturity
                  level attained for the company. Giving an indication of where
                  the company is on its social impact journey, it is designed to
                  be used in conjunction with the B4SI Social Impact Compass
                  Guidance Manual.
                </li>
                <li className="ps-2 fs-6 lh-sm">
                  <strong>B4SI Social Impact Compass Guidance Manual:</strong>{" "}
                  This document provides actionable guidance to implement
                  actions that improve a company's score and forms the basis for
                  further conversations with B4SI Account Managers.
                </li>
              </ol>
            </ListGroup.Item>
            <ListGroup.Item className="border-dark py-3">
              <p className="mb-3">
                <strong>Navigating the Assessment:</strong>
              </p>
              <ul className="ms-4">
                <li className="ps-2 mb-2 fs-6 lh-sm">
                  The online assessment is divided into four pillars: Plan,
                  Implement, Measure, Report &amp; Engage. Questions can be
                  accessed by clicking on the top heading which shows overall
                  progress through the assessment. There is also an option to
                  export the assessment to share with internal stakeholders.
                </li>
                <li className="ps-2 mb-2 fs-6 lh-sm">
                  A priority for action & commentary box is provided beside each
                  question to allow self-assessment of priorities along with a
                  commentary. These fields aide in prioritising next steps but
                  don't affect final assessment results.
                </li>
                <li className="ps-2 mb-2 fs-6 lh-sm">
                  Some questions in the assessment may not be applicable to all
                  businesses. In such cases, there is the option to select N/A
                  where relevant.
                </li>
                <li className="ps-2 mb-2 fs-6 lh-sm">
                  Guidance related to specific questions can be found by
                  hovering over the icon next to each question.
                </li>
                <li className="ps-2 mb-2 fs-6 lh-sm">
                  The assessment can be saved and continued at any point before
                  submission. Once submitted, the assessment is final, and
                  answers can't be amended.
                </li>
              </ul>
            </ListGroup.Item>
            <ListGroup.Item className="border-dark py-3 text-center">
              For any support contact your account manager directly or the B4SI
              Team at{" "}
              <a href="mailto:b4si@corporate-citizenship.com">
                b4si@corporate-citizenship.com
              </a>
            </ListGroup.Item>
          </ListGroup>
          <div className="start_page mt-5 mb-5">
            {verified && surveyStatus !== SurveyState.Submitted && (
              <button className="btn btn-primary" onClick={action}>
                {actionTitle.toUpperCase()}
              </button>
            )}
            {surveyStatus === SurveyState.Submitted && (
              <Alert variant="success text-center">
                <span
                  dangerouslySetInnerHTML={{
                    __html: strings.assessment_msg_view_result,
                  }}
                ></span>
              </Alert>
            )}
          </div>
        </Container>
      )}
    </div>
  );
}

export default SurveyGeneralInfo;
