import { useState } from "react";
import { Button, Form, Spinner, Modal, FormControl } from "react-bootstrap";
import { MdOutlineCheck } from "react-icons/md";
import { Controller, useForm } from "react-hook-form";
import { useNotification } from "../../../../components/NotificationProvider";
import adminService from "../../services/admin.service";
import { APIError } from "../../../models/error/api-error";
import { strings } from "../../../../localization/en";
import { emailRegex } from "../../../helpers/regex-pattern";

type SurveyGeneratorProps = {
  setCreatedSurvey: () => void;
};

function SurveyGenerator(props: SurveyGeneratorProps) {
  const [generatingSurvey, setGeneratingSurvey] = useState(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState({
    email: "",
    surveyLink: "",
  });
  const [contentButtonCopy, setContentButtonCopy] = useState(
    strings.dashboard_created_modal_copy_button
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "all",
  });
  const { showError } = useNotification();
  const onSubmit = handleSubmit(async (data) => {
    setGeneratingSurvey(true);
    adminService
      .generateSurvey(data.email)
      .then((si) => {
        setSurveyInfo({
          email: si?.email,
          surveyLink: si?.surveyLink,
        });
        props.setCreatedSurvey();
        setIsShowSuccessModal(true);
        setGeneratingSurvey(false);
        setContentButtonCopy(strings.dashboard_created_modal_copy_button);
      })
      .catch((error: APIError) => {
        setGeneratingSurvey(false);
        setIsShowSuccessModal(false);
        setSurveyInfo({
          email: "",
          surveyLink: "",
        });
        showError(error.status, strings.server_error);
      });
  });

  function copyToClipboard(surveyLink: string) {
    if (window.location.protocol === "https:") {
      navigator.clipboard.writeText(surveyLink);
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported("copy")
    ) {
      let textarea = document.createElement("textarea");
      textarea.textContent = surveyLink;
      textarea.style.position = "fixed";
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy");
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return prompt("Copy to clipboard: Ctrl+C, Enter", surveyLink);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }
  return (
    <>
      <Form>
        <Form.Group
          className="d-flex position-relative"
          controlId="formBasicEmail"
        >
          <Controller
            name="email"
            control={control}
            render={({ field }) => {
              return (
                <Form.Control
                  {...field}
                  type="email"
                  className="w-50 me-3 small-input"
                  placeholder={strings.dashboard_create_assessment_placeholder}
                />
              );
            }}
            rules={{
              required: strings.dashboard_create_assessment_error_empty,
              pattern: {
                value: emailRegex,
                message: strings.dashboard_create_assessment_error_format,
              },
            }}
          />

          <Button
            onClick={onSubmit}
            disabled={!!errors.email || !isDirty || generatingSurvey}
            variant={errors.email || !isDirty ? "secondary" : "primary"}
            type="submit"
          >
            {generatingSurvey && (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {strings.dashboard_create_assessment_send_button}
          </Button>
        </Form.Group>
      </Form>

      <Modal
        onHide={() => setIsShowSuccessModal(false)}
        show={isShowSuccessModal}
        centered
        className="create-survey-link-modal"
      >
        <Modal.Header closeButton className="modal-none-border"></Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center py-0">
          <MdOutlineCheck fontSize={100} className="icon-success mb-4" />
          <Modal.Title className="fs-2 font-weight-bold mb-2 text-muted">
            <strong>{strings.dashboard_created_modal_title}</strong>
          </Modal.Title>
          <p className="py-2 my-0 text-muted fs-6">
            {strings.dashboard_created_modal_content + surveyInfo?.email}
          </p>
          <div className="text-muted d-flex w-100">
            <FormControl disabled value={surveyInfo?.surveyLink}></FormControl>
            <Button
              variant="secondary"
              className="mx-2"
              onClick={() => {
                copyToClipboard(surveyInfo?.surveyLink);
                setContentButtonCopy(
                  strings.dashboard_created_modal_copied_button
                );
              }}
            >
              {contentButtonCopy}
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-none-border d-flex justify-content-center align-items-center">
          <Button
            className="btn-color-purple px-3"
            onClick={() => setIsShowSuccessModal(false)}
          >
            <strong>{strings.dashboard_created_modal_OK}</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SurveyGenerator;
