import "./CaseStudy.css";
import styles from "./CaseStudy.module.scss";
function Purposeful() {
  return (
    <div>
      <p className="normal page-header">
        <b>
          <span>Your Social Impact Maturity Level - Case Studies</span>
        </b>
      </p>
      <h4 className="normal text-center">
        <span data-testid="case-header" className="page-title">
          Purposeful
        </span>
      </h4>
      <p className="normal page-description">
        The second maturity level in the B4SI Social Impact Compass is{" "}
        <strong>Purposeful</strong>. Companies at this level will start to look
        very different to each other, as they evolve in their strategies and
        define specific plans and targets. Compared to the{" "}
        <strong>Thoughtful</strong> level, <strong>Purposeful</strong> companies
        may have made an effort to identify key stakeholders, define KPIs and
        outline some key themes and programmes. However, social impact is
        aligned to community investment activities, with a lack of programme
        involvement and connection to other business units.
      </p>
      <p className="normal page-description">
        A company in this level may reflect the key aspects of the Illustrative
        example below.
      </p>
      <h6 className={`normal company-header ${styles.purposeful}`}>
        <strong>Illustrative example: EnerCo</strong>
      </h6>
      <p className="normal">
        <strong>Sector:</strong> Utilities and Services
      </p>
      <p className="normal">
        <strong>Overview:</strong> EnerCo is a{" "}
        <strong>multinational energy group</strong> with headquarters in Israel
        and operations in the Middle East, North Africa, and Southern Europe.
        Its companies lead in solar and wind technology deployment and are
        publicly listed in NYSE and TASE (Tel Aviv).
      </p>
      <p className="normal">
        <strong>Plan:</strong> The group of companies each have their own social
        impact strategy, with diverse initiatives depending on the regional
        context, leveraging their extensive local footprint. A recent
        materiality assessment has created the opportunity to build a
        companywide social impact strategy and the global sustainability team
        has doubled in the past two years. Some broad key themes have been
        defined stemming from the group's organisational purpose and these will
        be translated across local operations and monitored.
      </p>
      <p className="normal">
        <strong>Implement:</strong> Stakeholder engagement is light. Each
        company approaches the local public and community sector with a set of
        already defined areas of intervention and uses these engagements to find
        suitable investments. The group has a set of long-term partnerships with
        large charities and the core community team members have agreed KPIs
        with their respective partner organisations. These are written into
        partnership agreements with some key impact data requirements also.
      </p>
      <p className="normal">
        The group has a strong volunteering programme with activities such as
        fundraising, financial support to community organisations, pro bono
        energy consulting and digital skills training to local disadvantaged
        communities. Employee engagement is monitored through surveys, but
        volunteering is not connected to HR development internally.
      </p>

      <p className="normal">
        <strong>Measure:</strong> The company has agreed internal processes for
        data capture relating to inputs and outputs and they are currently
        reporting impact data for one of their major partnership activities in
        Israel. The company has a strong working relationship with the charity
        partner, and they have taken the time to agree a mutually workable
        approach to impact measurement for the flagship program, enabling Enerco
        to tell a compelling story.
      </p>
      <p className="normal">
        <strong>Report and Engage:</strong> Reporting is focused mainly on each
        company's flagship programme and corresponding partnership, trying to
        sustain a narrative in line with the global social impact strategy. This
        is mostly externally focused and leveraged for engagement with customers
        and investors.
      </p>
      <p className="normal">
        <strong>Next steps:</strong> To move on to a <strong>Strategic</strong>{" "}
        level, EnerCo might start with some of the following actions:
      </p>
      <ul className="dashed">
        <li className="dashed">
          Review existing partnerships and identify impact measurement gaps
          through the Project Assessment Sheet on the{" "}
          <strong className="cs-text-blue">B4SI Impact Toolkit</strong>
        </li>
        <li className="dashed">
          <strong>Assess existing</strong>{" "}
          <strong className="cs-text-blue">employee engagement</strong>{" "}
          opportunities or develop an employee engagement plan linked to social
          impact priorities. This should focus on:
          <ul>
            <li className="second-indent">
              identifying how social impact priorities are communicated to
              employees,
            </li>
            <li className="second-indent">
              the formal processes to support employee involvement,
            </li>
            <li className="second-indent">
              and the incentive structures for employees to engage in the
              achievement of social impact priorities.
            </li>
          </ul>
        </li>
        <li className="dashed">
          Conduct a <strong className="cs-text-blue">landscape analysis</strong>{" "}
          to refresh understanding of key social issues with a focus on
          enhancing understanding of those issues, key drivers, needs of target
          groups, and approaches of leading companies and peers.
        </li>
        <li className="dashed">
          Validate its data independently through{" "}
          <strong className="cs-text-blue">B4SI Assurance</strong> to increase
          the quality of reports and alignment to B4SI Frameworks.
        </li>
      </ul>

      <div style={{ pageBreakAfter: "always" }}></div>

      <h6 className={`normal company-header ${styles.purposeful}`}>
        <strong>Illustrative example: Quinn Foundation</strong>
      </h6>
      <p className="normal">
        <strong>Sector:</strong> Financial Services - Corporate Foundation
      </p>
      <p className="normal">
        <strong>Overview:</strong> Quinn plc is a multinational insurance
        company with headquarters in Buenos Aires, Argentina. They operate in 20
        countries and 12 years ago they launched the Quinn Foundation to
        ringfence all charitable funding across the business and provide
        dedicated resources to its allocation. Quinn plc have remained the sole
        source of funding for the Foundation throughout the last 12 years.
      </p>
      <p className="normal">
        <strong>Plan:</strong> The Quinn Foundation leverage their local
        footprint by focusing their charitable commitments with 89 community
        partners in 36 locations where Quinn plc has offices. Having previously
        conducted a strategy assessment they have defined a global vision and
        objectives which are aligned to their parent business strategy; focusing
        on helping societies develop the capacity to mitigate environmental
        risks, as well as to recover quickly from setbacks caused by climate
        change.
      </p>
      <p className="normal">
        <strong>Implement:</strong> Their charitable commitments with community
        partners are short term and consist of only in-kind donations and
        financial grants. There is no proactive process for partner selection,
        instead the Foundation fields requests for partnership. About 60% of
        community partners have been approved based on the overarching purpose
        of the Foundation, while the remaining are approved by a board
        consisting wholly of internal trustees. Expertise from the Corporate
        provides a huge strength to the Foundation, this includes the
        Corporate's technical guidance and expertise, particularly in advising
        on the improvement of management efficiencies and locating any
        liabilities to brand.
      </p>
      <p className="normal">
        <strong>Measure:</strong> Most community partners meet with the
        Foundation on an ad hoc basis throughout the year to share developments
        and high-level data, including inputs and outputs. The Foundation
        captures data such as total financial contributions, financial
        contributions by objective, organizations and individual beneficiaries
        reached which are then used as targets. Objectives are aligned with
        SDGs, but it is unclear how partner goals and objectives are related to
        the Foundation goals and impacts beyond financial contributions. Some of
        their partners are proactively capturing data on the types of impact but
        the Foundation fails to collate these into an aggregated measure. They
        largely measure impact and success via output measures.
      </p>
      <p className="normal">
        <strong>Report and Engage:</strong> There are no externally published
        reports available, but internally all high-level data is tracked and
        influences the partnership longevity and/or funding. All data captured
        is in quantitative format which fails to account for the nuances in each
        of their community partner's initiatives. Throughout their internal
        reports, a top line strategy has been communicated to the Board of
        trustees and a high-level overview of the strategy can be found on their
        webpage.
      </p>
      <p className="normal">
        <strong>Next steps:</strong> To move on to a Strategic level, Quinn
        Foundation might start with some of the following actions:
      </p>
      <ul className="dashed">
        <li className="dashed">
          Conduct{" "}
          <strong className="cs-text-blue">
            a review of current activities
          </strong>{" "}
          <strong>and commitments.</strong> This will include a thorough mapping
          exercise of current company/Foundation activities, governance,
          operational framework, and ambitions to identify strengths, common
          threads, and areas to build on. The results of this review will
          identify alignment areas for existing partnerships, key gaps in the
          partnership selection process and governing body (i.e., Board of
          Trustees).
        </li>
        <li className="dashed">
          <strong className="cs-text-blue">
            B4SI For Effective Partnerships
          </strong>{" "}
          is then useful to introduce the impact measurement framework with its
          partners and review/align the strategy & impact data collection
          methods in collaboration with them. This could eventually form the
          foundation of a partnership vetting process.
        </li>
        <li className="dashed">
          A <strong className="cs-text-blue">Benchmark analysis</strong> can be
          conducted to better understand the organizational structure and
          partnership selection process of peers and other Corporate
          Foundation's in the Financial sector. This will help to inform Quinn's
          own partnership selection process.
        </li>
        <li className="dashed">
          Conduct a{" "}
          <strong className="cs-text-blue">landscape/audience analysis</strong>{" "}
          to better understand the key stakeholders and the needs of target
          groups, and approaches of leading foundations and peers.
        </li>
        <li className="dashed">
          Validate its data independently through{" "}
          <strong className="cs-text-blue">B4SI Assurance</strong> to increase
          the quality of reports and alignment to B4SI Frameworks and peers
          within the network.
        </li>
      </ul>
    </div>
  );
}

export default Purposeful;
