import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";

type AppProps = {
  options: any[];
  name: string;
  control: any;
  rules: any;
  label: string;
  small?: boolean;
  placeholder: string;
};

function DropdownWithLabel({
  options,
  name,
  control,
  rules,
  label,
  small = false,
  placeholder,
}: AppProps) {
  return (
    <Form.Group as={Row} className="mb-3 mx-0">
      <Form.Label className="bg-label py-3 fw-bold" column sm="2">
        {label}
      </Form.Label>
      <Col sm="10" className="position-relative px-0">
        <Controller
          name={name}
          control={control}
          render={({ field, formState }) => {
            const { errors } = formState;
            return (
              <>
                <Form.Select
                  {...field}
                  isInvalid={!!errors[name]}
                  className={small ? "h-100 rounded-0 w-25" : "h-100 rounded-0"}
                >
                  <option defaultChecked style={{ display: "none" }}>
                    {placeholder}
                  </option>
                  {options.map((option, i) => (
                    <option key={i} value={option.value}>
                      {option.display}
                    </option>
                  ))}
                </Form.Select>
              </>
            );
          }}
          rules={rules}
        />
      </Col>
    </Form.Group>
  );
}

export default DropdownWithLabel;
