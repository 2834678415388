import {
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  PointElement,
  RadialLinearScale,
  Tooltip,
} from "chart.js";
import { Radar } from "react-chartjs-2";
import { extractName } from "../../../helpers/extract-name";
import { fillSpiderGraphBackground } from "../../../helpers/fill-spidergraph-background";
import { spiderGraphBackgroundGenerator } from "../../../helpers/spider-graph-background-generator";
import { SurveyScore } from "../../../models/survey-score";
import ChartData from "../../../models/survey-score/chart-data";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

type props = {
  chartData: ChartData | undefined;
  surveyScore: SurveyScore | undefined;
};

export default function SurveyScoreChart({ chartData, surveyScore }: props) {
  return (
    <div className="d-flex justify-content-center">
      <div className="spider-graph-wrapper">
        <Radar
          data={chartData!}
          plugins={[
            {
              beforeDraw: (chart) => {
                const { ctx, scales } = chart;
                const {
                  xCenter,
                  yCenter,
                  drawingArea: radius,
                } = scales.r as any;
                fillSpiderGraphBackground(xCenter, yCenter, radius, ctx);
              },
              id: "beforeDatasetDraw",
            },
          ]}
          options={{
            plugins: {
              datalabels: {
                formatter: (value, context) =>
                  context.datasetIndex == 0 ? value : "",
                color: "rgb(87,87,86)",
                align: "end",
                anchor: "start",
                font: {
                  weight: "bold",
                },
                display: false,
              },
              legend: {
                display: false,
              },
              tooltip: {
                filter: (tooltipItem) => {
                  return tooltipItem.datasetIndex === 0;
                },
              },
            },
            scales: {
              r: {
                pointLabels: {
                  padding: 25,
                } as any,
                ticks: {
                  display: false,
                  stepSize: 20,
                },
                grid: {
                  color: "#FFFFFF",
                },
                angleLines: {
                  display: false,
                },
                min: 0,
                max: 100,
              },
            },
          }}
        />
        <div className="custom-legend">
          {surveyScore?.sections.map((section) => (
            <div
              key={section.section}
              style={{
                backgroundColor: spiderGraphBackgroundGenerator(
                  extractName(section.section)
                ),
              }}
            >
              {extractName(section.section)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
