import { useEffect, useState } from "react";
import { Alert, Col, Container, Form, Row } from "react-bootstrap";
import SurveyGenerator from "../components/dashboard/SurveyGenerator";
import SurveyTable from "../components/dashboard/SurveyTable";
import PieChart from "../components/dashboard/PieChart";
import Status from "../components/dashboard/Status";
import adminService from "../services/admin.service";
import { StatisticData } from "../models";
import "./AdminDashboard.css";
import LogoWrapper from "../../client/components/LogoWrapper";
import { clientLink } from "../../constants/link-client-site";
import { linkLogo } from "../../constants/link-logo";
import { strings } from "../../../localization/en";
import SurveyInsightFilter, {
  TypeSortingDate,
} from "../models/survey-insight-filter";
import { toYYYYMMDD } from "../../helpers/function-extensions";

function AdminDashboard() {
  const [isInvalidDate, setIsInvalidDate] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<StatisticData[]>([]);
  const [createdSurvey, setCreatedSurvey] = useState<boolean>(false);

  const [filter, setFilter] = useState<SurveyInsightFilter>({
    fromDate: undefined,
    toDate: undefined,
    typeSortingDate: TypeSortingDate.DateSent,
  });

  useEffect(() => {
    adminService
      .getStatisticsData(filter)
      .then((s) => {
        setStatistics(s);
      })
      .catch((_error) => {
        console.log("Error: Cannot get statistics data");
      });
  }, [filter, createdSurvey]);

  function dateHandler(from: Date | undefined, to: Date | undefined) {
    setIsInvalidDate(!!from && !!to && from > to);
  }

  function fromDateChange(e: any) {
    const newDate = e.target.value ? new Date(e.target.value) : undefined;
    dateHandler(newDate, filter.toDate);
    setFilter((prevState) => {
      return { ...prevState, fromDate: newDate };
    });
  }

  function toDateChange(e: any) {
    const newDate = e.target.value ? new Date(e.target.value) : undefined;
    dateHandler(filter.fromDate, newDate);
    setFilter((prevState) => {
      return { ...prevState, toDate: newDate };
    });
  }

  return (
    <Container className="admin-dashboard" fluid>
      <Container fluid>
        <h2 data-testid="title" className="mt-2 mb-1">
          {strings.dashboard_title}
        </h2>
        <p className="text-muted">{strings.dashboard_title_description}</p>
        <LogoWrapper link={clientLink.b4si}>
          <img
            className="logo-top-right"
            width="180"
            height="100"
            src={linkLogo.logo_2}
            alt="sub-logo"
          />
        </LogoWrapper>

        <h5 className="mt-4 mb-1">
          {strings.dashboard_create_assessment_title}
        </h5>

        <SurveyGenerator
          setCreatedSurvey={() => setCreatedSurvey(!createdSurvey)}
        />
        <Row className="mt-3 mx-0 w-100 border py-3 bg-white insight-section ">
          <Row>
            <Col
              xs={4}
              className="d-flex px-4 mx-0 justify-content-center align-items-center flex-column"
            >
              <div className="me-auto">
                <h4 className="fs-3 insight-title">
                  {strings.dashboard_insight_title}
                </h4>
              </div>

              <Form.Group className="d-flex align-items-center w-100 me-auto">
                <Form.Label as={Col} md={3} className="m-0 me-2">
                  {strings.dashboard_insight_search_date_label}
                </Form.Label>
                <Form.Select
                  style={{ width: "170px" }}
                  as={Col}
                  md={9}
                  className="text-muted small-input text-center"
                  value={filter.typeSortingDate}
                  onChange={(e: any) => {
                    setFilter((prevState) => {
                      return {
                        ...prevState,
                        typeSortingDate: e.target.value as TypeSortingDate,
                      };
                    });
                  }}
                >
                  <option value={TypeSortingDate.DateSent}>
                    {strings.dashboard_insight_search_date_sent}
                  </option>
                  <option value={TypeSortingDate.DateSubmitted}>
                    {strings.dashboard_insight_search_date_submitted}
                  </option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="d-flex justify-content-center my-4 me-auto">
                <div>
                  <label>{strings.dashboard_insight_from_date_label}</label>
                  <Form.Control
                    placeholder={
                      strings.dashboard_insight_from_date_placeholder
                    }
                    type="date"
                    className={`text-center ${
                      isInvalidDate ? "text-red" : "text-muted"
                    } `}
                    value={toYYYYMMDD(filter.fromDate)}
                    onChange={fromDateChange}
                    max={toYYYYMMDD(filter.toDate)}
                    isInvalid={isInvalidDate}
                  />
                </div>

                <div className="ms-4">
                  <label>{strings.dashboard_insight_to_date_label}</label>
                  <Form.Control
                    placeholder={strings.dashboard_insight_to_date_placeholder}
                    type="date"
                    className={`text-center ${
                      isInvalidDate ? "text-red" : "text-muted"
                    }`}
                    value={toYYYYMMDD(filter.toDate)}
                    onChange={toDateChange}
                    min={toYYYYMMDD(filter.fromDate)}
                    isInvalid={isInvalidDate}
                  />
                </div>
              </Form.Group>
            </Col>

            <Col xs={4}>
              <Status statistics={statistics} />
            </Col>
            <Col xs={4} className="d-flex justify-content-center">
              <PieChart statistics={statistics} />
            </Col>
          </Row>

          {isInvalidDate && (
            <Row>
              <Alert
                variant="danger"
                className="mx-3 my-0 text-center text-red"
              >
                {strings.dashboard_error_invalid_date}
              </Alert>
            </Row>
          )}
        </Row>
        <SurveyTable
          isCreatedSurvey={createdSurvey}
          typeSortingDate={filter.typeSortingDate}
          fromDate={toYYYYMMDD(filter.fromDate)}
          toDate={toYYYYMMDD(filter.toDate)}
        />
      </Container>
    </Container>
  );
}

export default AdminDashboard;
