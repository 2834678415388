import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import DropdownWithLabel from "../components/common/DropdownWithLabel";
import InputWithLabel from "../components/common/InputWithLabel";
import Title from "../components/common/Title";
import { weightingOptions } from "../constants/weighting-options";
import { naOptions } from "../constants/na-options";
import RawScoreInput from "../components/RawScoreInput";
import adminService from "../services/admin.service";
import { sortSubSections, SubsectionViewModel } from "../models";
import { useState, useEffect } from "react";
import { orderOptions } from "../constants/order-options";
import { filterObject } from "../../helpers/filter-object";
import { strings } from "../../../localization/en";
import {
  TypeNotification,
  useNotification,
} from "../../../components/NotificationProvider";
import TextareaWithLabel from "../components/common/TextareaWithLabel";

function EditQuestion() {
  const navigate = useNavigate();
  const location = useLocation();
  const [subSections, setSubSections] = useState<SubsectionViewModel[]>([]);
  const [workingSubsection, setWorkingSubsection] =
    useState<SubsectionViewModel>();
  const [guidance, setGuidance] = useState<string>();
  const { id } = useParams();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      subSectionId: -1,
      question: "",
      weighting: 0.5,
      order: 0,
      naOption: "Yes",
      rawScore1: 1,
      rawScore2: 2,
      rawScore3: 3,
      rawScore4: 4,
      guidance: "",
    },
  });
  const { showError, showInfo } = useNotification();

  const handleEditQuestion = handleSubmit(async (data) => {
    const { weighting, naOption, ...rest } = data;
    adminService
      .updateQuestion(+id!, {
        weighting: weighting,
        naOption: naOption === "Yes",
        ...rest,
        subSectionId: workingSubsection!.id,
      })
      .then((_) => {
        showInfo(
          strings.question_edited_message,
          TypeNotification.success,
          3000
        );
        navigate("/admin/questions", { state: location.state });
      })
      .catch((error) => {
        showError(error.status, strings.server_error);
      });
  });

  //Working subsection changed:
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "subSectionId") {
        const subsection = subSections.find(
          (s) => s.id === +value.subSectionId!
        );
        setWorkingSubsection(subsection);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, subSections]);

  useEffect(() => {
    adminService.getQuestion(+id!).then((question) => {
      reset({ ...question, naOption: question.naOption ? "Yes" : "No" });
      setGuidance(question.guidance);
      adminService.getAllSubSections().then((data) => {
        const subsection = data.find((s) => s.id === +question?.subSectionId);
        setWorkingSubsection(subsection);
        const sortedSubSections = sortSubSections(data);
        setSubSections(sortedSubSections);
      });
    });
  }, [reset, id]);
  const processedErrors = filterObject(
    errors,
    (_, k) => !k.includes("rawScore")
  );
  return (
    <Container fluid>
      <Title content={strings.question_edit_title} />
      <Container fluid className="bg-white p-4">
        {Object.keys(processedErrors).length !== 0 ? (
          <Alert variant="danger">
            <ul className="m-0">
              {Object.keys(processedErrors).map((e) => (
                <li key={e}>{(processedErrors as any)[e].message}</li>
              ))}
            </ul>
          </Alert>
        ) : (
          <></>
        )}
        <Form>
          <DropdownWithLabel
            options={subSections.map((s) => ({ value: s.id, display: s.name }))}
            label={strings.question_label_subsection}
            name="subSectionId"
            control={control}
            rules={{
              required: strings.question_error_subSection,
            }}
            placeholder={strings.question_placeholder_subsection}
          />
          <InputWithLabel
            label={strings.question_label_question}
            name="question"
            control={control}
            placeholder=""
            rules={{
              required: strings.question_error_question,
            }}
          />
          <DropdownWithLabel
            options={orderOptions}
            label={strings.question_label_order}
            name="order"
            control={control}
            rules={{
              required: strings.question_error_order,
            }}
            small={true}
            placeholder={strings.question_placeholder_order}
          />
          <DropdownWithLabel
            options={weightingOptions}
            label={strings.question_label_weighting}
            name="weighting"
            control={control}
            rules={{
              required: strings.question_error_weighting,
            }}
            small={true}
            placeholder={strings.question_placeholder_weighting}
          />
          <DropdownWithLabel
            options={naOptions}
            label={strings.question_label_NA}
            name="naOption"
            control={control}
            rules={{
              required: strings.question_error_naOption,
            }}
            small={true}
            placeholder={strings.question_placeholder_NA}
          />

          <Form.Group as={Row} className="mb-3 ms-0">
            <Form.Label
              className="bg-label py-3 fw-bold d-flex align-items-center"
              column
              sm="2"
            >
              {strings.label_answer_set}
            </Form.Label>
            <Col sm="10" className="d-flex justify-content-evenly">
              <div className="bg-label  py-2 px-3 fw-bold text-center option-wrapper">
                {strings.label_option_1}
                <div className="mt-2 fw-normal fst-italic ">
                  {workingSubsection?.option1}
                </div>
              </div>

              <div className="bg-label py-2 px-3 fw-bold text-center option-wrapper">
                {strings.label_option_2}
                <div className="mt-2 fw-normal fst-italic">
                  {workingSubsection?.option2}
                </div>
              </div>

              <div className="bg-label py-2 px-3 fw-bold text-center option-wrapper">
                {strings.label_option_3}
                <div className="mt-2 fw-normal fst-italic">
                  {workingSubsection?.option3}
                </div>
              </div>

              <div className="bg-label py-2 px-3 fw-bold text-center option-wrapper">
                {strings.label_option_4}
                <div className="mt-2 fw-normal fst-italic ">
                  {workingSubsection?.option4}
                </div>
              </div>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3 ms-0">
            <Form.Label
              className="bg-label py-3 fw-bold d-flex align-items-center"
              column
              sm="2"
            >
              {strings.question_label_raw_score}
            </Form.Label>
            <Col sm="10">
              <div className="h-100 d-flex justify-content-evenly">
                <RawScoreInput
                  name="rawScore1"
                  control={control}
                  trigger={trigger}
                  fallback={1}
                />
                <RawScoreInput
                  name="rawScore2"
                  control={control}
                  trigger={trigger}
                  fallback={2}
                />
                <RawScoreInput
                  name="rawScore3"
                  control={control}
                  trigger={trigger}
                  fallback={3}
                />
                <RawScoreInput
                  name="rawScore4"
                  control={control}
                  trigger={trigger}
                  fallback={4}
                />
              </div>
            </Col>
          </Form.Group>
          <TextareaWithLabel
            label={strings.question_label_guidance}
            value={guidance}
            name="guidance"
            control={control}
            placeholder=""
          />
          <div className="text-center mt-5">
            <Button onClick={handleEditQuestion}>
              {strings.save_changes_button}
            </Button>
          </div>
        </Form>
      </Container>
    </Container>
  );
}

export default EditQuestion;
