import { useEffect, useState } from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import {
  TypeNotification,
  useNotification,
} from "../../../components/NotificationProvider";
import { sectionRegex } from "../../helpers/regex-pattern";
import { strings } from "../../../localization/en";
import InputWithLabel from "../components/common/InputWithLabel";
import Title from "../components/common/Title";
import { SectionViewModel } from "../models";
import adminService from "../services/admin.service";

function EditSection() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [section, setSection] = useState<SectionViewModel | undefined>(
    undefined
  );
  const { showError, showInfo } = useNotification();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      name: section?.name || "",
      description: section?.name || "",
    },
  });

  const handleEditSection = handleSubmit(async (data) => {
    adminService
      .updateSection(id!, data)
      .then((_) => {
        showInfo(
          strings.section_edited_message,
          TypeNotification.success,
          3000
        );
        navigate("/admin/sections");
      })
      .catch((error) => {
        showError(error.status, strings.server_error);
      });
  });

  useEffect(() => {
    adminService.getSection(id!).then((data) => {
      setSection(data);
      reset(data);
    });
  }, [reset, id]);

  return (
    <Container fluid>
      <Title content={strings.section_edit_title} />
      <Container fluid className="bg-white p-4">
        {Object.keys(errors).length !== 0 ? (
          <Alert variant="danger">
            <ul className="m-0">
              {Object.keys(errors).map((e) => (
                <li key={e}>{(errors as any)[e].message}</li>
              ))}
            </ul>
          </Alert>
        ) : (
          <></>
        )}

        <Form>
          <InputWithLabel
            label={strings.section_label_section}
            name="name"
            control={control}
            value={section?.name}
            placeholder={strings.section_placeholder_section}
            rules={{
              required: strings.section_error_section_empty,
              pattern: {
                value: sectionRegex.name,
                message: strings.section_error_section_format,
              },
            }}
          />
          <InputWithLabel
            label={strings.section_label_description}
            name="description"
            value={section?.description}
            control={control}
            placeholder={strings.section_placeholder_description}
            rules={{
              required: strings.section_error_description_empty,
            }}
          />
          <div className="text-center mt-5">
            <Button onClick={handleEditSection}>
              {strings.save_changes_button}
            </Button>
          </div>
        </Form>
      </Container>
    </Container>
  );
}

export default EditSection;
