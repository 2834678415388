import Transformational from "./Transformational";
import Thoughtful from "./Thoughtful";
import Purposeful from "./Purposeful";
import Strategic from "./Strategic";
import { useParams } from "react-router";
import { surveyService } from "../../../services/survey.service";
import { SurveyScore } from "../../../models/survey-score/survey-score";
import { useEffect, useState } from "react";
import "./Common.css";
import Header from "./Header";

function CaseStudy() {
  const { id } = useParams();
  const [category, setCategory] = useState("");
  useEffect(() => {
    id &&
      surveyService
        .getSurveyResult(id)
        .then((surveyScore: SurveyScore | undefined) => {
          if (!surveyScore) {
            throw new Error("Survey score not found");
          }
          setCategory(surveyScore.category);
        });
  }, [id]);
  let caseStudy = null;
  if (category === "Transformational") caseStudy = <Transformational />;
  else if (category === "Thoughtful") caseStudy = <Thoughtful />;
  else if (category === "Purposeful") caseStudy = <Purposeful />;
  else if (category === "Strategic") caseStudy = <Strategic />;
  return (
    <div>
      <Header />
      {caseStudy}
    </div>
  );
}
export default CaseStudy;
