export interface SurveyContactRequest {
  slug: string;
  firstName: string;
  surname: string;
  company: string;
  email: string;
  reason: string;
}

export const MAX_LENGTH_REASON = 500;
