import { useState } from "react";
import {
  Button,
  Modal,
  Nav,
  Form,
  Col,
  Row,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "./ContactUs.css";
import VerificationInput from "./VerificationInput";
import { useParams } from "react-router";
import { useNotification } from "../../../components/NotificationProvider";
import { strings } from "../../../localization/en";
import { emailRegex } from "../../helpers/regex-pattern";
import { surveyContactRequest } from "../../services/contact.service";
import { MAX_LENGTH_REASON } from "../../models/contact/survey-contact-request";
type props = {
  isAdmin: boolean;
};

function ContactUs({ isAdmin }: props) {
  const { id } = useParams();

  const initialValue = {
    firstName: "",
    surname: "",
    company: "",
    email: "",
    reason: "",
  };

  const {
    control,
    formState: { errors, dirtyFields },
    getValues,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: initialValue,
    mode: "all",
  });

  const { showInfo, showError } = useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [numCharacters, setNumCharacters] = useState<number>(MAX_LENGTH_REASON);
  const [isInvalidNumCharacter, setIsInvalidNumCharacter] =
    useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const handleClose = () => {
    setShow(false);
    reset(initialValue);
    setIsInvalidNumCharacter(false);
    setNumCharacters(MAX_LENGTH_REASON);
  };
  const handleShow = () => setShow(true);

  const contactSubmit = async () => {
    setIsLoading(true);
    try {
      if (!!id) {
        await surveyContactRequest({
          slug: id,
          ...getValues(),
        });

        showInfo(strings.request_submitted_successfully, strings.success, 3000);
      } else {
        console.log("Error: Invalid Id");
      }
    } catch {
      console.log("Error: Cannot create a contact request");
      showError(undefined, strings.server_error);
    } finally {
      handleClose();
    }
    setIsLoading(false);
  };

  const isAnyError =
    !dirtyFields.firstName ||
    !dirtyFields.surname ||
    !dirtyFields.company ||
    !dirtyFields.email ||
    !dirtyFields.reason ||
    !!errors?.firstName ||
    !!errors?.surname ||
    !!errors?.company ||
    !!errors?.email ||
    !!errors?.reason;

  return (
    <div className="contact-us" hidden={isAdmin}>
      <Nav.Link
        className="nav-link"
        data-testid="contact-us-link"
        onClick={() => handleShow()}
      >
        {strings.button_contact_us_content}
      </Nav.Link>
      <Modal
        data-testid="contact-modal"
        size="lg"
        show={show}
        onHide={handleClose}
        centered
        className="modal-contact"
      >
        <Modal.Header closeButton className="modal-contact-header">
          <Modal.Title className="mx-auto">
            {strings.contact_title_modal}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(contactSubmit)}>
          <Modal.Body className="mx-5">
            <Form.Group className="mb-3">
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <VerificationInput
                    name="firstName"
                    placeholder={strings.placeholder_first_name}
                    control={control}
                    rules={{
                      required: "First Name cannot be empty.",
                    }}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <VerificationInput
                    name="surname"
                    placeholder={strings.placeholder_sur_name}
                    control={control}
                    type="text"
                    rules={{
                      required: "Surname cannot be empty.",
                    }}
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-3">
                <VerificationInput
                  name="company"
                  placeholder={strings.placeholder_company_name}
                  control={control}
                  rules={{
                    required: "Company cannot be empty.",
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <VerificationInput
                  name="email"
                  placeholder={strings.placeholder_email_address}
                  control={control}
                  type="email"
                  rules={{
                    required: "Email cannot be empty.",
                    pattern: {
                      value: emailRegex,
                      message: "Wrong email format.",
                    },
                  }}
                />
              </Form.Group>

              <Controller
                name="reason"
                control={control}
                render={({ field }) => {
                  return (
                    <>
                      <Form.Control
                        onChange={(e) => {
                          field.onChange(e);
                          setNumCharacters(
                            MAX_LENGTH_REASON - e.target.value.length
                          );
                        }}
                        maxLength={MAX_LENGTH_REASON}
                        data-testid="input-reason"
                        name="reason"
                        as="textarea"
                        rows={7}
                        placeholder={strings.placeholder_question}
                        isInvalid={isInvalidNumCharacter}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="reason"
                        render={({ message }) =>
                          message !== undefined ? (
                            <Alert variant="danger" className="p-2 mt-2">
                              {message}
                            </Alert>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </>
                  );
                }}
                rules={{
                  required: "Reason cannot be empty.",
                }}
              />
            </Form.Group>

            <Row>
              <Form.Label
                className="description-question text-muted"
                as={Col}
                sm={10}
              >
                {strings.description_question.replace(
                  "{{characters}}",
                  MAX_LENGTH_REASON.toString() + " "
                )}
              </Form.Label>
              <Form.Label
                data-testid="label-num-character"
                className={`description-question text-end ${
                  numCharacters === 0 ? "text-danger fw-bold" : "text-muted"
                }`}
                as={Col}
                sm={2}
              >
                {numCharacters}
              </Form.Label>
            </Row>
          </Modal.Body>
          <Modal.Footer className="contact-modal-footer">
            <Button
              data-testid="submit-contact"
              variant={isAnyError ? "secondary" : "primary"}
              className="mx-auto"
              type="submit"
              disabled={isAnyError || isLoading}
            >
              <Spinner
                className="mx-1"
                animation="grow"
                size="sm"
                hidden={!isLoading}
              />
              {strings.button_submit_content}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default ContactUs;
