import { handleResponse } from "../helpers/handle-response";
import { SurveyContactRequest } from "../models/contact/survey-contact-request";
import sessionService from "./session.service";

export function userRequestContact(payload: any) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/contacts/user`,
    requestOptions
  ).then(handleResponse);
}

export async function surveyContactRequest(
  contactRequest: SurveyContactRequest
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(contactRequest),
  };
  return fetch(
    `${process.env.REACT_APP_API_END_POINT}/contacts/survey`,
    requestOptions
  ).then(handleResponse);
}
