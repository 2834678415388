import { Row, Carousel } from "react-bootstrap";
import Progress, { ProgressProps } from "./Progress";
import "./ProgressDetails.css";
interface ProgressDetailsProps {
  data: ProgressProps[];
  onClick: (secitonName: string) => void;
}

function ProgressDetails(props: ProgressDetailsProps) {
  const { data, onClick } = props;

  // split data for carousel
  const numGroups = Math.ceil(data.length / 4);
  const sectionDatas = new Array(numGroups)
    .fill("")
    .map((_, i) => data.slice(i * 4, (i + 1) * 4));

  return (
    <Carousel
      variant="dark"
      className="carousel-progress-bar"
      indicators={sectionDatas.length > 1}
      interval={null}
      prevIcon={sectionDatas.length > 1 ? undefined : null}
      nextIcon={sectionDatas.length > 1 ? undefined : null}
    >
      {sectionDatas.map((d, i) => {
        return (
          <Carousel.Item key={i}>
            <div className="d-flex align-items-stretch justify-content-center flex-wrap">
              {d.map((p, index) => (
                <Progress
                  onClick={onClick}
                  label={p.label}
                  value={p.value}
                  name={p.name}
                  key={index}
                  index={index}
                />
              ))}
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default ProgressDetails;
