import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router";
import { pageTitleGenerator } from "../helpers/page-title-generator";

type Props = {
  children: ReactNode;
};
function PageTitleHandler({ children }: Props) {
  const location = useLocation();
  useEffect(() => {
    document.title = pageTitleGenerator(location.pathname);
  }, [location]);
  return <>{children}</>;
}

export default PageTitleHandler;
