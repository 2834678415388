import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { AiOutlineExclamation } from "react-icons/ai";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import "./RoundedModal.css";

export enum ModalType {
  Success,
  Error,
  Warning,
}

interface AppProp {
  modalType: ModalType;
  showModal: boolean;
  hideAction: () => void;
  handleAction: () => void;
  title: string;
  content: string | React.ReactElement;
  action: string;
}

export default function RoundedModal({
  modalType,
  showModal,
  hideAction,
  handleAction,
  title,
  content,
  action,
}: AppProp) {
  const modalIcon = () => {
    if (modalType === ModalType.Success) {
      return <FaCheck color="rgb(36, 185, 7)" size={64} />;
    } else if (modalType === ModalType.Error) {
      return <BsFillExclamationCircleFill size={64} color="rgb(237, 41, 57)" />;
    } else if (modalType === ModalType.Warning) {
      return <AiOutlineExclamation size={64} color="rgb(255, 243, 0)" />;
    }
  };

  const modalCssColor = () => {
    if (modalType === ModalType.Success) {
      return "rounded-modal-success";
    } else if (modalType === ModalType.Error) {
      return "rounded-modal-error";
    } else if (modalType === ModalType.Warning) {
      return "rounded-modal-warning";
    }
  };

  const modalCssBackgroundColor = () => {
    if (modalType === ModalType.Success) {
      return "rounded-modal-bg-success";
    } else if (modalType === ModalType.Error) {
      return "rounded-modal-bg-error";
    } else if (modalType === ModalType.Warning) {
      return "rounded-modal-bg-warning";
    }
  };

  return (
    <Modal
      className="rounded-modal border-0"
      onHide={hideAction}
      show={showModal}
      centered
    >
      <ModalBody className="rounded-modal-body px-5">
        {modalIcon()}
        <h5 className={modalCssColor()}>{title}</h5>
        <p className="text-center">{content}</p>
      </ModalBody>

      <ModalFooter
        className={`text-center rounded-modal-footer ${modalCssBackgroundColor()}`}
        onClick={handleAction}
      >
        {action}
      </ModalFooter>
    </Modal>
  );
}
