import { clientLink } from "../../constants/link-client-site";
import { linkLogo } from "../../constants/link-logo";
import ContactUs from "./ContactUs";
import LogoWrapper from "./LogoWrapper";

type props = {
  isAdmin?: boolean;
};

function ClientHeader({ isAdmin = false }: props) {
  return (
    <nav className="navbar navbar-expand-md navbar-light bg-info shadow-sm">
      <div className="container-fluid">
        <LogoWrapper
          className="navbar-brand"
          link={clientLink.corporate_citizenship}
        >
          <img src={linkLogo.cc} width="160" height="34px" alt="logo" />
        </LogoWrapper>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active"></li>
            <li className="nav-item"></li>
            <li className="nav-item">
              <ContactUs isAdmin={isAdmin} />
            </li>
          </ul>
          <span className="navbar-text" style={{ marginLeft: "auto" }}>
            <LogoWrapper className="navbar-brand" link={clientLink.b4si}>
              <img
                src={linkLogo.logo_2}
                width="180"
                height="110px"
                alt="logo"
              />
            </LogoWrapper>
          </span>
        </div>
      </div>
    </nav>
  );
}

export default ClientHeader;
