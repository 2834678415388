import { useState } from "react";
import { surveyService } from "../../services/survey.service";
import SectionPage from "../components/SectionPage";
import SurveyProgress from "../components/SurveyProgress";
import { Button, Modal, Spinner, Alert, Container } from "react-bootstrap";
import { Navigate, useNavigate, useParams } from "react-router";
import { AiOutlineExclamation } from "react-icons/ai";
import {
  AnsweredQuestion,
  Section,
  SubSection,
  Survey,
} from "../../models/survey";
import { SectionProvider } from "../components/SectionProvider";
import { SurveyActionProvider } from "../components/SurveyActionProvider";
import {
  TypeNotification,
  useNotification,
} from "../../../components/NotificationProvider";
import ClientHeader from "../components/ClientHeader";
import SurveyGeneralInfo from "./SurveyGeneralInfo";
import { strings } from "../../../localization/en";
import { APIError } from "../../models/error/api-error";
import SubmittedSurveyPage from "./SubmittedSurveyPage";

interface CurrentSection {
  sectionIndex: number;
  section: Section | undefined;
  subSections: SubSection[];
  questions: AnsweredQuestion[];
}

function SurveyPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneralInfoViewed, setIsGeneralInfoViewed] = useState(false);
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const [isSurveyFulfilled, setIsSurveyFulfilled] = useState(false);
  const [isCheckIncomplete, setIsCheckInComplete] = useState(false);
  const [survey, setSurvey] = useState<Survey | undefined>(undefined);
  const [currentSection, setCurrentSection] = useState<CurrentSection>({
    sectionIndex: -1,
    section: undefined,
    subSections: [],
    questions: [],
  });

  const { showInfo, showError } = useNotification();

  const errorHandler = (_err: any) => {
    showError(_err.status, strings.server_error);
    setIsLoading(false);
  };
  const getQuestion = (questionId: number): AnsweredQuestion => {
    const questions = currentSection.questions;
    const question = questions.find((q) => q.questionId === questionId);
    if (!question) {
      throw new Error("Cannot find question with id: " + questionId);
    }
    return question;
  };

  const changeAnswer = (questionId: number, answerId: number | undefined) => {
    const question = getQuestion(questionId);
    question.answerId = answerId;
    question.naSelected = !answerId;

    setCurrentSection({ ...currentSection });
  };

  const changeActionPriority = (questionId: number, priority: string) => {
    const question = getQuestion(questionId);
    question.actionPriority = priority;
    setCurrentSection({ ...currentSection });
  };

  const changeComment = (questionId: number, comment: string) => {
    const question = getQuestion(questionId);
    question.comment = comment;
    setCurrentSection({ ...currentSection });
  };

  const hasNextSection = () =>
    !!currentSection.section &&
    !!survey &&
    !!survey.sections &&
    survey.sections.length - 1 > currentSection.sectionIndex;

  const hasPreviousSection = () =>
    !!currentSection.section &&
    !!survey &&
    !!survey.sections &&
    currentSection.sectionIndex > 0;

  const previousSection = () =>
    updateCurrentSection(survey!, currentSection.sectionIndex - 1);

  const nextSection = () =>
    updateCurrentSection(survey!, currentSection.sectionIndex + 1);

  const updateCurrentSection = (_survey: Survey, sectionIndex: number) => {
    if (!_survey) {
      throw new Error("Invalid survey");
    }

    const section = _survey.sections[sectionIndex];
    const subSections = _survey.subSections.filter(
      (ss) => ss.sectionId === section.id
    );
    const questions = _survey.questions.filter(
      (q) => subSections.map((ss) => ss.id).indexOf(q.subSectionId) >= 0
    );

    setCurrentSection({
      sectionIndex,
      questions,
      subSections,
      section,
    });
  };

  const saveSurvey = () => {
    setIsLoading(true);
    const isAnyAnswered = survey?.questions.some((q) => q?.answerId !== null);
    surveyService
      .updateSurvey(id!, survey!)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error: APIError) => {
        setIsLoading(false);
        showError(error.status, error.message);
      });

    if (isAnyAnswered) {
      showInfo(strings.assessment_saved, TypeNotification.success, 3000);
    } else {
      showInfo(strings.assessment_not_thing, TypeNotification.warning, 3000);
    }
  };

  const submitSurvey = () => {
    setIsCheckInComplete(true);
    if (isSurveyFulfilled) {
      setIsLoading(true);
      surveyService
        .submitSurvey(id!, survey)
        .then(() => {
          setIsLoading(false);
          setIsSurveySubmitted(true);
        })
        .catch((err) => {
          errorHandler(err);
        });
    }
  };

  const calculateCompletedness = () => {
    if (!survey) {
      throw new Error(strings.invalid_assessment);
    }
    return survey.sections.map((s) => {
      const subSections = survey.subSections.filter(
        (ss) => ss.sectionId === s.id
      );
      const questions = survey.questions.filter(
        (q) => subSections.map((ss) => ss.id).indexOf(q.subSectionId) >= 0
      );

      const section = s.name;
      const completed = questions.filter(
        (q) =>
          q.naSelected ||
          (!!q.actionPriority && ((q.allowNA && q.naSelected) || !!q.answerId))
      ).length;
      const total = questions.length;
      return {
        section,
        completed,
        total,
      };
    });
  };

  const gotoSection = (sectionName: string) => {
    if (!survey) {
      throw new Error("Invalid survey");
    }
    const section = survey.sections.find((s) => s.name === sectionName);
    if (!section) {
      throw new Error("Invalid section");
    }
    const sectionIndex = survey.sections.findIndex((s) => s.id === section.id);
    const subSections = survey.subSections.filter(
      (ss) => ss.sectionId === section.id
    );
    const questions = survey.questions.filter(
      (q) => subSections.map((ss) => ss.id).indexOf(q.subSectionId) >= 0
    );

    setCurrentSection({
      sectionIndex,
      questions,
      subSections,
      section,
    });
  };

  const doAction = () => {
    surveyService
      .getById(id!)
      .then((_survey: Survey | undefined) => {
        setIsLoading(false);
        setIsGeneralInfoViewed(true);

        if (_survey) {
          setSurvey(_survey);
          updateCurrentSection(_survey, 0);
        }
      })
      .catch((err) => {
        errorHandler(err);
      });
  };

  if (isLoading) {
    return (
      <div className="survey-page">
        <Spinner animation="border" className="loading" />
      </div>
    );
  }

  if (!isGeneralInfoViewed) {
    return <SurveyGeneralInfo action={doAction} surveyId={id!} />;
  }

  if (isSurveySubmitted) {
    return <Navigate to={`/assessments/${id}/submitted`} />;
  }

  return (
    <div className="survey-page">
      <ClientHeader />
      <SurveyProgress
        onClick={gotoSection}
        sections={calculateCompletedness()}
        setIsSurveyFulfilled={setIsSurveyFulfilled}
        isExportable={true}
      />
      {currentSection.section && (
        <SurveyActionProvider
          changeAnswer={changeAnswer}
          changeActionPriority={changeActionPriority}
          changeComment={changeComment}
          hasNextSection={hasNextSection()}
          hasPreviousSection={hasPreviousSection()}
          previousSection={previousSection}
          nextSection={nextSection}
          saveSurvey={saveSurvey}
          isSurveyFulfilled={isSurveyFulfilled}
          setIsShowModalConfirm={setIsShowModalConfirm}
          isCheckComplete={isCheckIncomplete}
          setIsCheckComplete={setIsCheckInComplete}
        >
          <SectionProvider
            section={currentSection.section}
            subSections={currentSection.subSections}
            questions={currentSection.questions}
          >
            <SectionPage />
          </SectionProvider>
        </SurveyActionProvider>
      )}

      <Modal
        show={isShowModalConfirm}
        centered
        onHide={() => setIsShowModalConfirm(false)}
        className="submit-success-modal"
        dialogClassName="submit-success-modal-custom-width"
      >
        <Modal.Header closeButton className="modal-none-border"></Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center py-0">
          <div
            style={{
              border: "0.4rem solid #fbc880",
              width: "8rem",
              height: "8rem",
              display: "flex",
              borderRadius: "50%",
            }}
          >
            <AiOutlineExclamation
              className="text-center"
              style={{ color: "#f89406", fontSize: " 5rem", margin: "auto" }}
            />
          </div>
          <Modal.Title className="fs-2 font-weight-bold my-2 text-muted">
            <b>{strings.confirm_title}</b>
          </Modal.Title>
          <p className="py-2 my-0 text-muted fs-6">
            {strings.assessment_confirm_content}
          </p>
        </Modal.Body>
        <Modal.Footer className="modal-none-border d-flex justify-content-center align-items-center">
          <Button
            variant="primary"
            onClick={submitSurvey}
            style={{ minWidth: "4rem" }}
          >
            {strings.button_confirm}
          </Button>
          <Button
            style={{ minWidth: "4rem" }}
            variant="success"
            onClick={() => setIsShowModalConfirm(false)}
          >
            {strings.button_refuse}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SurveyPage;
