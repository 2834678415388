import React, { ReactNode } from "react";
import { mdiInformationVariant } from "@mdi/js";
import Icon from "@mdi/react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "./InfoTooltip.module.scss";

type AppProps = {
  content: ReactNode;
  position?: "top" | "bottom" | "left" | "right";
  size?: number;
  sectionName?: string;
};

function InfoTooltip({
  sectionName = "default",
  content,
  position = "right",
  size = 0.8,
}: AppProps) {
  return (
    <OverlayTrigger
      placement={position}
      overlay={(props: any) => (
        <Tooltip
          id="info-tooltip"
          {...props}
          className={`${styles["info-tooltip-" + sectionName]}`}
        >
          {content}
        </Tooltip>
      )}
      trigger={["click", "hover"]}
    >
      <Icon
        path={mdiInformationVariant}
        size={size}
        className={`${styles["info-icon"]}`}
      />
    </OverlayTrigger>
  );
}

export default InfoTooltip;
