export function fillSpiderGraphBackground(
  xCenter: any,
  yCenter: any,
  radius: any,
  ctx: any
) {
  const temp = (Math.PI * 3) / 2;
  ctx.save();
  for (let i = 0; i < 16; i++) {
    if (i == 0 || i == 4 || i == 9 || i == 13) {
      ctx.beginPath();
      ctx.moveTo(xCenter, yCenter);
      if (i !== 0) {
        i--;
        var x = Math.cos(temp + (Math.PI * i * 2) / 15) * radius + xCenter;
        var y = Math.sin(temp + (Math.PI * i * 2) / 15) * radius + yCenter;
        ctx.lineTo(x, y);
        i++;
      }
    }

    var x = Math.cos(temp + (Math.PI * i * 2) / 15) * radius + xCenter;
    var y = Math.sin(temp + (Math.PI * i * 2) / 15) * radius + yCenter;
    ctx.lineTo(x, y);

    if (i == 3 || i == 8 || i == 12 || i == 15) {
      ctx.fillStyle = fillBackground(i);
      ctx.fill();
    }
  }

  ctx.restore();
}
function fillBackground(index: number) {
  switch (index) {
    case 3:
      return "rgba(65, 192, 240, 0.4)";

    case 8:
      return "rgba(27, 175, 162, 0.4)";

    case 12:
      return "rgba(0, 112, 189, 0.4)";

    case 15:
      return "rgba(130, 46, 142, 0.4)";

    default:
      return "";
  }
}
