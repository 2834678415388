import { Container } from "react-bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminDashboard from "./compass/admin/pages/AdminDashboard";
import AdminLayout from "./compass/admin/components/common/AdminLayout";
import "./App.css";
import HomePage from "./compass/admin/pages/HomePage";
import LogOut from "./compass/admin/pages/LogOut";
import ManageSections from "./compass/admin/pages/ManageSections";
import AddNewSection from "./compass/admin/pages/AddNewSection";
import EditSection from "./compass/admin/pages/EditSection";
import ManageSubSections from "./compass/admin/pages/ManageSubSections";
import AddNewSubSection from "./compass/admin/pages/AddNewSubSection";
import EditSubSection from "./compass/admin/pages/EditSubSection";
import ManageQuestions from "./compass/admin/pages/ManageQuestions";
import AddNewQuestion from "./compass/admin/pages/AddNewQuestion";
import EditQuestion from "./compass/admin/pages/EditQuestion";
import Help from "./compass/admin/pages/Help";
import ViewSurveyPage from "./compass/admin/pages/ViewSurveyPage";

import { ThemeProvider } from "styled-components";
import { b4si } from "./pages/styles/theme";
import { GlobalStyles } from "./pages/styles/GlobalStyles";
import CaseStudy from "./compass/client/components/reports/CaseStudy";
import SurveyDetail from "./compass/client/components/reports/SurveyDetail";
import SurveySummaryReport from "./compass/client/components/reports/SurveySummaryReport";
import SurveyDraftVersion from "./compass/client/pages/SurveyDraftVersion";
import SurveyPage from "./compass/client/pages/SurveyPage";
import SurveyReport from "./compass/client/pages/SurveyReport";
import { AuthProvider } from "./components/AuthProvider";
import PageNotFound from "./components/error-handler/PageNotFound";
import { NotificationProvider } from "./components/NotificationProvider";
import PageTitleHandler from "./components/PageTitleHandler";
import LogIn from "./compass/admin/pages/LogIn";
import ViewSurveyScorePage from "./compass/admin/pages/ViewSurveyScorePage";
import { RolesAuthRoute } from "./components/RolesAuthRoute";
import WithInterceptor from "./components/WithInterceptor";
import SubmittedSurveyPage from "./compass/client/pages/SubmittedSurveyPage";

function App() {
  return (
    <ThemeProvider theme={b4si}>
      <GlobalStyles />
      <AuthProvider>
        <NotificationProvider>
          <Container fluid className="p-0">
            <BrowserRouter>
              <WithInterceptor>
                <PageTitleHandler>
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/login" element={<LogIn />} />
                    <Route path="/logout" element={<LogOut />} />

                    <Route path="/assessments/:id" element={<SurveyPage />} />
                    <Route
                      path="/assessments/:id/view"
                      element={<ViewSurveyPage />}
                    />
                    <Route
                      path="/assessments/:id/submitted"
                      element={<SubmittedSurveyPage />}
                    />
                    <Route
                      path="/assessments/:id/view-score"
                      element={<ViewSurveyScorePage />}
                    />
                    <Route
                      path="/assessments/:id/report"
                      element={<SurveyReport />}
                    />
                    <Route
                      path="/assessments/:id/case-study"
                      element={<CaseStudy />}
                    />
                    <Route
                      path="/assessments/:id/assessment-summary-report"
                      element={<SurveySummaryReport />}
                    />
                    <Route
                      path="/assessments/:id/assessment-detail"
                      element={<SurveyDetail />}
                    />
                    <Route
                      path="/assessments/:id/assessment-copy"
                      element={<SurveyDraftVersion />}
                    />
                    <Route
                      path="/admin"
                      element={
                        <RolesAuthRoute
                          routeRoles={["CoreAdmin", "MasterAdmin"]}
                        >
                          <AdminLayout>
                            <AdminDashboard />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    ></Route>
                    <Route
                      path="/admin/sections"
                      element={
                        <RolesAuthRoute routeRoles={["MasterAdmin"]}>
                          <AdminLayout>
                            <ManageSections viewOnly={false} />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/sections/view"
                      element={
                        <RolesAuthRoute routeRoles={["CoreAdmin"]}>
                          <AdminLayout>
                            <ManageSections viewOnly={true} />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/sections/add"
                      element={
                        <RolesAuthRoute routeRoles={["MasterAdmin"]}>
                          <AdminLayout>
                            <AddNewSection />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/sections/edit/:id"
                      element={
                        <RolesAuthRoute routeRoles={["MasterAdmin"]}>
                          <AdminLayout>
                            <EditSection />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/sub-sections"
                      element={
                        <RolesAuthRoute routeRoles={["MasterAdmin"]}>
                          <AdminLayout>
                            <ManageSubSections viewOnly={false} />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/sub-sections/view"
                      element={
                        <RolesAuthRoute routeRoles={["CoreAdmin"]}>
                          <AdminLayout>
                            <ManageSubSections viewOnly={true} />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/sub-sections/add"
                      element={
                        <RolesAuthRoute routeRoles={["MasterAdmin"]}>
                          <AdminLayout>
                            <AddNewSubSection />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/sub-sections/edit/:id"
                      element={
                        <RolesAuthRoute routeRoles={["MasterAdmin"]}>
                          <AdminLayout>
                            <EditSubSection />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/questions"
                      element={
                        <RolesAuthRoute routeRoles={["MasterAdmin"]}>
                          <AdminLayout>
                            <ManageQuestions viewOnly={false} />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/questions/view"
                      element={
                        <RolesAuthRoute routeRoles={["CoreAdmin"]}>
                          <AdminLayout>
                            <ManageQuestions viewOnly={true} />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/questions/add"
                      element={
                        <RolesAuthRoute routeRoles={["MasterAdmin"]}>
                          <AdminLayout>
                            <AddNewQuestion />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/questions/edit/:id"
                      element={
                        <RolesAuthRoute routeRoles={["MasterAdmin"]}>
                          <AdminLayout>
                            <EditQuestion />
                          </AdminLayout>
                        </RolesAuthRoute>
                      }
                    />
                    <Route
                      path="/admin/help"
                      element={
                        <AdminLayout>
                          <Help />
                        </AdminLayout>
                      }
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </PageTitleHandler>
              </WithInterceptor>
            </BrowserRouter>
          </Container>
        </NotificationProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
