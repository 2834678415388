export enum TypeSortingDate {
  DateSent = "DateSent",
  DateSubmitted = "DateSubmitted",
}

export default interface SurveyInsightFilter {
  fromDate: Date | undefined;
  toDate: Date | undefined;
  typeSortingDate: TypeSortingDate;
}
