import "./CaseStudy.css";
import styles from "./CaseStudy.module.scss";
function Thoughtful() {
  return (
    <div>
      <p className="normal page-header">
        <b>
          <span>Your Social Impact Maturity Level - Case Studies</span>
        </b>
      </p>
      <h4 className="normal text-center">
        <span data-testid="case-header" className="page-title">
          Thoughtful
        </span>
      </h4>
      <p className="normal page-description">
        As its name suggest, the <strong>Thoughtful</strong> level is
        characterised by an overall abundance of good ideas and intentions, but
        few systems in place to transform these into actionable strategies.
        Programmes might be planned and implemented through a philanthropic
        perspective and charitable giving would have a larger share of the
        contributions.
      </p>
      <p className="normal page-description">
        A company in this level will reflect some of the key aspects within the
        Illustrative examples below.
      </p>
      <h6 className={`normal company-header ${styles.thoughtful}`}>
        <strong>Illustrative example: Trustworthy Financial Services</strong>
      </h6>
      <p className="normal">
        <strong>Sector:</strong> Financial Services.
      </p>
      <p className="normal">
        <strong>Overview:</strong> A private{" "}
        <strong>financial assets manager</strong> and investment manager
        operating in <strong>Europe</strong>, with over £650M assets under
        management (AUM) in property and construction.
      </p>
      <p className="normal">
        <strong>Plan:</strong> TFS has a strong business purpose which is to
        facilitate long term prosperity for its clients. The company has a
        longstanding tradition of philanthropic investments in the arts,
        education, and health and it has invested in the development of
        hospitals and schools across the United Kingdom. TFS has recently
        commenced a process to define its social purpose with the goal of
        setting up a social impact strategy for the business.
      </p>
      <p className="normal">
        Community investment decisions at TFS are made by a committee of manager
        level staff members and follows a due diligence process that relies on
        referrals. Some grants and funding have been running for more than 10
        years and are considered a legacy mandate left from the company's
        founder.
      </p>
      <p className="normal">
        <strong>Implement:</strong> Community support programs are administered
        and implemented by employees in various teams across the company, based
        on their involvement with referring the cause for support. Charity
        partners and funding recipients are engaged informally, enabling the
        company to identify any training needs and to check in on the
        appropriate allocation of funds.
      </p>
      <p className="normal">
        Twice a year, TFS runs a volunteering event for all its 600 employees. A
        social impact champion in each office is responsible for connecting with
        local community organisations and activities can vary from
        infrastructure improvements such as painting and installing new
        furniture, to running a fundraising campaign at the local high street.
      </p>
      <p className="normal">
        <strong>Measure:</strong> The company captures data relating to the
        total cash contributions across strategic investments and donations and
        there's one question in the annual employee engagement survey relating
        to community investment and the effects of volunteerism. A member of the
        community team meets with a HR representative twice a year to cross
        check opportunities within the community program against the People
        agenda.
      </p>
      <p className="normal">
        <strong>Report and Engage:</strong> The company is not subject to
        mandatory integrated reporting requirements. The community engagement
        strategy is outlined on the organisation's website and reported on the
        intranet, on social media and in regular email newsletters to
        stakeholders.
      </p>
      <p className="normal">
        <strong>Next steps:</strong> TFS will focus on improving its maturity
        level by:
      </p>
      <ul className="dashed">
        <li className="dashed">
          Engaging with pages 7 to 10 of{" "}
          <strong className="cs-text-blue">
            B4SI Corporate Community Investment Guidance Manual
          </strong>{" "}
          for guidance on what is meant by Community Investment and B4SI's
          maxims on what counts within this route.
        </li>
        <li className="dashed">
          Conducting a{" "}
          <strong className="cs-text-blue">stakeholder engagement</strong>{" "}
          exercise to gather perspectives on the company's current approach to
          social impact with a focus on understanding alignment with broader
          business purpose and strategy, as well as what's working and what's
          not to shape the future direction of the social impact strategy.
        </li>
        <li className="dashed">
          Review existing partnerships and identify where measurement is taking
          place to understand gaps and opportunities through the Project
          Assessment Sheet on the{" "}
          <strong className="cs-text-blue">B4SI Impact Toolkit.</strong>
        </li>
        <li className="dashed">
          Use the{" "}
          <strong className="cs-text-blue">
            B4SI Corporate Community Investment Guidance Manual
          </strong>{" "}
          along with the{" "}
          <strong className="cs-text-blue">
            Internal Data Collection Tool
          </strong>{" "}
          to determine how to measure your inputs, outputs, and impacts.
        </li>
        <li className="dashed">
          Implement the use of the{" "}
          <strong className="cs-text-blue">B4SI Logo</strong> for internal and
          external communications.
        </li>
        <li className="dashed">
          Connect with peer companies in the B4SI network to share ideas and
          learnings.
        </li>
      </ul>

      <div style={{ pageBreakAfter: "always" }}></div>

      <h6 className={`normal company-header ${styles.thoughtful}`}>
        <strong>Illustrative example: AusTransit Pty Ltd</strong>
      </h6>
      <p className="normal">
        <strong>Sector:</strong> Property, Construction, Housing.
      </p>
      <p className="normal">
        <strong>Overview:</strong> A medium sized transport company that
        produces construction vehicles and machinery. Headquartered in Perth,
        Australia, with national operations and internationally in China, India,
        New Zealand, and Papua New Guinea.
      </p>
      <p className="normal">
        <strong>Plan:</strong> The Australian based corporate affairs team
        manages the organisation's community engagement programs. A different
        theme is chosen each year and organisational visibility in the local
        community is a top priority. Each region has identified a charity
        partner with which it works to meet community needs aligned to the theme
        in question, by providing donations to regionally significant campaigns.
      </p>
      <p className="normal">
        <strong>Implement:</strong> A basic methodology is in place to assess
        the suitability of charity partners and the corporate affairs team
        places great importance on the quality of the organisation's one-way
        stakeholder communications. Any new community partnerships require CEO
        approval and members of the regional HR teams organise ad hoc
        volunteering events which employees can participate in.
      </p>

      <p className="normal">
        <strong>Measure:</strong> Each regional office manages its own community
        budget, and the total inputs (cash and volunteer time) get collated in
        the Internal Data Collection Tool, managed centrally by a team member in
        Perth, which is captured in quarterly internal reporting.
      </p>
      <p className="normal">
        <strong>Report and Engage:</strong> The company has released one
        sustainability report which included a section detailing the community
        investment efforts across the various regions through qualitative case
        studies. It has committed to an annual reporting cadence including
        progress updates against published targets.
      </p>
      <p className="normal">
        <strong>Next steps:</strong> To improve its scores, AusTransit might
        take some of the following actions:
      </p>
      <ul className="dashed">
        <li className="dashed">
          Conduct a{" "}
          <strong className="cs-text-blue">materiality assessment</strong> to
          identify key topics that are important to the business as well as to
          stakeholders. Identify topics that fall in the “S” in ESG that are
          critical for the company to address to differentiate itself, stay
          competitive or that are considered table stakes for companies today.
        </li>
        <li className="dashed">
          Engage charity partners through{" "}
          <strong className="cs-text-blue">
            B4SI For Effective Partnerships.
          </strong>{" "}
          Define a shared strategy and introduce impact measurement
          capabilities.
        </li>
        <li className="dashed">
          Develop a social impact strategy with a long-term sustainable outlook.
        </li>
        <li className="dashed">
          Prioritise collaboration and cohesive approach to social impact across
          business units.
        </li>
        <li className="dashed">
          Assess measurement of current initiatives through the lens of the{" "}
          <strong className="cs-text-blue">B4SI Guidance Notes</strong> to
          define internal guidance and best practice.
        </li>
      </ul>
    </div>
  );
}
export default Thoughtful;
