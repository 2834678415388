import { strings } from "../../../localization/en";
function NavBar() {
  return (
    <nav className="navbar navbar-expand-md navbar-light bg-info shadow-sm text-white">
      <div className="container">
        <span className="navbar-brand" style={{ color: "#fff" }}>
          {strings.welcome}
        </span>
      </div>
    </nav>
  );
}

export default NavBar;
