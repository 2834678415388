export interface SectionViewModel {
  id: number;
  name: string;
  description: string;
}
export function sortSections(sections: SectionViewModel[]) {
  return sections.sort((a, b) => {
    const firstNum = a.name.substring(0, a.name.indexOf("."));
    const secondNum = b.name.substring(0, b.name.indexOf("."));
    let res = parseInt(firstNum) - parseInt(secondNum);
    if (res === 0) {
      const firstStr = a.name.substring(a.name.indexOf(".") + 1);
      const secondStr = b.name.substring(b.name.indexOf(".") + 1);
      res = firstStr.localeCompare(secondStr);
    }
    return res;
  });
}
