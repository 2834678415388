import { useEffect, useRef, useState } from "react";
import { Form, Overlay, Tooltip } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { atLeastANumber } from "../../helpers/regex-pattern";
import { strings } from "../../../localization/en";
import "./RawScoreInput.css";
type AppProps = {
  name: string;
  control: any;
  trigger: any;
  fallback: number;
};
function RawScoreInput({ name, control, trigger, fallback }: AppProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => setShowTooltip(false), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showTooltip]);
  const target = useRef(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState }) => {
        const { errors } = formState;
        return (
          <div ref={target} className="position-relative">
            <Form.Control
              type="number"
              onWheel={(_: any) =>
                (document.activeElement as HTMLElement).blur()
              }
              onKeyPress={async (event: any) => {
                if (!atLeastANumber.test(event.key)) {
                  event.preventDefault();
                }
              }}
              {...field}
              isInvalid={!!errors[name]}
              className="h-100 rounded-0 raw-score-input text-center"
              onBlur={async () => {
                if (errors[name]) {
                  field.onChange(fallback);
                  await trigger(name);
                  setShowTooltip(true);
                }
              }}
              onChange={async (e: any) => {
                field.onChange(e);
                await trigger(name);
              }}
            />
            <Overlay
              target={target.current}
              show={showTooltip}
              placement="bottom"
            >
              {(props) => (
                <Tooltip {...props}>
                  {strings.question_tooltip_raw_score}
                </Tooltip>
              )}
            </Overlay>
          </div>
        );
      }}
      rules={{
        required: true,
        min: 1,
      }}
    />
  );
}

export default RawScoreInput;
