export const weightingOptions = [
  {
    display: 0.5,
    value: 0.5,
  },
  {
    display: 1,
    value: 1,
  },
  {
    display: 1.5,
    value: 1.5,
  },
  {
    display: 2,
    value: 2,
  },
  {
    display: 2.5,
    value: 2.5,
  },
  {
    display: 3,
    value: 3,
  },
  {
    display: 3.5,
    value: 3.5,
  },
  {
    display: 4,
    value: 4,
  },
  {
    display: 4.5,
    value: 4.5,
  },
  {
    display: 5,
    value: 5,
  },
];
