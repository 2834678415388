import CaseStudy from "../components/reports/CaseStudy";
import SurveyDetail from "../components/reports/SurveyDetail";
import SurveySummaryReport from "../components/reports/SurveySummaryReport";

function SurveyReport() {
  return (
    <>
      <SurveySummaryReport />
      <div style={{ pageBreakAfter: "always" }}></div>
      <CaseStudy />
      <div style={{ pageBreakAfter: "always" }}></div>
      <SurveyDetail />
    </>
  );
}
export default SurveyReport;
