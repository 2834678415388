import * as React from "react";
import { Pagination } from "react-bootstrap";
import { PaginationModel } from "../../models";
import { PaginationCalculator, DOTS } from "../PaginationCalculator";

interface PaginationProps extends PaginationModel {
  pageChanged: (page: number) => void;
}

function TablePagination(props: PaginationProps) {
  const { totalItems, currentPage, pageSize, pageChanged } = props;

  const totalTabs: number =
    Math.floor(totalItems / pageSize) + (totalItems % pageSize === 0 ? 0 : 1);

  const tabItems = PaginationCalculator({
    currentPage: currentPage,
    totalCount: totalItems,
    pageSize: pageSize,
  });

  return (
    <Pagination className="d-flex justify-content-center position-relative my-2">
      {totalTabs > 1 && (
        <>
          <Pagination.Prev
            onClick={() => pageChanged(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Pagination.Prev>

          {tabItems?.map((pageNumber, pageIdx: number) => {
            if (pageNumber === DOTS) {
              return <Pagination.Ellipsis key={pageIdx} disabled />;
            }

            return (
              <Pagination.Item
                onClick={(function (index: any) {
                  return function () {
                    pageChanged(index);
                  };
                })(pageNumber)}
                active={currentPage === pageNumber}
                key={pageIdx}
              >
                {pageNumber}
              </Pagination.Item>
            );
          })}

          <Pagination.Next
            onClick={() => pageChanged(currentPage + 1)}
            disabled={currentPage === totalTabs}
          >
            Next
          </Pagination.Next>
        </>
      )}
    </Pagination>
  );
}

export default TablePagination;
