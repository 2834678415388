import { FallbackProps } from "react-error-boundary";
import { strings } from "../../localization/en";

function ErrorHandler(props: FallbackProps) {
  const { error } = { ...props };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="display-1 fw-bold text-danger">
          {strings.boundary_error_header}
        </h1>
        <p className="fs-3 text-center">
          <span className="">{strings.boundary_error_content}</span>
        </p>
        <p className="lead text-center">{strings.boundary_error_description}</p>
      </div>
    </div>
  );
}

export default ErrorHandler;
