import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { StatisticData, SurveyState } from "../../models";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { strings } from "../../../../localization/en";

export type AppProps = {
  statistics: StatisticData[];
};

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function PieChart({ statistics }: AppProps) {
  const data = {
    labels: [
      strings.dashboard_not_started_label,
      strings.dashboard_in_progress_label,
      strings.dashboard_completed_label,
    ],
    datasets: [
      {
        label: "",
        data: [
          statistics.find((x) => x.state === SurveyState.NotStarted)?.count ||
            0,
          statistics.find((x) => x.state === SurveyState.InProgress)?.count ||
            0,
          statistics.find((x) => x.state === SurveyState.Submitted)?.count || 0,
        ],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 206, 86, 0.2)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const position: any = "right";
  const align: any = "center";

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        formatter: (value: number, ctx: any) => {
          let datasets = ctx.chart.data.datasets;
          let sum = datasets[0].data.reduce((a: number, b: number) => a + b, 0);
          let percentage = Math.round((value / sum) * 100) + "%";
          return value <= 0 ? "" : percentage;
        },
      },
      legend: {
        display: true,
        position: position,
        align: align,
      },
    },
  };
  return (
    <div style={{ width: "350px" }}>
      <Pie data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
}

export default PieChart;
