import { Container, Table } from "react-bootstrap";
import { BiEditAlt } from "react-icons/bi";
import { IoTrashOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import Title from "../components/common/Title";
import { Section } from "../../models/survey";
import ConfirmDeleteModal from "../components/common/ConfirmDeleteModal";
import React, { useEffect, useState } from "react";
import adminService from "../services/admin.service";
import {
  TypeNotification,
  useNotification,
} from "../../../components/NotificationProvider";
import { strings } from "../../../localization/en";

let deletingId: number;

function ManageSections({ viewOnly }: { viewOnly: boolean }) {
  const [sections, setSections] = useState<Section[]>([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { showError, showInfo } = useNotification();
  useEffect(() => {
    adminService.getAllSections().then((response) => {
      setSections(response);
    });
  }, []);

  const handleShowModal = (id: number) => {
    deletingId = id;
    setShowModal(true);
  };

  const deleteSection = () => {
    adminService
      .deleteSection(deletingId)
      .then((_) => {
        showInfo(
          strings.section_deleted_message,
          TypeNotification.success,
          3000
        );
        adminService.getAllSections().then((response) => {
          setSections(response);
        });
      })
      .catch((error) => {
        showError(error.status, strings.server_error);
      })
      .finally(() => setShowModal(false));
  };

  return (
    <Container fluid>
      <Title content={strings.section_delete_title} />
      <Container fluid className="bg-white p-4">
        <Table responsive className="custom-table">
          <thead className="table-gray ">
            <tr>
              <th className="text-center m-2" style={{ width: "20%" }}>
                {strings.section_table_header_section}
              </th>
              <th className="m-2">Description</th>
              {!viewOnly && (
                <React.Fragment>
                  <th className="text-center m-2" style={{ width: "10%" }}>
                    {strings.table_header_edit}
                  </th>
                  <th className="text-center m-2" style={{ width: "10%" }}>
                    {strings.table_header_delete}
                  </th>
                </React.Fragment>
              )}
            </tr>
          </thead>
          <tbody>
            {sections.map((section, i) => (
              <tr key={i}>
                <td>{section.name}</td>
                <td>{section.description}</td>
                {!viewOnly && (
                  <React.Fragment>
                    <td className="text-center">
                      <BiEditAlt
                        fontSize={25}
                        onClick={() => {
                          navigate(`/admin/sections/edit/${section.id}`);
                        }}
                        className="icon-button"
                      />
                    </td>
                    <td className="text-center">
                      <IoTrashOutline
                        fontSize={25}
                        className="icon-button"
                        onClick={() =>
                          (function (id) {
                            handleShowModal(id);
                          })(section.id)
                        }
                      />
                    </td>
                  </React.Fragment>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <ConfirmDeleteModal
        show={showModal}
        setShow={setShowModal}
        handleConfirm={deleteSection}
      />
    </Container>
  );
}

export default ManageSections;
