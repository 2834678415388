import React, { useState, useEffect } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  TypeNotification,
  useNotification,
} from "../../../components/NotificationProvider";
import { subSectionRegex } from "../../helpers/regex-pattern";
import { strings } from "../../../localization/en";
import AnswerSetInput from "../components/common/AnswerSetInput";
import DropdownWithLabel from "../components/common/DropdownWithLabel";
import InputWithLabel from "../components/common/InputWithLabel";
import Title from "../components/common/Title";
import { SectionViewModel, sortSections, SubsectionViewModel } from "../models";
import adminService from "../services/admin.service";

function EditSubSection() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [subsection, setSubsection] = useState<SubsectionViewModel | undefined>(
    undefined
  );
  const [sections, setSections] = useState<SectionViewModel[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: subsection?.name || "",
      sectionId: subsection?.sectionId || "",
      option1: subsection?.option1 || "",
      option2: subsection?.option2 || "",
      option3: subsection?.option3 || "",
      option4: subsection?.option4 || "",
    },
  });

  const { showError, showInfo } = useNotification();

  const handleEditSubSection = handleSubmit(async (data) => {
    adminService
      .updateSubSection(+id!, { ...data, sectionId: +data.sectionId })
      .then((_) => {
        showInfo(
          strings.subsection_edited_message,
          TypeNotification.success,
          3000
        );
        navigate("/admin/sub-sections", { state: location.state });
      })
      .catch((error) => {
        showError(error.status, strings.server_error);
      });
  });

  useEffect(() => {
    adminService.getSubsection(+id!).then((data) => {
      setSubsection(data);
      reset(data);
    });

    adminService.getAllSections().then((data) => {
      const sortedSections = sortSections(data);
      setSections(sortedSections);
    });
  }, [reset, id]);

  const renderMessagesForOptions = () => {
    const found = Object.keys(errors).findIndex((e) => e.includes("option"));
    if (found >= 0) return <li>All options must have an answer.</li>;
    return <></>;
  };
  return (
    <Container fluid>
      <Title content={strings.subsection_edit_title} />
      <Container fluid className="bg-white p-4">
        {Object.keys(errors).length !== 0 ? (
          <Alert variant="danger">
            <ul className="m-0">
              {Object.keys(errors).map((e) =>
                !e.includes("option") ? (
                  <li key={e}>{(errors as any)[e].message}</li>
                ) : (
                  <React.Fragment key={e}></React.Fragment>
                )
              )}
              {renderMessagesForOptions()}
            </ul>
          </Alert>
        ) : (
          <></>
        )}
        <Form>
          <InputWithLabel
            label={strings.subsection_label_subsection}
            name="name"
            value={subsection?.name}
            control={control}
            placeholder={strings.subsection_placeholder_subsection}
            rules={{
              required: strings.subsection_error_subsection_empty,
              pattern: {
                value: subSectionRegex.name,
                message: strings.subsection_error_subsection_format,
              },
            }}
          />
          <DropdownWithLabel
            options={sections.map((s) => ({ display: s.name, value: s.id }))}
            label={strings.subsection_label_section}
            name="sectionId"
            control={control}
            rules={{
              required: strings.subsection_error_section_empty,
            }}
            placeholder={strings.subsection_placeholder_section}
          />
          <Form.Group as={Row} className="mb-3 ms-0">
            <Form.Label
              className="bg-label py-3 fw-bold d-flex align-items-center"
              column
              sm="2"
            >
              {strings.label_answer_set}
            </Form.Label>
            <Col sm="10">
              <Row>
                <Col xs="3">
                  <div className="bg-label py-2 px-3 fw-bold text-center mb-2">
                    {strings.label_option_1}
                  </div>
                  <AnswerSetInput
                    name="option1"
                    value={subsection?.option1}
                    control={control}
                    rules={{
                      required: "Option 1 is required.",
                    }}
                  />
                </Col>
                <Col xs="3">
                  <div className="bg-label py-2 px-3 fw-bold text-center mb-2">
                    {strings.label_option_2}
                  </div>
                  <AnswerSetInput
                    name="option2"
                    value={subsection?.option2}
                    control={control}
                    rules={{
                      required: "Option 2 is required.",
                    }}
                  />
                </Col>
                <Col xs="3">
                  <div className="bg-label py-2 px-3 fw-bold text-center mb-2">
                    {strings.label_option_3}
                  </div>
                  <AnswerSetInput
                    name="option3"
                    value={subsection?.option3}
                    control={control}
                    rules={{
                      required: "Option 3 is required.",
                    }}
                  />
                </Col>
                <Col xs="3">
                  <div className="bg-label py-2 px-3 fw-bold text-center mb-2">
                    {strings.label_option_4}
                  </div>
                  <AnswerSetInput
                    name="option4"
                    value={subsection?.option4}
                    control={control}
                    rules={{
                      required: "Option 4 is required.",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Form.Group>
          <div className="text-center mt-5">
            <Button onClick={handleEditSubSection}>
              {strings.save_changes_button}
            </Button>
          </div>
        </Form>
      </Container>
    </Container>
  );
}

export default EditSubSection;
