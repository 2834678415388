export function handleResponse(response: any) {
  return response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      let error = (data && data.message) || response.statusText;
      if (!error) {
        if (response.status === 401) {
          error = "Unauthorized Access";
        } else if (response.status === 403) {
          error = "Forbidden";
        } else {
          error = "Unknown error";
        }
      }
      return Promise.reject({
        error: error,
        status: response.status,
        data: data,
      });
    }

    return Promise.resolve(data);
  });
}

export function handleBlobResponse(response: any) {
  const filename = response
    .headers!.get("Content-Disposition")!
    .split(`filename="`)[1]
    .split(`"`)[0];
  response.blob().then((blob: any) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  });
}
