import { Form } from "react-bootstrap";

interface PriorityProps {
  onChange: (evt: any) => void;
  value: string;
  chooseNA: boolean;
  className: string;
}

function ActionPriority(props: PriorityProps) {
  const { onChange, chooseNA, value, className } = props;
  const options = ["", "Low", "Medium", "High"];
  return (
    <Form.Select
      disabled={chooseNA}
      className={`px-4 ${className}`}
      onChange={onChange}
      value={value}
    >
      {options.map((o, i) => (
        <option value={o} key={i}>
          {o}
        </option>
      ))}
    </Form.Select>
  );
}

export default ActionPriority;
