import B4SIAllWhiteLogo from "./logos/CC_B4SI_logo_WO.png";
import B4SIAllWhiteCropLogo from "./logos/CC_B4SI_logo_WO_Crop.png";
const b4siBranding = {
  black: "rgb(0, 0, 0)",
  blue: "rgb(65,192,240)",
  teal: "rgb(27,175,162)",
  green: "rgb(12,155,105)",
  yellow: "rgb(240,182,47)",
  orange: "rgb(246,137,32)",
  purple: "rgb(130,46,142)",
  darkBlue: "#336EB2",
  navy: "#116184",
  white: "rgb(255,255,255)",
  gray0: "rgb(185,180,187)",
  gray1: "rgb(135,135,135)",
  gray2: "rgb(87,87,87)",
  fontFamilyHeadlines: "Century Gothic",
  fontFamilyBody: "Omnes",
  headlinesFontWeight: "bold",
  bodyFontWeight: "normal",
  logoAllWhite: B4SIAllWhiteLogo,
  logoAllWhiteCrop: B4SIAllWhiteCropLogo,
};
export default b4siBranding;
