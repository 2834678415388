import { Ordering } from "./ordering";
import { SurveyCategory } from "./survey-category";
import { TypeSortingDate } from "./survey-insight-filter";
import { SurveyState } from "./survey-state";

export interface SurveyFilter {
  status: SurveyState;
  category: SurveyCategory;
  search: string;
  itemsPerPage: number;
  currentPage: number;
  orderDirection: Ordering;
  orderBy: SurveyOrderByColumn;
  dateFrom: string;
  dateTo: string;
  typeSortingDate: TypeSortingDate;
}

export enum SurveyOrderByColumn {
  DateSent = "DateSent",
  FirstName = "FirstName",
  Surname = "Surname",
  Company = "Company",
  Email = "Email",
  Status = "Status",
  DateSubmitted = "DateSubmitted",
  Maturity = "Maturity",
}
