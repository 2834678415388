import "./CaseStudy.css";
import styles from "./CaseStudy.module.scss";
function Strategic() {
  return (
    <div>
      <p className="normal page-header">
        <b>
          <span>Your Social Impact Maturity Level - Case Studies</span>
        </b>
      </p>
      <h4 className="normal text-center">
        <span className="page-title" data-testid="case-header">
          Strategic
        </span>
      </h4>
      <p className="normal page-description">
        The third maturity level in the B4SI Social Impact Compass is{" "}
        <strong>Strategic</strong>. Companies at this level have made greater
        efforts to align their social impact strategy to the business, and
        leverage company resources to deliver the most effective programmes. At
        this level, examples of social procurement or business innovation
        projects may be emerging and/or thorough impact measurement applied
        across activities.
      </p>
      <p className="normal page-description">
        A company in this level may reflect some of the key elements in the
        Illustrative example below.
      </p>
      <h6 className={`normal company-header ${styles.strategic}`}>
        <strong>Illustrative example: Very Healthy Foods</strong>
      </h6>
      <p className="normal">
        <strong>Sector:</strong> Consumer Goods
      </p>
      <p className="normal">
        <strong>Overview:</strong> A U.S grocery and clothing store chain
        operating in 700 locations across 10 countries with 250,000 employees.
      </p>
      <p className="normal">
        <strong>Plan:</strong> The company's social impact strategy is designed
        collaboratively by each of the local divisions, with a strong focus on
        gathering input from all areas of the business. Each division has
        identified a series of key issues to engage with locally and has defined
        initiatives to tackle them. Although initiatives don't necessarily stem
        from a joint business and community assessment, overall projects support
        business sustainability by improving the conditions of the communities
        the company has stores in.
      </p>
      <p className="normal">
        <strong>Implement:</strong> Most projects fall under one or more of the
        identified strategic pillars which are: healthy living, employment and
        education, community resilience. These pillars have clear supporting
        policies and definitions. They are implemented through long term
        partnerships with local charities and the contribution of in-kind
        donations, cash, volunteering, and advertising space in the stores.
        Australia and the US implement a flagship diverse supplier project that
        invests in social enterprises run by minorities, to establish long term
        commercial relationships. All impact partners are responsible for output
        measurement and receive training accordingly. They are not directly
        involved in deciding the impact strategy but provide key input on
        identifying impact areas.
      </p>
      <p className="normal">
        Employees are engaged through volunteering in stores and running
        fundraising campaigns. Some countries have a well-developed skills-based
        volunteering programme, where employees give up to 2 days/year
        supporting charity partners in finance, accounting, strategy, and/or
        operational support. The involvement in each of these programmes is not
        linked to employee performance internally.
      </p>

      <p className="normal">
        <strong>Measure:</strong> All initiatives have defined output KPIs that
        ladder up to each pillar's strategy on a companywide dashboard. This
        dashboard is reviewed annually by the sustainability and the operations
        teams, to evaluate and adjust. There is no impact measurement in place.
      </p>
      <p className="normal">
        <strong>Report and Engage:</strong> Social Impact reporting is presented
        both on the integrated annual report and on a separate impact report.
        Key metrics are presented in line to the B4SI framework and matched to
        SDGs. As there is not impact measurement in place, impact is reported as
        qualitative case studies, featuring stories from each country. Data is
        not assured externally.
      </p>
      <p className="normal">
        <strong>Next steps:</strong> To continue its journey to become a
        transformational business, Very Healthy Foods might take some of the
        following actions.
      </p>
      <ul className="dashed">
        <li className="dashed">
          Conduct a{" "}
          <strong className="cs-text-blue">stakeholder engagement</strong>{" "}
          exercise to gather perspectives on the company's current approach to
          social impact with a focus on understanding alignment with broader
          business purpose and strategy, as well as what's working and what's
          not to shape the future direction of the social impact strategy.
        </li>
        <li className="dashed">
          Consider implementing social impact through Procurement or Business
          Innovation, starting with the{" "}
          <strong className="cs-text-blue">
            B4SI Business Investment for Social Impact Guidance Manual
          </strong>
          .
        </li>
        <li className="dashed">
          Engage with charity partners to define a shared strategy, introduce
          impact measurement capabilities, and review current initiatives
          through{" "}
          <strong className="cs-text-blue">
            B4SI For Effective Partnerships
          </strong>
          .
        </li>
        <li className="dashed">
          Access the{" "}
          <strong className="cs-text-blue">B4SI Impact Toolkit</strong> for
          resources to identify where impact measurement is taking place and
          understand the gaps in information.
        </li>
        <li className="dashed">
          Improve impact measurement capabilities across the business and with
          local offices by delivering a{" "}
          <strong className="cs-text-blue">Bespoke Training Session</strong> on
          B4SI's Impact Framework.
        </li>
        <li className="dashed">
          Review the accessibility and reach of the employee volunteering
          program
        </li>
        <li className="dashed">
          Consider the frequency of social impact program performance reviews
        </li>
        <li className="dashed">
          Consider external verification of data/ assurance.
        </li>
      </ul>

      <div style={{ pageBreakAfter: "always" }}></div>

      <h6 className={`normal company-header ${styles.strategic}`}>
        <strong>Illustrative example: SRG</strong>
      </h6>
      <p className="normal">
        <strong>Sector:</strong> Financial Services
      </p>
      <p className="normal">
        <strong>Overview:</strong> SRG is a multinational professional services
        network that operates in 58 countries and has an annual revenue of $13
        billion. It works with clients to help manage, support, and grow their
        businesses in several service areas including audit, tax, pensions and
        legal, deal advisory and consulting.
      </p>
      <p className="normal">
        <strong>Plan:</strong> SRG's business purpose is to address strategic
        business challenges and build trust in society. This underpins its
        social impact strategy. Following a joint business and community
        assessment across all the regions it operates, SRG identified
        inclusivity and environmental sustainability as the two themes to centre
        their social impact strategy around. Each division then translates these
        goals into suitable localised initiatives to maximise the positive
        impact for all stakeholders involved. For example, SRG India has worked
        with a local partner to develop training and education aimed at rural
        communities to close the skills and opportunity gap they face, while SRG
        Australia provides funding that supports indigenous communities to
        access employment in the consulting sector.
      </p>
      <p className="normal">
        <strong>Implement:</strong> Local social impact teams determine the
        scope and objective of each initiative to ensure that each social impact
        programme aligns with the company's global strategy. On the project
        level, each division engages with local leading charities whose
        objectives align with SRG's strategy. Along with objectives, these
        partnerships also consider the business' resources to maximise impact.
        All partners are responsible for output and impact measurement and some
        divisions provide impact and data measurement training.
      </p>
      <p className="normal">
        SRG's social impact strategy involves inputs in community investment,
        social procurement, and business innovation. The company's global focus
        on inclusivity and environmental sustainability underpins all its local
        community investment programmes. Diverse suppliers are engaged through
        the social procurement programme which invests in local and sustainable
        business. In the business innovation space, SRG has recently developed
        an easy to apply measurement for how diverse and inclusive a company is
        and incorporated the measurement into their consulting work, analysis of
        client performance and as an open assessment which every organisation
        can have access at SRG's website
      </p>
      <p className="normal">
        All employees are encouraged to engage in these programmes. Beyond
        employee fundraising, the company leverages its expertise in pro bono
        projects and volunteering initiatives. SRG has implemented a 2-day
        volunteering programme across all its divisions whereby employees can
        support their charity partner in finance, strategy, and operational
        support. Participation in this volunteering scheme is included in
        internal employee performance reviews.
      </p>
      <p className="normal">
        <strong>Measure:</strong> Data is collected across all programmes, but
        the level of detail on community and social impact varies depending on
        partner resources and accessibility to measurement training. Business
        impacts are measured internally through employee questionnaires and both
        local and global reputational assessments. This allows SRG to assign a
        monetary value to its volunteer initiatives and understand the
        variations in sales due to cause related marketing. All initiatives have
        clearly defined output KPIs that are linked to one of the core themes in
        SRG's social impact strategy and are added up on a companywide dashboard
        which allows for companywide visibility on the progress being made
        against its targets. This is then compiled and reviewed every 2 years by
        the sustainability team to evaluate progress and adjust measurement
        practices.
      </p>
      <p className="normal">
        <strong>Report and Engage:</strong> Social impact reporting that
        integrates data from programmes across all SRG's divisions is presented
        in the annual report and on a separate impact report. Reporting is
        presented in line with the B4SI framework and is matched to SDGs. Data
        is not assured externally.
      </p>
      <p className="normal">
        <strong>Next steps:</strong> To continue its journey to become a
        transformational business, SRG might take some of the following actions.
      </p>
      <ul className="dashed">
        <li className="dashed">
          Engage with charity partners to define a shared strategy, introduce
          impact measurement capabilities, and review current initiatives
          through{" "}
          <strong className="cs-text-blue">
            B4SI For Effective Partnerships.
          </strong>
        </li>
        <li className="dashed">
          Improve impact measurement capabilities across the business and with
          local offices by delivering a{" "}
          <strong className="cs-text-blue">Bespoke Training Session</strong> on
          B4SI's Impact Framework.
        </li>
        <li className="dashed">
          Access the{" "}
          <strong className="cs-text-blue">B4SI Impact Toolkit</strong> for
          resources to identify where impact measurement is taking place and
          understand the gaps in information.
        </li>
        <li className="dashed">
          Validate its data independently through{" "}
          <strong className="cs-text-blue">B4SI Assurance</strong> to increase
          the quality of reports and alignment to B4SI Frameworks.
        </li>
      </ul>
    </div>
  );
}

export default Strategic;
