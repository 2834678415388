import { Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";

type AppProps = {
  name: string;
  value?: any;
  control: any;
  rules: any;
  label: string;
  placeholder: string;
};

function InputWithLabel({
  name,
  value,
  control,
  rules,
  label,
  placeholder,
}: AppProps) {
  return (
    <Form.Group as={Row} className="mb-3 mx-0" controlId="formPlaintextEmail">
      <Form.Label column className="bg-label py-3 fw-bold" sm="2">
        {label}
      </Form.Label>
      <Col sm="10" className="position-relative px-0">
        <Controller
          name={name}
          defaultValue={value}
          control={control}
          render={({ field, formState }) => {
            const { errors } = formState;
            return (
              <Form.Control
                {...field}
                className="h-100 rounded-0 "
                placeholder={placeholder}
                isInvalid={!!errors[name]}
              />
            );
          }}
          rules={rules}
        />
      </Col>
    </Form.Group>
  );
}

export default InputWithLabel;
