import { matchPath } from "react-router-dom";
export function pageTitleGenerator(url: string) {
  if (
    matchPath("/admin/sections", url) ||
    matchPath("/admin/sections/add", url) ||
    matchPath("/admin/sections/edit/:id", url)
  )
    return "B4SI | Sections";
  if (
    matchPath("/admin/sub-sections", url) ||
    matchPath("/admin/sub-sections/add", url) ||
    matchPath("/admin/sub-sections/edit/:id", url)
  )
    return "B4SI | Sub-Sections";
  if (
    matchPath("/admin/questions", url) ||
    matchPath("/admin/questions/add", url) ||
    matchPath("/admin/questions/edit/:id", url)
  )
    return "B4SI | Questions";
  if (matchPath("/assessments/:id/view", url)) return "B4SI | View Assessment";
  if (matchPath("/assessments/:id", url)) return "B4SI | Verify";

  switch (url) {
    case "/login":
      return "B4SI | Log in";
    case "/admin":
      return "B4SI | Dashboard";
    case "/admin/help":
      return "B4SI | Help";
    default:
      return "B4SI";
  }
}
