import { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SidebarRoute } from "../../models/sidebar-route";
import "./AdminNavItem.css";
type Prop = {
  index: number;
  route: SidebarRoute;
  role?: string;
  activePath: string;
  currentlyCollapsed: number;
  setCurrentlyCollapsed: (i: number) => void;
};
function AdminNavItem({
  index,
  route,
  role,
  activePath,
  currentlyCollapsed,
  setCurrentlyCollapsed,
}: Prop) {
  const [show, setShow] = useState(false);
  const [navItemClassNames, setNavItemClassNames] = useState(["nav-item"]);
  useEffect(() => {
    if (activePath.includes(route.parent.identifier))
      setNavItemClassNames((prev) => {
        return Array.from(new Set([...prev, "active"]));
      });
    else {
      setNavItemClassNames((prev) => {
        const found = prev.findIndex((e) => e === "active");
        if (found >= 0) prev.splice(found, 1);
        return [...prev];
      });
    }
  }, [activePath]);
  useEffect(() => {
    if (currentlyCollapsed === index) setShow(true);
    else setShow(false);
  }, [currentlyCollapsed]);
  const handleMouseOver = () => {
    if (document.body.classList.contains("sidebar-icon-only"))
      setNavItemClassNames((prev) => {
        return Array.from(new Set([...prev, "hover-open"]));
      });
  };
  const handleMouseLeave = () => {
    setNavItemClassNames((prev) => {
      const found = prev.findIndex((e) => e === "hover-open");
      if (found >= 0) prev.splice(found, 1);
      return [...prev];
    });
  };

  return (
    <li
      className={navItemClassNames.join(" ")}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <a
        className="nav-link"
        aria-expanded={show}
        role="button"
        onClick={() => {
          if (currentlyCollapsed === index) setCurrentlyCollapsed(-1);
          else setCurrentlyCollapsed(index);
        }}
      >
        <i className="mdi mdi-circle-outline menu-icon"></i>
        <span className="menu-title">{route.parent.title}</span>
        <i className="menu-arrow"></i>
      </a>
      <Collapse in={show} timeout={300}>
        <div>
          <ul className={"nav flex-column sub-menu"}>
            {route.children
              .filter((c) => c.role === role)
              .map((c: any) => (
                <li key={c.title} className="nav-item">
                  <Link
                    className={
                      activePath === c.route ? "nav-link active" : "nav-link"
                    }
                    to={c.route}
                  >
                    <i>{c.title}</i>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </Collapse>
    </li>
  );
}

export default AdminNavItem;
