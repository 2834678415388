import React, { SetStateAction } from "react";

interface SurveyActionContextType {
  changeAnswer: (questionId: number, answerId?: number) => void;
  changeActionPriority: (questionId: number, priority: string) => void;
  changeComment: (questionId: number, priority: string) => void;
  hasNextSection: boolean;
  nextSection: () => void;
  hasPreviousSection: boolean;
  previousSection: () => void;
  saveSurvey: () => void;
  isSurveyFulfilled: boolean;
  setIsShowModalConfirm: React.Dispatch<SetStateAction<boolean>>;
  isCheckComplete: boolean;
  setIsCheckComplete: React.Dispatch<SetStateAction<boolean>>;
}
const SurveyActionContext = React.createContext<SurveyActionContextType>(null!);

interface SurveyActionProps extends SurveyActionContextType {
  children: React.ReactNode;
}

export function SurveyActionProvider(props: SurveyActionProps) {
  const { children } = props;
  const value: SurveyActionContextType = { ...props };
  return (
    <SurveyActionContext.Provider value={value}>
      {children}
    </SurveyActionContext.Provider>
  );
}

export function useSurveyActions() {
  return React.useContext(SurveyActionContext);
}
