import { Fragment, ReactNode } from "react";
import { Navigate } from "react-router";
import { useAuth } from "./AuthProvider";
import PageNotFound from "./error-handler/PageNotFound";

export function RolesAuthRoute({
  children,
  routeRoles,
}: {
  children: ReactNode;
  routeRoles: string[];
}) {
  const { role, user } = useAuth();

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!role) {
    return <Navigate to="/logout" replace />;
  }

  const canAccess = routeRoles.includes(role);

  if (canAccess) return <Fragment>{children}</Fragment>;

  return <PageNotFound />;
}
