import { useEffect, useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../components/AuthProvider";
import LogoWrapper from "../../../client/components/LogoWrapper";
import { clientLink } from "../../../constants/link-client-site";
import { linkLogo } from "../../../constants/link-logo";
import { sidebarRoutes } from "../../constants/sidebar-routes";
import AdminNavItem from "./AdminNavItem";
import { strings } from "../../../../localization/en";
import {
  TypeNotification,
  useNotification,
} from "../../../../components/NotificationProvider";

function AdminLayout({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const [isSidebarShown, setIsSidebarShown] = useState(false);
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const [currentlyCollapsed, setCurrentlyCollapsed] = useState(-1);
  const { logOut, user, role } = useAuth();
  const navigate = useNavigate();
  const { showInfo } = useNotification();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else if (!user.refreshToken) {
      let exp = new Date(user?.expiration!);
      let now = new Date();
      if (now.getTime() > exp.getTime()) {
        showInfo(
          strings.session_timeout_error,
          TypeNotification.warning,
          10000
        );
        logOut().then((_) => navigate("/login"));
      }
    }
  }, [location]);

  const toggleSidebar = () => {
    setIsSidebarShown((prev) => !prev);
  };

  const minimizeSidebar = () => {
    if (isSidebarMinimized) document.body.classList.remove("sidebar-icon-only");
    else document.body.classList.add("sidebar-icon-only");
    setIsSidebarMinimized((prev) => !prev);
  };

  return (
    <div className="container-scroller">
      <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex justify-content-center">
          <div className="navbar-brand-inner-wrapper d-flex justify-content-between align-items-center w-100">
            <LogoWrapper
              className="navbar-brand brand-logo"
              link={clientLink.corporate_citizenship}
            >
              <img src={linkLogo.logo_1} alt="logo" />
            </LogoWrapper>

            <LogoWrapper
              className="navbar-brand brand-logo-mini"
              link={clientLink.corporate_citizenship}
            >
              <img src={linkLogo.cc_tab_icon} alt="logo" />
            </LogoWrapper>

            <button
              className="navbar-toggler navbar-toggler align-self-center shadow-none"
              type="button"
              onClick={minimizeSidebar}
            >
              <span className="mdi mdi-sort-variant"></span>
            </button>
          </div>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
          <ul className="navbar-nav navbar-nav-right">
            <NavDropdown
              title={
                <span className="nav-profile-name">{user?.displayName}</span>
              }
              id="profileDropdownn"
              className="nav-item nav-profile dropdown"
              align="end"
              menuVariant="light"
            >
              <NavDropdown.Item to="/logout" as={Link}>
                <i className="mdi mdi-logout text-primary"></i>
                {strings.logout}
              </NavDropdown.Item>
            </NavDropdown>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
            onClick={toggleSidebar}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>

      <div className="container-fluid page-body-wrapper">
        <nav
          className={
            isSidebarShown
              ? "sidebar sidebar-offcanvas active"
              : "sidebar sidebar-offcanvas"
          }
          id="sidebar"
        >
          <ul className="nav">
            <li
              className={
                location.pathname === "/admin" ? "nav-item active" : "nav-item"
              }
            >
              <Link className="nav-link" to="/admin">
                <i className="mdi mdi-home menu-icon"></i>
                <span className="menu-title">{strings.sidebar_dashboard}</span>
              </Link>
            </li>
            {sidebarRoutes.map((r, i) => (
              <AdminNavItem
                index={i}
                key={r.parent.title}
                route={r}
                role={role}
                activePath={location.pathname}
                currentlyCollapsed={currentlyCollapsed}
                setCurrentlyCollapsed={setCurrentlyCollapsed}
              />
            ))}
            <li
              className={
                location.pathname === "/admin/help"
                  ? "nav-item active"
                  : "nav-item"
              }
            >
              <Link className="nav-link" to="/admin/help">
                <i className="mdi mdi-circle-outline menu-icon"></i>
                <span className="menu-title">{strings.sidebar_help}</span>
              </Link>
            </li>
          </ul>
        </nav>

        <div className="main-panel">
          <div className="content-wrapper"> {children} </div>
        </div>
      </div>
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">
            {strings.copyright}
          </span>
          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
            Visit: <LogoWrapper link={clientLink.b4si}>B4SI</LogoWrapper>
          </span>
        </div>
      </footer>
    </div>
  );
}

export default AdminLayout;
