import React from "react";
import { AnsweredQuestion, Section, SubSection } from "../../models/survey";

interface SectionContextType {
  section: Section | undefined;
  subSections: SubSection[];
  questions: AnsweredQuestion[];
}

interface SectionProviderProps extends SectionContextType {
  children: React.ReactNode;
}

const SectionContext = React.createContext<SectionContextType>(null!);
export function SectionProvider(props: SectionProviderProps) {
  const { children, section, subSections, questions } = props;

  const value = {
    section,
    subSections,
    questions,
  };

  return (
    <SectionContext.Provider value={value}>{children}</SectionContext.Provider>
  );
}

export function useSection() {
  return React.useContext(SectionContext);
}
