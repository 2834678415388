export function addDays(days: number) {
  // Date.now() gives the epoch date value (in milliseconds) of current date
  return new Date(Date.now() + days * 24 * 60 * 60 * 1000);
}

export function toYYYYMMDD(date?: Date) {
  if (!date) return "";

  const mm = date.getMonth() + 1; // getMonth() is zero-based
  const dd = date.getDate();

  return [
    date.getFullYear(),
    (mm > 9 ? "" : "0") + mm,
    (dd > 9 ? "" : "0") + dd,
  ].join("-");
}

export function showDateValue(date?: Date) {
  if (!date) return "";

  const mm = date.getMonth() + 1; // getMonth() is zero-based
  const dd = date.getDate();

  return [
    (dd > 9 ? "" : "0") + dd,
    (mm > 9 ? "" : "0") + mm,
    date.getFullYear(),
  ].join("/");
}
