import styled from "styled-components";
import { Button, Table } from "react-bootstrap";

export const SurveyTableHeader = styled.th.attrs((_) => ({}))`
  text-align: center;
  position: relative;
  margin: 0.5rem !important;
  padding: 1.5rem 0 !important;
`;

export const OrderingIconWrapper = styled.div.attrs(
  (props: { active: boolean }) => props
)`
  position: absolute;
  right: 1px;
  bottom: 1px;
  border: none;
  opacity: ${(props: any) => (props.active ? 1 : 0.5)};
  padding: 0;

  :hover,
  :focus,
  :active {
    opacity: 0.7;
    cursor: pointer;
  }

  svg {
    font-size: 1rem;
  }
`;

export const SurveyTable = styled(Table).attrs((_) => ({
  striped: true,
  bordered: true,
}))`
  background-color: #fff;

  thead th {
    border-top: none !important;
    border-bottom: none !important;
    box-shadow: inset 0 1px 0 #ccc, inset 0 -1px 0 #ccc;
    background-clip: padding-box;
  }

  thead th:last-of-type {
    background-color: rgba(238, 238, 238, 0.57) !important;
    border: rgba(238, 238, 238, 0.57) 1px solid !important;
    box-shadow: none !important;
    background-clip: border-box !important;
  }

  tbody {
    border-top: none !important;
  }

  tr td {
    text-align: center;
  }

  tr td:last-of-type {
    background-color: rgba(238, 238, 238, 0.57) !important;
    border: rgba(238, 238, 238, 0.57) 1px solid !important;
    box-shadow: none !important;
    background-clip: border-box !important;
  }

  tbody tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: rgba(238, 238, 238, 0.57) !important;
  }

  .sticky-top {
    top: 58px;
    background-color: #fff !important;
  }

  button {
    :disabled {
      background-color: ${({ theme }) => theme.colors.seventhBackground};
      color: ${({ theme }) => theme.colors.thirdText};
      border-color: ${({ theme }) => theme.colors.seventhBorder};
    }
  }
`;

export const ResendSurveyButton = styled(Button).attrs((_) => ({}))`
  min-width: 165px;
  :disabled {
    background-color: ${({ theme }) => theme.colors.seventhBackground};
    color: ${({ theme }) => theme.colors.thirdText};
    border-color: ${({ theme }) => theme.colors.seventhBorder};
  }
`;

export const ShowEntriesWrapper = styled.div.attrs((_) => ({}))`
  display: flex;
  align-items: center;
  width: 180px;
`;
