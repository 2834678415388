export interface User {
  username: string;
  password?: string;
  displayName: string;
  token?: string;
  expiration?: string;
  refreshToken?: string;
  refreshTokenExpiration?: string;
  client: { email: string };
}
export function isAdmin(user: User | undefined) {
  if (!user) return false;
  return !!user.token;
}
