import { useEffect, useState } from "react";
import { surveyService } from "../../services/survey.service";
import SurveyProgress from "../../client/components/SurveyProgress";
import { Card, Spinner, Table, Form, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { Survey, SurveyState } from "../../models/survey";
import ClientHeader from "../../client/components/ClientHeader";
import { strings } from "../../../localization/en";
import { useAuth } from "../../../components/AuthProvider";
import { useNotification } from "../../../components/NotificationProvider";
import Icon from "@mdi/react";
import { mdiAlertOutline } from "@mdi/js";
import styles from "./ViewSurveyPage.module.scss";
import InfoTooltip from "../components/InfoTooltip/InfoTooltip";
import QuestionGuidanceTooltip from "../components/QuestionGuidanceTooltip";

function ViewSurveyPage() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [survey, setSurvey] = useState<Survey | undefined>(undefined);
  const [isExportPDF, setIsExportPDF] = useState<boolean>(false);
  const { showError } = useNotification();
  const { getMyInfo, logOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    getMyInfo()
      .then()
      .catch(() => {
        logOut().then((_) => navigate("/login"));
      });
  });

  useEffect(() => {
    surveyService
      .getById(id!)
      .then((_survey: Survey | undefined) => {
        if (_survey) {
          setSurvey(_survey);
        }
      })
      .catch((_err) => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, [id, setError]);

  const calculateCompletedness = () => {
    if (!survey) {
      throw new Error(strings.invalid_assessment);
    }
    return survey.sections.map((s) => {
      const subSections = survey.subSections.filter(
        (ss) => ss.sectionId === s.id
      );
      const questions = survey.questions.filter(
        (q) => subSections.map((ss) => ss.id).indexOf(q.subSectionId) >= 0
      );

      const section = s.name;
      const completed = questions.filter(
        (q) =>
          q.naSelected ||
          (!!q.actionPriority && ((q.allowNA && q.naSelected) || !!q.answerId))
      ).length;
      const total = questions.length;
      return {
        section,
        completed,
        total,
      };
    });
  };

  const scrollToElement = (index: number) => {
    const sectionElement = document.getElementById(
      `view-survey-section-${index.toString()}`
    );
    const progressBarElement = document.getElementsByClassName(
      "survey-progress-bar"
    );

    if (!!sectionElement && !!progressBarElement[0]) {
      const headerOffset = progressBarElement[0].getBoundingClientRect().height;
      const elementPosition = sectionElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const gotoSection = (sectionName: string) => {
    survey?.sections.forEach((section) => {
      if (section.name === sectionName) {
        scrollToElement(section.id);
      }
    });
  };

  const exportPdf = async (e: any) => {
    e.preventDefault();
    setIsExportPDF(true);
    try {
      await surveyService.getAdminSurveyReportPdf(id!);
    } catch (_) {
      console.log("Error: An error occurred when export PDF");
      showError(undefined, strings.server_error);
    }
    setIsExportPDF(false);
  };

  function SurveyNotFoundAdmin() {
    return (
      <div className="alert alert-danger d-flex px-0 py-4" role="alert">
        <Icon path={mdiAlertOutline} size={3} className="mx-5" />
        <div className="d-flex flex-column text-dark justify-content-center">
          <h2>{strings.assessmentlink_notfound}</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="survey-page">
      <ClientHeader isAdmin />
      {loading && (
        <div className="survey-page">
          <Spinner animation="border" className="loading" />
        </div>
      )}
      {error && SurveyNotFoundAdmin()}
      {!survey ? null : (
        <>
          {survey?.status === SurveyState.Submitted && (
            <div className="clearfix">
              <Button
                className="float-end me-3 mt-3 mb-2"
                onClick={(e: any) => exportPdf(e)}
                disabled={isExportPDF}
              >
                <Spinner
                  className="mx-1"
                  animation="grow"
                  size="sm"
                  hidden={!isExportPDF}
                />
                {strings.export_to_pdf_button}
              </Button>
            </div>
          )}
          <SurveyProgress
            onClick={gotoSection}
            sections={calculateCompletedness()}
          />
          {survey.sections.map((section) => (
            <Card
              key={section.id}
              id={`view-survey-section-${section.id}`}
              className="border-0"
            >
              <Card.Header
                className={`${
                  styles[
                    "section-header-" + section.name.split(" ")[1].toLowerCase()
                  ]
                } py-3 section-header mx-1`}
              >
                <h5 className="py-0 my-0">
                  {section.name.split(" ")[1].toUpperCase() +
                    ": " +
                    section.description}
                </h5>
              </Card.Header>
              <Card.Body className="py-1 px-1">
                {survey.subSections
                  .filter((ss) => ss.sectionId === section.id)
                  .map((subSection, i) => (
                    <div key={i}>
                      <div className="subsection">
                        <div
                          className={`${
                            styles[
                              "sub-section-header-" +
                                section.name.split(" ")[1].toLowerCase()
                            ]
                          } py-2 px-2`}
                          style={{ backgroundColor: "rgb(217, 217, 217)" }}
                        >
                          <h6 className="my-0">{subSection.name}</h6>
                        </div>
                        <Table responsive="sm my-1" borderless>
                          <thead className="sub-section-header">
                            <tr>
                              <th
                                style={{
                                  padding: 0,
                                  width: "0.25%",
                                  backgroundColor: "#fff",
                                }}
                              ></th>
                              <th
                                className="text-center"
                                style={{
                                  width: "35%",
                                  backgroundColor: "#fff",
                                }}
                              ></th>
                              <th
                                className="text-center border-left-right"
                                style={{ width: "5%", borderRadius: "10px" }}
                              >
                                {strings.na_question}
                              </th>

                              {subSection.options.map((o, idx) => (
                                <th
                                  className="text-center border-left-right"
                                  style={{ width: "5%", borderRadius: "10px" }}
                                  key={idx}
                                >
                                  {o.answer}
                                </th>
                              ))}

                              <th
                                className="text-center border-left-right"
                                style={{
                                  width: "10%",
                                  borderRadius: "10px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "4px",
                                    right: "4px",
                                  }}
                                >
                                  <InfoTooltip
                                    content={
                                      strings.action_priority_description
                                    }
                                    position="top"
                                  />
                                </div>
                                {strings.action_priority}
                              </th>
                              <th
                                className="text-center "
                                style={{ width: "15%", borderRadius: "10px" }}
                              >
                                {strings.comment} <br /> {strings.optional}
                              </th>
                            </tr>
                          </thead>

                          <tbody style={{ borderTop: "none" }}>
                            {survey.questions
                              .filter((q) => q.subSectionId === subSection.id)
                              .map((question, idxQuestion) => (
                                <tr key={idxQuestion}>
                                  <td
                                    style={{
                                      padding: 0,
                                    }}
                                  >
                                    <QuestionGuidanceTooltip
                                      guidance={question.guidance}
                                      sectionName={section.name
                                        .split(" ")[1]
                                        .toLowerCase()}
                                    />
                                  </td>
                                  <td>
                                    {
                                      <b>
                                        {String.fromCharCode(97 + idxQuestion) +
                                          ". "}
                                      </b>
                                    }
                                    {question.question}
                                  </td>
                                  <td className="text-center">
                                    {question.allowNA ? (
                                      <Form.Check
                                        disabled
                                        type="radio"
                                        aria-label="radio 1"
                                        checked={
                                          question.naSelected &&
                                          question.allowNA
                                        }
                                      />
                                    ) : (
                                      <span>-</span>
                                    )}
                                  </td>

                                  {subSection.options.map((o) => (
                                    <td className="text-center" key={o.id}>
                                      <Form.Check
                                        readOnly
                                        disabled
                                        type="radio"
                                        aria-label="radio 1"
                                        name={question.questionId.toString()}
                                        checked={question.answerId === o.id}
                                      />
                                    </td>
                                  ))}

                                  <td>
                                    <Form.Select
                                      aria-label="Default select example"
                                      size="sm"
                                      className="text-center"
                                      value={question.actionPriority}
                                      disabled={true}
                                    >
                                      {["", "Low", "Medium", "High"].map(
                                        (o, idx) => (
                                          <option value={o} key={idx}>
                                            {o}
                                          </option>
                                        )
                                      )}
                                    </Form.Select>
                                  </td>

                                  <td>
                                    <Form.Control
                                      size="sm"
                                      type="text"
                                      readOnly
                                      value={question.comment ?? ""}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  ))}
              </Card.Body>
            </Card>
          ))}
        </>
      )}
    </div>
  );
}

export default ViewSurveyPage;
