import { strings } from "../../../localization/en";
import OverallProgress from "./OverallProgress";
import ProgressDetails from "./ProgressDetails";

interface CompletedSectionQuestions {
  section: string;
  total: number;
  completed: number;
}

interface CompletedSurveyQuestionsProps {
  onClick: (sectionName: string) => void;
  sections: CompletedSectionQuestions[];
}

function SurveyDraftVersionProgress(props: CompletedSurveyQuestionsProps) {
  const { sections, onClick } = props;
  const overallValue = Math.round(
    (100 * sections.reduce((acc, curr) => acc + curr.completed, 0)) /
      sections.reduce((acc, curr) => acc + curr.total, 0)
  );

  const data = sections.map((s) => ({
    name: s.section,
    label: s.section.replace(/\d+\.\s*/, ""),
    value: Math.round((100 * s.completed) / s.total),
  }));

  return (
    <>
      <div
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1040,
          fontSize: "15pt",
        }}
        className=" d-flex justify-content-between py-2 px-2 bg-white survey-progress-bar my-2"
      >
        <div
          className="d-flex flex-column justify-content-end"
          style={{
            flexBasis: "75%",
          }}
        >
          <ProgressDetails onClick={onClick} data={data} />
        </div>
        <div
          className="d-flex flex-column justify-content-end"
          style={{
            flexBasis: "24%",
          }}
        >
          <OverallProgress
            label={strings.overall_label_content}
            value={overallValue}
          />
        </div>
      </div>
      <div className="d-flex flex-column justify-content-end">
        <p className="survey-copy-progress-reminder m-0">
          {strings.warning_message}
        </p>
      </div>
    </>
  );
}

export default SurveyDraftVersionProgress;
