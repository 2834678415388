import { useEffect, useState } from "react";
import {
  Button,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { MdOutlineCheck } from "react-icons/md";
import { useParams } from "react-router";
import { useAuth } from "../../../components/AuthProvider";
import { useNotification } from "../../../components/NotificationProvider";
import { strings } from "../../../localization/en";
import { surveyService } from "../../services/survey.service";
import OverallProgress from "./OverallProgress";
import ProgressDetails from "./ProgressDetails";

interface CompletedSectionQuestions {
  section: string;
  total: number;
  completed: number;
}

interface CompletedSurveyQuestionsProps {
  onClick: (sectionName: string) => void;
  sections: CompletedSectionQuestions[];
  setIsSurveyFulfilled?: React.Dispatch<React.SetStateAction<boolean>>;
  isExportable?: boolean;
}

function SurveyProgress(props: CompletedSurveyQuestionsProps) {
  const {
    sections,
    onClick,
    setIsSurveyFulfilled,
    isExportable = false,
  } = props;
  const { clientVerified } = useAuth();
  const { id } = useParams();
  const { showError } = useNotification();
  const [isExporting, setIsExporting] = useState(false);
  const [isShowLoadingModal, setIsShowLoadingModal] = useState(false);
  const overallValue = Math.round(
    (100 * sections.reduce((acc, curr) => acc + curr.completed, 0)) /
      sections.reduce((acc, curr) => acc + curr.total, 0)
  );

  useEffect(() => {
    setIsSurveyFulfilled && setIsSurveyFulfilled(overallValue === 100);
  }, [setIsSurveyFulfilled, overallValue]);

  const data = sections.map((s) => ({
    name: s.section,
    label: s.section.replace(/\d+\.\s*/, ""),
    value: Math.round((100 * s.completed) / s.total),
  }));
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Download the current survey to PDF format
    </Tooltip>
  );
  const handleExport = () => {
    setIsExporting(true);
    setIsShowLoadingModal(true);
    surveyService
      .getSurveyDraftVersionPdf(id!)
      .catch(() => {
        if (clientVerified) showError(undefined, strings.server_error);
      })
      .finally(() => setIsExporting(false));
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 1040,
        fontSize: "24px",
        borderBottom: "1px solid #f3f3f3",
        boxShadow: "4px 1px 1px #f3f3f3",
        marginTop: "1.25rem",
      }}
      className=" d-flex justify-content-between py-2 px-2 bg-white survey-progress-bar "
    >
      <div className="d-flex flex-column justify-content-end">
        {isExportable && (
          <>
            <div className="ms-3 mb-2">
              <div className="d-inline-block">
                <Button
                  onClick={() =>
                    openInNewTab("/guides/Assessment Guidance.pdf")
                  }
                >
                  Guidance
                </Button>
              </div>
              <OverlayTrigger
                placement="bottom"
                overlay={renderTooltip}
                trigger={["click", "hover"]}
              >
                <div className="d-inline-block ms-3">
                  <Button data-testid="export-a-copy" onClick={handleExport}>
                    <Spinner
                      className="me-1"
                      animation="grow"
                      size="sm"
                      hidden={!isExporting}
                    />
                    Export
                  </Button>
                </div>
              </OverlayTrigger>
            </div>
            <Modal
              onHide={() => setIsShowLoadingModal(false)}
              show={isShowLoadingModal}
              centered
            >
              <Modal.Header
                closeButton
                className="modal-none-border"
              ></Modal.Header>
              <Modal.Body className="d-flex flex-column justify-content-center align-items-center pt-0 pb-4">
                {!isExporting ? (
                  <MdOutlineCheck
                    fontSize={100}
                    className="icon-success icon-export-success my-2"
                  />
                ) : (
                  <Spinner
                    animation="border"
                    className="icon-export icon-export-loading my-2"
                  />
                )}

                <strong>
                  {!isExporting
                    ? strings.export_to_csv_completed
                    : strings.export_to_csv_loading}
                </strong>
              </Modal.Body>
            </Modal>
          </>
        )}
        <p className="progress-reminder m-0">{strings.warning_message}</p>
      </div>
      <div
        className="d-flex flex-column justify-content-end"
        style={{
          flexGrow: 0.7,
        }}
      >
        <ProgressDetails onClick={onClick} data={data} />
      </div>
      <div
        className="d-flex flex-column justify-content-end"
        style={{
          flexGrow: 0.15,
        }}
      >
        <OverallProgress
          label={strings.overall_label_content}
          value={overallValue}
        />
      </div>
    </div>
  );
}

export default SurveyProgress;
