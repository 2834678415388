import { Container, ListGroup } from "react-bootstrap";
import LogoWrapper from "../../client/components/LogoWrapper";
import { clientLink } from "../../constants/link-client-site";
import { linkLogo } from "../../constants/link-logo";
import { strings } from "../../../localization/en";

function Help() {
  return (
    <Container>
      <h4 className="mt-4 mb-0">
        <b>{strings.help_title}</b>
      </h4>
      <p className="text-muted">{strings.help_title_description}</p>
      <LogoWrapper link={clientLink.b4si}>
        <img
          className="logo-top-right"
          width="180"
          height="100"
          src={linkLogo.logo_2}
          alt="sub-logo"
        />
      </LogoWrapper>

      <div className="mt-5 mb-1">
        <p className=" fw-bold">{strings.help_navigate_title}</p>
        <ListGroup>
          <ListGroup.Item>{strings.help_navigate_content}</ListGroup.Item>
        </ListGroup>
      </div>

      <div className="mt-5 mb-1">
        <p className=" fw-bold">{strings.help_create_assessment_title}</p>
        <ListGroup>
          <ListGroup.Item>
            {strings.help_create_assessment_content}
          </ListGroup.Item>
        </ListGroup>
      </div>

      <div className="mt-5 mb-1">
        <p className=" fw-bold">{strings.help_insight_title}</p>
        <ListGroup>
          <ListGroup.Item>{strings.help_insight_content_1}</ListGroup.Item>
          <ListGroup.Item>{strings.help_insight_content_2}</ListGroup.Item>
          <ListGroup.Item>{strings.help_insight_content_3}</ListGroup.Item>
          <ListGroup.Item>{strings.help_insight_content_4}</ListGroup.Item>
        </ListGroup>
      </div>

      <div className="mt-5 mb-1">
        <p className=" fw-bold">{strings.help_assessment_list_title}</p>
        <ListGroup>
          <ListGroup.Item>
            {strings.help_assessment_list_content_1}
          </ListGroup.Item>
          <ListGroup.Item>
            {strings.help_assessment_list_content_2}
          </ListGroup.Item>
          <ListGroup.Item>
            {strings.help_assessment_list_content_3}
          </ListGroup.Item>
        </ListGroup>
      </div>
    </Container>
  );
}

export default Help;
