import { Alert, Container } from "react-bootstrap";
import { Navigate, useParams } from "react-router";
import { useAuth } from "../../../components/AuthProvider";
import ClientHeader from "../components/ClientHeader";
import { strings } from "../../../localization/en";

export default function SubmittedSurveyPage() {
  const { clientVerified } = useAuth();
  const { id } = useParams();

  if (!clientVerified) return <Navigate to={`/assessments/${id}`} />;

  return (
    <div>
      <ClientHeader />
      <Container
        className="mt-5 "
        style={{
          maxWidth: "900px",
        }}
      >
        <Alert variant="success text-center">
          <span
            dangerouslySetInnerHTML={{
              __html: strings.assessment_msg_view_result,
            }}
          />
        </Alert>
      </Container>
    </div>
  );
}
