import { strings } from "../../../localization/en";
export const sidebarRoutes = [
  {
    parent: {
      title: strings.sidebar_section,
      identifier: "/admin/sections",
    },
    children: [
      {
        title: strings.sidebar_new_section,
        route: "/admin/sections/add",
        role: "MasterAdmin",
      },
      {
        title: strings.sidebar_manage_section,
        route: "/admin/sections",
        role: "MasterAdmin",
      },
      {
        title: strings.sidebar_view_section,
        route: "/admin/sections/view",
        role: "CoreAdmin",
      },
    ],
  },
  {
    parent: {
      title: strings.sidebar_subsection,
      identifier: "/admin/sub-sections",
    },
    children: [
      {
        title: strings.sidebar_new_subsection,
        route: "/admin/sub-sections/add",
        role: "MasterAdmin",
      },
      {
        title: strings.sidebar_manage_subsection,
        route: "/admin/sub-sections",
        role: "MasterAdmin",
      },
      {
        title: strings.sidebar_view_subsection,
        route: "/admin/sub-sections/view",
        role: "CoreAdmin",
      },
    ],
  },
  {
    parent: {
      title: strings.sidebar_question,
      identifier: "/admin/questions",
    },
    children: [
      {
        title: strings.sidebar_new_question,
        route: "/admin/questions/add",
        role: "MasterAdmin",
      },
      {
        title: strings.sidebar_manage_question,
        route: "/admin/questions",
        role: "MasterAdmin",
      },
      {
        title: strings.sidebar_view_question,
        route: "/admin/questions/view",
        role: "CoreAdmin",
      },
    ],
  },
];
